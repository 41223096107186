import { FC, useEffect, useState } from "react";
import Plot from "react-plotly.js";

import { Data, PlotRelayoutEvent } from "plotly.js";

import {
  ChartRange,
  ITraceData,
} from "../../../../types/charts/chartType/chartType";
import { WellPanelSectionsProps } from "../../../../types/panels/wellPanel/common";

import {
  CASHFLOW_CHART_SECTION,
  WELL_PANEL_SECTION,
} from "../../../../constants/panels/wellPanel/wellInfo";

import useExportPanelStore from "../../../../store/exportPanel/exportPanelStore";
import usePanelsStore from "../../../../store/panels/panelsStore";

import useProductionDataChartTransformation from "../../../../customHooks/charts/useProductionDataChartTransformation";
import useExportWellPanel from "../../../../customHooks/panels/wellPanel/export/useExportWellPanel";

import {
  config,
  fontLayout,
  formatChartCustomTicks,
  generalLayout,
  legendLayout,
  xAxisLayout,
  yAxisLayout,
} from "../../../../utils/charts/layouts/CashFlowLayout";
import {
  getChartRangeProperties,
  getMinMaxChartRangeProperties,
  isResettingChartRange,
} from "../../../../utils/charts/plotlyLayout";
import { truncateString } from "../../../../utils/stringUtils";

import { clone } from "../../../../utils";
import WellPanelAccordion from "../common/WellPanelAccordion";
import ChartExportImageChartLogo from "../common/export/ChartExportImageChartLogo";
import ChartExportImageTitle from "../common/export/ChartExportImageTitle";

type CashflowSectionProps = Omit<
  WellPanelSectionsProps,
  "groupedWellID" | "layer" | "activePage"
>;

const CashflowChart: FC<CashflowSectionProps> = ({
  wellData,
  isExpanded,
  onChange,
  isChartExport = false,
}) => {
  const { formatXTicks } = useProductionDataChartTransformation();
  const [range, setRange] = useState<ChartRange | undefined>(undefined);
  const [plotlyLayout, setPlotlyLayout] = useState<any>();
  const [cashflowTraceData, setCashflowTraceData] = useState<Data[]>([]);
  const [shouldFormatXTicks, setShouldFormatXTicks] = useState(false);
  const [currentTickData, setCurrentTickData] = useState<number[]>([]);

  const isWellEconomicsLoading = usePanelsStore(
    (state) => state.isWellEconomicsLoading
  );
  const exportModalProps = useExportPanelStore(
    (state) => state.exportModalProps
  );

  const { handleSingleChartImageExport } = useExportWellPanel();

  const handleDownloadChartImage = () => {
    if (exportModalProps?.cashflowRef) {
      handleSingleChartImageExport(
        wellData.wellCardData.uwi as string,
        CASHFLOW_CHART_SECTION.displayLabel,
        exportModalProps?.cashflowRef
      );
    }
  };

  const onChartRelayout = (figure: Readonly<PlotRelayoutEvent>) => {
    if (figure?.autosize) return;

    setPlotlyLayout(figure);
  };

  useEffect(() => {
    const layout = plotlyLayout;
    if (!layout) return;
    if (layout && Object.keys(layout).length < 2) return;

    let chartRange = getChartRangeProperties(layout, range);
    if (isResettingChartRange(plotlyLayout)) {
      const copiedChartData: ITraceData[] = clone(wellData.cashflowData?.trace);
      chartRange = getMinMaxChartRangeProperties(copiedChartData);
    }

    setRange(chartRange);
  }, [plotlyLayout]);

  const handleOnChange = () => {
    onChange(WELL_PANEL_SECTION.CASHFLOW_CHART_SECTION);
  };

  useEffect(() => {
    if (wellData.cashflowData) {
      const copiedChartData: ITraceData[] = clone(wellData.cashflowData.trace);

      const chartRange = getMinMaxChartRangeProperties(copiedChartData);
      setRange(chartRange);

      let isXTicksFormatted = false;
      const customTicks = formatChartCustomTicks(copiedChartData);
      setCurrentTickData(customTicks);
      isXTicksFormatted = formatXTicks(copiedChartData);
      setShouldFormatXTicks(!isXTicksFormatted);
      setCurrentTickData(customTicks);

      const mappedTraceData = copiedChartData.map((trace: ITraceData) => {
        let name = trace.name;
        name = truncateString(trace.name, 11);

        const formattedNameWithUnit = `${name}, ${trace.unit}`;
        return { ...trace, name: formattedNameWithUnit };
      });

      setCashflowTraceData(mappedTraceData);
    } else {
      setCashflowTraceData([]);
    }
  }, [wellData.cashflowData, isWellEconomicsLoading]);

  return (
    <WellPanelAccordion
      expanded={isExpanded}
      header={WELL_PANEL_SECTION.CASHFLOW_CHART_SECTION.displayLabel}
      isLoading={isWellEconomicsLoading}
      className="chart"
      onChange={handleOnChange}
      isChartExport={isChartExport}
      showDownloadIcon={!isWellEconomicsLoading}
      handleDownloadOnClick={handleDownloadChartImage}
    >
      <div className="well-panel-chart-container">
        {isChartExport && (
          <ChartExportImageTitle
            chart={CASHFLOW_CHART_SECTION.displayLabel}
            uwi={wellData.wellCardData.uwi as string}
          />
        )}
        <Plot
          className="plot-chart"
          useResizeHandler={true}
          data={cashflowTraceData}
          config={config}
          layout={{
            ...generalLayout(isChartExport),
            ...(isChartExport && {
              width: 1850,
              height: 1060,
            }),
            margin: {
              t: isChartExport ? 50 : 10,
              r: isChartExport ? 0 : 20,
              b: 0,
              l: isChartExport ? 70 : 50,
            },
            font: {
              ...fontLayout,
            },
            legend: { ...legendLayout(range, isChartExport) },
            xaxis: {
              ...xAxisLayout(range, shouldFormatXTicks, isChartExport),
            },
            yaxis: {
              ...yAxisLayout(range, currentTickData, isChartExport),
            },
          }}
          onRelayout={(figure: any) => {
            onChartRelayout(figure);
          }}
        />
        {isChartExport && <ChartExportImageChartLogo />}
      </div>
    </WellPanelAccordion>
  );
};

export default CashflowChart;
