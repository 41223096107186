import { ChartSettingsModalParams } from "../../types/charts/chartStore/chartStore";
import {
  IAssetEconomicsData,
  ICashFlowChartData,
} from "../../types/charts/chartType/chartType";
import {
  EconomicsDisplayBy,
  FilterByAttributeType,
} from "../../types/charts/chartTypeSettings/exonomicsTypeSettings";
import { Modules } from "../../types/modularity/modules";

import { ECONOMICS_DISPLAY_BY } from "../../constants/charts/cashFlowChart";

import useChartStore from "../../store/chart/chartStore";
import useModularityStore from "../../store/modularity/modularityStore";

import { clone } from "../../utils";

const useEconomicsChartUpdate = (
  chartData: IAssetEconomicsData | ICashFlowChartData
) => {
  const modules = useModularityStore((state) => state.modules);

  const updateChartDataByKey = useChartStore(
    (state) => state.updateChartDataByKey
  );
  const updateGetDataFlag = useChartStore(
    (state) => state.setChartDataRefetchFlag
  );
  const updateModuleDataByKey = useModularityStore(
    (state) => state.updateModuleDataByKey
  );
  const updateChartModalParam = useChartStore(
    (state) => state.setChartSettingsModalParams
  );

  const closeConfigModal = () => {
    const chartConfigModalParams: ChartSettingsModalParams = {
      open: false,
      chartId: "",
      chartType: "",
    };

    updateChartModalParam(chartConfigModalParams);
  };

  const updateChartConfig = (
    title: string,
    displayBy: EconomicsDisplayBy,
    filterBy?: FilterByAttributeType,
    refetch = true
  ) => {
    const copiedChartData = clone(chartData) as IAssetEconomicsData;

    const module = modules.find((mod) => mod.module === chartData.chartId);
    const copiedModules = clone(module) as Modules;

    if (
      displayBy === ECONOMICS_DISPLAY_BY.allWells.key &&
      copiedChartData["filterBy"]
    )
      delete copiedChartData["filterBy"];

    const newChartData: IAssetEconomicsData = {
      ...copiedChartData,
      title: title,
      displayBy: displayBy,
      ...(displayBy === ECONOMICS_DISPLAY_BY.attribute.key
        ? {
            filterBy: filterBy,
          }
        : {}),
    };

    updateChartDataByKey(chartData.chartId, newChartData);
    updateModuleDataByKey(chartData.chartId, {
      ...copiedModules,
      title: title,
    });

    updateGetDataFlag({
      chartId: chartData.chartId,
      refetch: refetch,
    });
    closeConfigModal();
  };

  return {
    updateChartConfig,
    closeConfigModal,
  };
};

export default useEconomicsChartUpdate;
