import { useState } from "react";

import axios, { AxiosResponse } from "axios";

import {
  WellPanelInfo,
  WellSystemData,
} from "../../../../types/panels/wellPanel/wellPanel";

import config from "../../../../configs/appSettings";

import {
  DST_PRESSURE_DATA_TEST_TYPES,
  WFT_PRESSURE_DATA_TEST_TYPES,
} from "../../../../constants/panels/wellPanel/pressureData";
import * as constant from "../../../../constants/panels/wellPanel/wellInfo";
import { WELL_PANEL_FLAGS } from "../../../../constants/panels/wellPanel/wellInfo";

import useExportPanelStore from "../../../../store/exportPanel/exportPanelStore";
import usePanelsStore from "../../../../store/panels/panelsStore";

import { settledPromise } from "../../../../utils/api/request";
import { convertUWI } from "../../../../utils/common/wells";
import { wellPanelSectionFlags } from "../../../../utils/panel/wellPanel/sectionFlags";

import useGetRequestParams from "../../../common/useGetRequestParams";
import useExportWellPanelPressureData from "./useExportWellPanelPressureData";

const useWellPanelExportChecking = () => {
  const [fetchAllDataLoading, setFetchAllDataLoading] = useState(false);
  const [fetchAllDataSuccess, setFetchAllDataSuccess] = useState(false);
  const updateOpenedPanelAvailableSections = useExportPanelStore(
    (state) => state.updateOpenedPanelAvailableSections
  );
  const {
    getBulkPressureData: getBulkDstPressureData,
    loadingDataLakeData: loadingDstData,
  } = useExportWellPanelPressureData(DST_PRESSURE_DATA_TEST_TYPES.DST);

  const {
    getBulkPressureData: getBulkWftPressureData,
    loadingDataLakeData: loadingWftData,
  } = useExportWellPanelPressureData(WFT_PRESSURE_DATA_TEST_TYPES.WFT_PLUS);

  const { buildRequestQueryParam } = useGetRequestParams();

  const setOpenedPanelExportSections = (wellSystem: WellSystemData[]) => {
    if (wellSystem.length) {
      const sections: string[] = [];
      wellSystem.forEach((well) => {
        const availableSections = getHasSectionsData(well);
        const filteredAvailableSections = availableSections.filter(
          (section) => !sections.includes(section)
        );

        sections.push(...filteredAvailableSections);
      });
      updateOpenedPanelAvailableSections(sections);
    }
  };

  const updateWellCardDataByTypeAndKey = usePanelsStore(
    (state) => state.updateWellCardDataByTypeAndKey
  );

  const getHasSectionsData = (wellSystem: WellSystemData) => {
    const exportSections = [constant.HEADER_SECTION["key"]];
    const hasWellPanelSections = wellPanelSectionFlags(wellSystem);

    if (hasWellPanelSections.hasStatistics)
      exportSections.push(constant.STATISTICS_SECTION["key"]);
    if (hasWellPanelSections.hasForecast)
      exportSections.push(constant.DECLINE_CURVE_PARAMETERS_SECTION["key"]);
    if (hasWellPanelSections.hasPerforation)
      exportSections.push(constant.PERFORATION_SECTION["key"]);
    if (hasWellPanelSections.hasTesting)
      exportSections.push(constant.TEST_SECTION["key"]);
    if (hasWellPanelSections.hasPressureData)
      exportSections.push(constant.PRESSURE_DATA_SECTION["key"]);
    if (hasWellPanelSections.hasCompletion)
      exportSections.push(constant.COMPLETION_SECTION["key"]);
    if (hasWellPanelSections.hasCasing)
      exportSections.push(constant.CASING_SECTION["key"]);
    if (hasWellPanelSections.hasFormation)
      exportSections.push(constant.FORMATION_SECTION["key"]);
    if (hasWellPanelSections.hasPermit)
      exportSections.push(constant.PERMIT_SECTION["key"]);

    if (
      hasWellPanelSections.hasGunBarrel &&
      !exportSections.includes(constant.GUN_BARREL_SECTION["key"])
    )
      exportSections.push(constant.GUN_BARREL_SECTION["key"]);

    if (
      hasWellPanelSections.hasProduction &&
      !exportSections.includes(constant.PRODUCTION_PLOT_SECTION["key"])
    )
      exportSections.push(constant.PRODUCTION_PLOT_SECTION["key"]);

    if (hasWellPanelSections.hasWellEconomics) {
      exportSections.push(constant.CASHFLOW_CHART_SECTION["key"]);
      exportSections.push(constant.CASH_FLOW_CHART_INFO_KEY);
    }

    return exportSections;
  };

  // call api for missing sections
  // if a section data does not exists inside wellSystemData,
  // will collect all api requests then will trigger settledPromise(Promise.all)
  const updateAllSectionsData = async (wellPanelInfo: WellPanelInfo) => {
    if (!wellPanelInfo.wellSystemData) return;

    const requests: Promise<AxiosResponse<any, any>>[] = [];
    const dstPressureDataLakeRequestUwi: string[] = [];
    const wftPressureDataLakeRequestUwi: string[] = [];

    wellPanelInfo.wellSystemData?.forEach((wellSystem) => {
      const missingPromises = getMissingHasSectionsPromise(wellSystem);
      requests.push(...missingPromises);

      const uwi = convertUWI(wellSystem.wellCardData.uwi as string, -2);

      //missing dst pressure data in well system
      if (
        wellPanelSectionFlags(wellSystem).hasPressureData &&
        wellSystem.wellCardData[WELL_PANEL_FLAGS.HAS_DST.key] &&
        !wellSystem.dstPressuredata
      ) {
        if (!dstPressureDataLakeRequestUwi.includes(uwi)) {
          dstPressureDataLakeRequestUwi.push(uwi);
        }
      }

      //missing wft pressure data in well system
      if (
        wellPanelSectionFlags(wellSystem).hasPressureData &&
        wellSystem.wellCardData[WELL_PANEL_FLAGS.HAS_WFT.key] &&
        !wellSystem.wftPressureData
      ) {
        if (!wftPressureDataLakeRequestUwi.includes(uwi)) {
          wftPressureDataLakeRequestUwi.push(uwi);
        }
      }
    });

    //bulk dst pressure data request
    if (dstPressureDataLakeRequestUwi.length) {
      getBulkDstPressureData(
        dstPressureDataLakeRequestUwi,
        wellPanelInfo.wellType,
        wellPanelInfo.groupedWellID
      );
    }

    //bulk wft pressure data request
    if (wftPressureDataLakeRequestUwi.length) {
      getBulkWftPressureData(
        wftPressureDataLakeRequestUwi,
        wellPanelInfo.wellType,
        wellPanelInfo.groupedWellID
      );
    }

    if (requests.length) {
      setFetchAllDataLoading(true);
      try {
        settledPromise(requests, (responses) => {
          responses.forEach((response) => {
            if (response.config.url) {
              const info = getInfoByUrlSlug(response.config.url);

              if (info && info.wellId) {
                switch (info.key) {
                  case "perforation":
                    updateWellCardDataByTypeAndKey(
                      { type: "perforation", perforation: response.data },
                      info.wellId,
                      wellPanelInfo.wellType,
                      wellPanelInfo.groupedWellID
                    );
                    break;
                  case "test":
                    updateWellCardDataByTypeAndKey(
                      { type: "test", test: response.data },
                      info.wellId,
                      wellPanelInfo.wellType,
                      wellPanelInfo.groupedWellID
                    );
                    break;
                  case "casing":
                    updateWellCardDataByTypeAndKey(
                      { type: "casing", casing: response.data },
                      info.wellId,
                      wellPanelInfo.wellType,
                      wellPanelInfo.groupedWellID
                    );
                    break;
                  case "formation":
                    updateWellCardDataByTypeAndKey(
                      {
                        type: "formation",
                        formation: response.data,
                        isDataLoaded: true,
                      },
                      info.wellId,
                      wellPanelInfo.wellType,
                      wellPanelInfo.groupedWellID
                    );
                    break;
                  case "permit":
                    updateWellCardDataByTypeAndKey(
                      {
                        type: "permit",
                        permit: response.data,
                        isDataLoaded: true,
                      },
                      info.wellId,
                      wellPanelInfo.wellType,
                      wellPanelInfo.groupedWellID
                    );
                    break;
                }
              }
            }
          });

          setFetchAllDataLoading(false);
          setFetchAllDataSuccess(true);
        });
      } catch (e) {
        console.log(e);
        setFetchAllDataLoading(false);
        setFetchAllDataSuccess(false);
      }
    } else {
      setFetchAllDataLoading(false);
      setFetchAllDataSuccess(true);
    }
  };

  //get HAS Sections but not yet in wellInfoList
  const getMissingHasSectionsPromise = (wellSystem: WellSystemData) => {
    const hasWellPanelSections = wellPanelSectionFlags(wellSystem);
    const missingSectionsPromises = [];

    if (hasWellPanelSections.hasPerforation && !wellSystem.perforationData)
      missingSectionsPromises.push(
        axios.get(
          `${config.endpoints.wellService}api/${constant.PERFORATION_SECTION.urlSlug}/${wellSystem.wellId}`,
          {
            ...buildRequestQueryParam,
          }
        )
      );
    if (hasWellPanelSections.hasTesting && !wellSystem.testingData)
      missingSectionsPromises.push(
        axios.get(
          `${config.endpoints.wellService}api/${constant.TEST_SECTION.urlSlug}/${wellSystem.wellId}`,
          {
            ...buildRequestQueryParam,
          }
        )
      );
    if (hasWellPanelSections.hasCasing && !wellSystem.casingData)
      missingSectionsPromises.push(
        axios.get(
          `${config.endpoints.wellService}api/wells/${wellSystem.wellId}/${constant.CASING_SECTION.urlSlug}`,
          {
            ...buildRequestQueryParam,
          }
        )
      );
    if (hasWellPanelSections.hasFormation && !wellSystem.formationData)
      missingSectionsPromises.push(
        axios.get(
          `${config.endpoints.wellService}api/wells/${wellSystem.wellId}/${constant.FORMATION_SECTION.urlSlug}`,
          {
            ...buildRequestQueryParam,
          }
        )
      );
    if (hasWellPanelSections.hasPermit && !wellSystem.permitsData)
      missingSectionsPromises.push(
        axios.get(
          `${config.endpoints.wellService}api/wells/${wellSystem.wellId}/${constant.PERMIT_SECTION.urlSlug}`,
          {
            ...buildRequestQueryParam,
          }
        )
      );

    return missingSectionsPromises;
  };

  const getInfoByUrlSlug = (url: string) => {
    if (url.indexOf(constant.PERFORATION_SECTION.urlSlug) > -1) {
      const { pathname } = new URL(url);
      return {
        key: constant.PERFORATION_SECTION.key,
        wellId: Number(pathname.split("/").pop()),
      };
    } else if (url.indexOf(constant.TEST_SECTION.urlSlug) > -1) {
      const { pathname } = new URL(url);
      return {
        key: constant.TEST_SECTION.key,
        wellId: Number(pathname.split("/").pop()),
      };
    } else if (url.indexOf(constant.CASING_SECTION.urlSlug) > -1) {
      const { pathname } = new URL(url);
      return {
        key: constant.CASING_SECTION.key,
        wellId: Number(pathname.split("/")[3]),
      };
    } else if (url.indexOf(constant.FORMATION_SECTION.urlSlug) > -1) {
      const { pathname } = new URL(url);
      return {
        key: constant.FORMATION_SECTION.key,
        wellId: Number(pathname.split("/")[3]),
      };
    } else if (url.indexOf(constant.PERMIT_SECTION.urlSlug) > -1) {
      const { pathname } = new URL(url);
      return {
        key: constant.PERMIT_SECTION.key,
        wellId: Number(pathname.split("/")[3]),
      };
    }
  };

  const checkLoadedSectionsComplete = (wellSystemData: WellSystemData[]) => {
    return wellSystemData.every((wellSystem) => {
      const sectionFlags = wellPanelSectionFlags(wellSystem);

      //will no longer check for header, statistics and completion
      //since they are using data from wellCardData
      if (sectionFlags.hasPerforation && !wellSystem.perforationData) {
        return false;
      }

      if (sectionFlags.hasTesting && !wellSystem.testingData) {
        return false;
      }

      if (sectionFlags.hasCasing && !wellSystem.casingData) {
        return false;
      }

      if (sectionFlags.hasFormation && !wellSystem.formationData) {
        return false;
      }

      if (sectionFlags.hasPermit && !wellSystem.permitsData) {
        return false;
      }

      if (
        sectionFlags.hasPressureData &&
        wellSystem.wellCardData[WELL_PANEL_FLAGS.HAS_DST.key] &&
        !wellSystem.dstPressuredata
      ) {
        return false;
      }

      if (
        sectionFlags.hasPressureData &&
        wellSystem.wellCardData[WELL_PANEL_FLAGS.HAS_WFT.key] &&
        !wellSystem.wftPressureData
      ) {
        return false;
      }

      return true;
    });
  };

  return {
    fetchAllDataLoading,
    loadingDstData,
    loadingWftData,
    fetchAllDataSuccess,
    setOpenedPanelExportSections,
    updateAllSectionsData,
    checkLoadedSectionsComplete,
  };
};

export default useWellPanelExportChecking;
