import {
  FreeCashFlowData,
  FreeCashFlowMonthNegative,
  FreeCashFlowMonthPositive,
} from "../../types/charts/chartType/cashFlowChartData";
import { ITraceData } from "../../types/charts/chartType/chartType";

import {
  ASSET_LEVEL_SCATTER_DATA,
  EXPENSE_BAR_DATA,
  REVENUE_BAR_DATA,
} from "../../constants/charts/cashFlowChart";
import { MONTHS_NUMERIC_VALUE } from "../../constants/charts/charts";

import { clone } from "../helper";
import { getPositiveModulo } from "../helper2";

export const getMonthWithOffset = (
  year: number,
  month: string,
  monthOffset = 0
) => {
  if (monthOffset === 0) {
    return { monthWithOffset: month, yearWithOffset: year };
  }

  const monthsInArray = Object.keys(MONTHS_NUMERIC_VALUE);
  const monthIndex = monthsInArray.findIndex((m) => m === month);

  const newMonthIndex = monthIndex + monthOffset;
  const monthWithOffsetIndex = getPositiveModulo(newMonthIndex, 12);
  let yearOffset = 0;
  if (newMonthIndex > 0) {
    yearOffset = Math.floor(newMonthIndex / 12);
  } else if (newMonthIndex < 0) {
    // if negative, need to add -1 to the offset
    yearOffset = Math.ceil(newMonthIndex / 12) - 1;
  }

  const finalMonth = monthsInArray[monthWithOffsetIndex];
  const finalYear = year + yearOffset;

  return { monthWithOffset: finalMonth, yearWithOffset: finalYear };
};

// date format is Mmm-dd
export const getFormattedDate = (
  date: string,
  year: number,
  monthOffset = 0
) => {
  // adding a default empty string in case there are bad data
  const month = (date ?? "").slice(0, 3);
  const { monthWithOffset, yearWithOffset } = getMonthWithOffset(
    year,
    month,
    monthOffset
  );

  return `${yearWithOffset}-${MONTHS_NUMERIC_VALUE[monthWithOffset]}`;
};

export const formatCashflowTrace = (cashFlowData: FreeCashFlowData) => {
  const revenueBarData: ITraceData = clone(REVENUE_BAR_DATA);
  const expenseBarData: ITraceData = clone(EXPENSE_BAR_DATA);
  const fcfScatterData: ITraceData = clone(ASSET_LEVEL_SCATTER_DATA);

  cashFlowData.forEach((d, idx) => {
    const { Date: date, Year: year } = d;

    if (idx === 0) {
      const { TotalDrillingCompletionCost } = d as FreeCashFlowMonthNegative;

      const prevDate = getFormattedDate(date, year, -1);
      const currDate = getFormattedDate(date, year);

      const cost40Percent = Math.round(TotalDrillingCompletionCost * 0.4);
      const cost60Percent = Math.round(TotalDrillingCompletionCost * 0.6);

      revenueBarData.x.push(prevDate);
      revenueBarData.y.push(0);
      revenueBarData.x.push(currDate);
      revenueBarData.y.push(0);

      expenseBarData.x.push(prevDate);
      expenseBarData.y.push(cost40Percent);
      expenseBarData.x.push(currDate);
      expenseBarData.y.push(cost60Percent);

      fcfScatterData.x.push(prevDate);
      fcfScatterData.y.push(cost40Percent);
      fcfScatterData.x.push(currDate);
      fcfScatterData.y.push(cost60Percent);
      return;
    }

    const data = d as FreeCashFlowMonthPositive;
    const {
      TotalRevenue,
      TotalNonOperatingCost,
      TotalOperatingCost,
      FreeCashFlow,
    } = data;

    const formattedDate = getFormattedDate(date, year);

    revenueBarData.x.push(formattedDate);
    revenueBarData.y.push(Math.round(TotalRevenue));

    expenseBarData.x.push(formattedDate);
    expenseBarData.y.push(
      Math.round(TotalNonOperatingCost + TotalOperatingCost)
    );

    fcfScatterData.x.push(formattedDate);
    fcfScatterData.y.push(Math.round(FreeCashFlow));
  });

  return [revenueBarData, expenseBarData, fcfScatterData];
};
