import { useEffect, useState } from "react";

import usePanelsStore from "../../../../store/panels/panelsStore";

const useExportWellPanelState = () => {
  const [enableWellPanelExport, setEnableWellPaneExport] = useState(true);

  const isPerforationsLoading = usePanelsStore(
    (state) => state.isPerforationsLoading
  );
  const isTestLoading = usePanelsStore((state) => state.isTestLoading);
  const isCasingLoading = usePanelsStore((state) => state.isCasingLoading);
  const isPermitsLoading = usePanelsStore((state) => state.isPermitsLoading);
  const isFormationsLoading = usePanelsStore(
    (state) => state.isFormationsLoading
  );
  const isDstPressureDataLoading = usePanelsStore(
    (state) => state.isDstPressureDataLoading
  );
  const isWftPressureDataLoading = usePanelsStore(
    (state) => state.isWftPressureDataLoading
  );
  const isWellEconomicsLoading = usePanelsStore(
    (state) => state.isWellEconomicsLoading
  );

  useEffect(() => {
    if (
      isPerforationsLoading ||
      isTestLoading ||
      isCasingLoading ||
      isPermitsLoading ||
      isFormationsLoading ||
      isDstPressureDataLoading ||
      isWftPressureDataLoading ||
      isWellEconomicsLoading
    ) {
      setEnableWellPaneExport(false);
    } else {
      setEnableWellPaneExport(true);
    }
  }, [
    isPerforationsLoading,
    isTestLoading,
    isCasingLoading,
    isPermitsLoading,
    isFormationsLoading,
    isDstPressureDataLoading,
    isWftPressureDataLoading,
    isWellEconomicsLoading,
  ]);

  return { enableWellPanelExport };
};

export default useExportWellPanelState;
