import { Attribute, KeyToAttributeMapper } from "../types/common/attributes";

// Groups
export const HEADER = "Header";
export const WELL_SPACING = "Well Spacing";
export const WELL_FLAG = "Well Flag";
export const DATES = "Dates";
export const SURFACE_LOCATION = "Surface Location";
export const COMPLETION_DATA = "Completion Data";
export const STATISTICS = "Statistics";
export const ID = "ID";
export const WELL_ECONOMICS = "Well Economics";
export const PERMITS = "Permits";
export const NO_GROUP = "No Group";

// Types
export const TEXT_SELECT = "Text Select";
export const TEXT = "Text";
export const SPECIAL = "Special";
export const FLOAT = "Float";
export const INTEGER = "Integer";
export const DATE = "Date";
export const BOOLEAN = "Boolean";

/**
 * Note: When adding an attribute, the ATTRIBUTE_INFO_BY_KEY
 * should also be updated.
 * */
// Headers
export const COUNTRY: Attribute = {
  key: "Country",
  label: "Country",
  dataType: TEXT,
  group: HEADER,
  exportAlias: "Country",
};
export const STATE_NAME: Attribute = {
  key: "StateName",
  label: "State/Province",
  dataType: TEXT,
  group: HEADER,
  exportAlias: "StateName",
};
export const COUNTY: Attribute = {
  key: "County",
  label: "County",
  dataType: TEXT,
  group: HEADER,
  exportAlias: "County",
};
export const WELL_NAME: Attribute = {
  key: "WellName",
  label: "Well Name",
  dataType: SPECIAL,
  group: HEADER,
  exportAlias: "WellName",
  debounce: 750,
  reqChars: 2,
};
export const WELL_NUMBER: Attribute = {
  key: "WellNumber",
  label: "Well Number",
  dataType: SPECIAL,
  group: HEADER,
  exportAlias: "WellNumber",
  debounce: 0,
  reqChars: 1,
};
export const FIELD_NAME: Attribute = {
  key: "FieldName",
  label: "Field Name",
  dataType: TEXT,
  group: HEADER,
  exportAlias: "FieldName",
};
export const BASIN_NAME: Attribute = {
  key: "BasinName",
  label: "Basin",
  dataType: TEXT,
  group: HEADER,
  exportAlias: "BasinName",
};
export const DISPLAY_FORMATION: Attribute = {
  key: "DisplayFormation",
  label: "Producing Formation",
  dataType: TEXT,
  group: HEADER,
  exportAlias: "ProdForm",
};
export const INTERPRETED_PRODUCING_FORMATION: Attribute = {
  key: "InterpretedProducingFormation",
  label: "TGS Landing Zone",
  dataType: TEXT,
  group: HEADER,
  exportAlias: "LandingZon",
};
export const OPERATOR_NAME: Attribute = {
  key: "OperatorName",
  label: "Current Operator",
  dataType: TEXT,
  group: HEADER,
  exportAlias: "OperName",
};
export const WELL_STATUS: Attribute = {
  key: "WellStatus",
  label: "Well Status",
  dataType: TEXT,
  group: HEADER,
  exportAlias: "WellStatus",
};
export const WELL_TYPE: Attribute = {
  key: "WellType",
  label: "Well Type",
  dataType: TEXT,
  group: HEADER,
  exportAlias: "WellType",
};
export const MEASURED_DEPTH: Attribute = {
  key: "MeasuredDepth",
  label: "Measured Depth",
  dataType: FLOAT,
  group: HEADER,
  exportAlias: "MeasDepth",
};
export const TOTAL_VERTICAL_DEPTH: Attribute = {
  key: "TotalVerticalDepth",
  label: "Total Vertical Depth",
  dataType: FLOAT,
  group: HEADER,
  exportAlias: "TVD",
};
export const SLANT: Attribute = {
  key: "Slant",
  label: "Slant",
  dataType: TEXT,
  group: HEADER,
  exportAlias: "Slant",
};
export const LAST_INJECTION_TYPE: Attribute = {
  key: "LastInjectionType",
  label: "Injection Type",
  dataType: TEXT,
  group: HEADER,
  exportAlias: "INJType",
};
export const LAST_INJECTION_FORMATION: Attribute = {
  key: "LastInjectionFormation",
  label: "Injection Formation",
  dataType: TEXT,
  group: HEADER,
  exportAlias: "INJForm",
};
export const ULTIMATE_OWNER: Attribute = {
  key: "UltimateOwner",
  label: "TGS Operator",
  dataType: TEXT,
  group: HEADER,
  exportAlias: "TGSOper",
};
export const DISPOSITION_TYPE: Attribute = {
  key: "DispositionType",
  label: "Gas Disposition Type",
  dataType: TEXT,
  group: HEADER,
  exportAlias: "DispType",
};
export const ELEVATION_GROUND: Attribute = {
  key: "ElevationGround",
  label: "Elevation Ground",
  dataType: FLOAT,
  group: HEADER,
  exportAlias: "ElevGround",
};
export const ELEVATION_KELLY_BUSHING: Attribute = {
  key: "ElevationKellyBushing",
  label: "Elevation Kelly Bushing",
  dataType: FLOAT,
  group: HEADER,
  exportAlias: "ElevKB",
};
export const LEASE_NAME: Attribute = {
  key: "LeaseName",
  label: "Lease Name",
  dataType: SPECIAL,
  group: HEADER,
  exportAlias: "LeaseName",
  debounce: 750,
  reqChars: 2,
};
export const STATE_LEASE_ID: Attribute = {
  key: "StateLeaseID",
  label: "State Lease ID",
  dataType: SPECIAL,
  group: HEADER,
  exportAlias: "StLeaseID",
  debounce: 750,
  reqChars: 2,
};
export const LEASE_NAME_AND_ID: Attribute = {
  key: "LeaseNameAndID",
  label: "Lease Name and ID",
  dataType: SPECIAL,
  group: HEADER,
  exportAlias: "LeaseNamID",
  debounce: 750,
  reqChars: 2,
};

// Well Spacing
export const CLOSEST_HORIZONTAL_DISTANCE_IN_ZONE: Attribute = {
  key: "ClosestHorizontalDistanceInZone",
  label: "Closest Hz Dist (in-zone)",
  dataType: FLOAT,
  group: WELL_SPACING,
  exportAlias: "ClosestHDZ",
};
export const CLOSEST_HORIZONTAL_DISTANCE: Attribute = {
  key: "ClosestHorizontalDistance",
  label: "Closest Hz Dist (total)",
  dataType: FLOAT,
  group: WELL_SPACING,
  exportAlias: "ClosestHD",
};
export const CLOSEST_VERTICAL_DISTANCE: Attribute = {
  key: "ClosestVerticalDistance",
  label: "Closest Vrt Dist",
  dataType: FLOAT,
  group: WELL_SPACING,
  exportAlias: "ClosestVD",
};
export const COUNT_OF_PARENT_WELLS: Attribute = {
  key: "CountOfParentWells",
  label: "# of Parents",
  dataType: INTEGER,
  group: WELL_SPACING,
  exportAlias: "NumOfPrt",
};
export const COUNT_OF_CHILD_WELLS: Attribute = {
  key: "CountOfChildWells",
  label: "# of Children",
  dataType: INTEGER,
  group: WELL_SPACING,
  exportAlias: "NumOfChld",
};
export const COUNT_OF_SIBLING_WELLS: Attribute = {
  key: "CountOfSiblingWells",
  label: "# of Siblings",
  dataType: INTEGER,
  group: WELL_SPACING,
  exportAlias: "NumOfSib",
};

// Well Flags
export const HAS_PRODUCTION: Attribute = {
  key: "HasProduction",
  label: "Has Production",
  dataType: BOOLEAN,
  group: WELL_FLAG,
  exportAlias: "HasProd",
};
export const HAS_FORECAST: Attribute = {
  key: "HasForecast",
  label: "Has Forecast",
  dataType: BOOLEAN,
  group: WELL_FLAG,
  exportAlias: "HasForecas",
};
export const TEST: Attribute = {
  key: "Test",
  label: "Has Tests",
  dataType: BOOLEAN,
  group: WELL_FLAG,
  exportAlias: "HasTest",
};
export const PERF: Attribute = {
  key: "Perf",
  label: "Has Perforations",
  dataType: BOOLEAN,
  group: WELL_FLAG,
  exportAlias: "HasPerf",
};
export const HAS_INJECTION: Attribute = {
  key: "HasInjection",
  label: "Has Injection",
  dataType: BOOLEAN,
  group: WELL_FLAG,
  exportAlias: "HasInj",
};
export const HAS_VENT_FLARE: Attribute = {
  key: "HasVentFlare",
  label: "Has Vent Flare",
  dataType: BOOLEAN,
  group: WELL_FLAG,
  exportAlias: "HasVF",
};
export const OFFSHORE_FLG: Attribute = {
  key: "OffShoreFlg",
  label: "Offshore Flag",
  dataType: BOOLEAN,
  group: WELL_FLAG,
  exportAlias: "OffShFLG",
};

export const HAS_WELL_ECONOMICS: Attribute = {
  key: "HasWellEconomics",
  label: "Has Well Economics",
  dataType: BOOLEAN,
  group: WELL_FLAG,
  exportAlias: "HasEcon",
};

export const HAS_DST: Attribute = {
  key: "HasDST",
  label: "Has DST",
  dataType: BOOLEAN,
  group: WELL_FLAG,
  exportAlias: "HasDST",
};

export const HAS_LAS: Attribute = {
  key: "HasLas",
  label: "Has LAS",
  dataType: BOOLEAN,
  group: WELL_FLAG,
  exportAlias: "HasLAS",
};

export const HAS_RASTER: Attribute = {
  key: "HasRaster",
  label: "Has Raster",
  dataType: BOOLEAN,
  group: WELL_FLAG,
  exportAlias: "HasRaster",
};

export const HAS_WELL_FILE: Attribute = {
  key: "HasWellFile",
  label: "Has Files",
  dataType: BOOLEAN,
  group: WELL_FLAG,
  exportAlias: "HasFiles",
};

export const HAS_WFT: Attribute = {
  key: "HasWFT",
  label: "Has WFT",
  dataType: BOOLEAN,
  group: WELL_FLAG,
  exportAlias: "HasWFT",
};

// Dates
export const SPUD_DATE: Attribute = {
  key: "SpudDate",
  label: "Spud Date",
  dataType: DATE,
  group: DATES,
  exportAlias: "SpudDate",
};
export const TD_DATE: Attribute = {
  key: "TDDate",
  label: "TD Date",
  dataType: DATE,
  group: DATES,
  exportAlias: "TDDate",
};
export const COMPLETION_DATE: Attribute = {
  key: "CompletionDate",
  label: "Completion Date",
  dataType: DATE,
  group: DATES,
  exportAlias: "CompDate",
};
export const FIRST_MONTH: Attribute = {
  key: "FirstMonth",
  label: "First Production Month",
  dataType: DATE,
  group: DATES,
  exportAlias: "FirstMonth",
};
export const LAST_MONTH: Attribute = {
  key: "LastMonth",
  label: "Last Production Month",
  dataType: DATE,
  group: DATES,
  exportAlias: "LastMonth",
};
export const PLUG_DATE: Attribute = {
  key: "PlugDate",
  label: "Plug Date",
  dataType: DATE,
  group: DATES,
  exportAlias: "PlugDate",
};

// Surface Location
export const SECTION: Attribute = {
  key: "Section",
  label: "Section",
  dataType: TEXT,
  group: SURFACE_LOCATION,
  exportAlias: "Section",
};
export const TOWNSHIP: Attribute = {
  key: "Township",
  label: "Township",
  dataType: TEXT,
  group: SURFACE_LOCATION,
  exportAlias: "Twnshp",
};
export const TOWNSHIP_DIRECTION: Attribute = {
  key: "TownshipDirection",
  label: "Township Direction",
  dataType: TEXT,
  group: SURFACE_LOCATION,
  exportAlias: "TwnshpDir",
};
export const RANGE: Attribute = {
  key: "RangeName",
  label: "Range",
  dataType: TEXT,
  group: SURFACE_LOCATION,
  exportAlias: "Range",
};
export const RANGE_DIRECTION: Attribute = {
  key: "RangeDirection",
  label: "Range Direction",
  dataType: TEXT,
  group: SURFACE_LOCATION,
  exportAlias: "RangeDir",
};
export const DISTRICT: Attribute = {
  key: "District",
  label: "District",
  dataType: TEXT,
  group: SURFACE_LOCATION,
  exportAlias: "District",
};
export const AREA: Attribute = {
  key: "Area",
  label: "Area",
  dataType: TEXT,
  group: SURFACE_LOCATION,
  exportAlias: "Area",
};
export const OFFSHORE_BLOCK: Attribute = {
  key: "OffshoreBlock",
  label: "Offshore Block",
  dataType: TEXT,
  group: SURFACE_LOCATION,
  exportAlias: "OffshBLK",
};

// Completion Data
export const PERF_INTERVAL_TOP: Attribute = {
  key: "PerfIntervalTop",
  label: "Completion Interval Top",
  dataType: FLOAT,
  group: COMPLETION_DATA,
  exportAlias: "PerfTop",
};
export const PERF_INTERVAL_BOTTOM: Attribute = {
  key: "PerfIntervalBottom",
  label: "Completion Interval Bottom",
  dataType: FLOAT,
  group: COMPLETION_DATA,
  exportAlias: "PerfBot",
};
export const LATERAL_LENGTH: Attribute = {
  key: "LateralLength",
  label: "Lateral Length",
  dataType: FLOAT,
  group: COMPLETION_DATA,
  exportAlias: "LatLength",
};
export const NUMBER_OF_STAGES: Attribute = {
  key: "NumberOfStages",
  label: "Number of Stages",
  dataType: INTEGER,
  group: COMPLETION_DATA,
  exportAlias: "NumStage",
};
export const FRAC_FLUID_AMOUNT: Attribute = {
  key: "FractureFluidAmount",
  label: "Frac Fluid Amount",
  dataType: FLOAT,
  group: COMPLETION_DATA,
  exportAlias: "FrFluid",
};
export const PROPPANT_AMOUNT: Attribute = {
  key: "ProppantAmount",
  label: "Proppant Amount",
  dataType: FLOAT,
  group: COMPLETION_DATA,
  exportAlias: "PropAmt",
};
export const MAX_TREATMENT_RATE: Attribute = {
  key: "MaxTreatmentRate",
  label: "Max Treatment Rate",
  dataType: FLOAT,
  group: COMPLETION_DATA,
  exportAlias: "TreatRate",
};
export const MAX_TREATMENT_PRESSURE: Attribute = {
  key: "MaxTreatmentPressure",
  label: "Max Treatment Pressure",
  dataType: FLOAT,
  group: COMPLETION_DATA,
  exportAlias: "TreatPress",
};
export const ACID_AMOUNT: Attribute = {
  key: "AcidAmount",
  label: "Acid Amount",
  dataType: FLOAT,
  group: COMPLETION_DATA,
  exportAlias: "AcidAmt",
};
export const PROPPANT_AMOUNT_PER_FT: Attribute = {
  key: "ProppantAmountPerFt",
  label: "Proppant Per Lateral Length",
  dataType: FLOAT,
  group: COMPLETION_DATA,
  exportAlias: "PropAmtPFt",
};
export const FRAC_FLUID_AMOUNT_PER_FT: Attribute = {
  key: "FractureFluidAmountPerFt",
  label: "Frac Fluid Per Lateral Length",
  dataType: FLOAT,
  group: COMPLETION_DATA,
  exportAlias: "FrFluidpft",
};
export const BOE_MAX_PER_FT: Attribute = {
  key: "BOEMaxPerFt",
  label: "BOE IP30 Per Lateral Length",
  dataType: FLOAT,
  group: COMPLETION_DATA,
  exportAlias: "BOEIP30PFt",
};

// Statistics
export const CUM_BOE: Attribute = {
  key: "CumBOE",
  label: "BOE Total Cum",
  dataType: FLOAT,
  group: STATISTICS,
  exportAlias: "BOECum",
};
export const CUM_GAS: Attribute = {
  key: "CumGas",
  label: "Gas Total Cum",
  dataType: FLOAT,
  group: STATISTICS,
  exportAlias: "GasCum",
};
export const CUM_OIL: Attribute = {
  key: "CumOil",
  label: "Oil Total Cum",
  dataType: FLOAT,
  group: STATISTICS,
  exportAlias: "OilCum",
};
export const CUM_WATER: Attribute = {
  key: "CumWater",
  label: "Water Total Cum",
  dataType: FLOAT,
  group: STATISTICS,
  exportAlias: "WtrCum",
};
export const CUM_INJECTION_LIQUID: Attribute = {
  key: "CumInjectionLiquid",
  label: "Liquid Injection Cum",
  dataType: FLOAT,
  group: STATISTICS,
  exportAlias: "LiqInjCum",
};
export const CUM_INJECTION_GAS: Attribute = {
  key: "CumInjectionGas",
  label: "Gas Injection Cum",
  dataType: FLOAT,
  group: STATISTICS,
  exportAlias: "GasInjCum",
};
export const WELL_FORECAST_BOE_ULTIMATE: Attribute = {
  key: "WellForecastBOEUltimate",
  label: "BOE Ultimate",
  dataType: FLOAT,
  group: STATISTICS,
  exportAlias: "EUR",
};
export const WELL_FORECAST_GAS_ULTIMATE: Attribute = {
  key: "WellForecastGasUltimate",
  label: "Gas Ultimate",
  dataType: FLOAT,
  group: STATISTICS,
  exportAlias: "GasEUR",
};
export const WELL_FORECAST_OIL_ULTIMATE: Attribute = {
  key: "WellForecastOilUltimate",
  label: "Oil Ultimate",
  dataType: FLOAT,
  group: STATISTICS,
  exportAlias: "OilEUR",
};
export const WELL_FORECAST_WATER_ULTIMATE: Attribute = {
  key: "WellForecastWaterUltimate",
  label: "Water Ultimate",
  dataType: FLOAT,
  group: STATISTICS,
  exportAlias: "WtrEUR",
};
export const WELL_FORECAST_WATER_CUT_ULTIMATE: Attribute = {
  key: "WellForecastWaterCutUltimate",
  label: "Water Cut Ultimate",
  dataType: FLOAT,
  group: STATISTICS,
  exportAlias: "WtrCutEUR",
};
export const WELL_FORECAST_GOR_ULTIMATE: Attribute = {
  key: "WellForecastGORUltimate",
  label: "GOR Ultimate",
  dataType: FLOAT,
  group: STATISTICS,
  exportAlias: "GOREUR",
};
export const MAX_BOE: Attribute = {
  key: "MaxBOE",
  label: "BOE IP30",
  dataType: FLOAT,
  group: STATISTICS,
  exportAlias: "BOEIP30",
};

//grid uses BOEIP30 key
export const BOE_IP30: Attribute = {
  key: "BOEIP30",
  label: "BOE IP30",
  dataType: FLOAT,
  group: STATISTICS,
};

export const MAX_GAS: Attribute = {
  key: "MaxGas",
  label: "Gas IP30",
  dataType: FLOAT,
  group: STATISTICS,
  exportAlias: "GasIP30",
};
export const MAX_GAS_PLUS_2: Attribute = {
  key: "MaxGasPlus2",
  label: "Gas IP90",
  dataType: FLOAT,
  group: STATISTICS,
  exportAlias: "GasIP90",
};
export const MAX_OIL: Attribute = {
  key: "MaxOil",
  label: "Oil IP30",
  dataType: FLOAT,
  group: STATISTICS,
  exportAlias: "OilIP30",
};
export const MAX_OIL_PLUS_2: Attribute = {
  key: "MaxOilPlus2",
  label: "Oil IP90",
  dataType: FLOAT,
  group: STATISTICS,
  exportAlias: "OilIP90",
};
export const MAX_WATER: Attribute = {
  key: "MaxWater",
  label: "Water IP30",
  dataType: FLOAT,
  group: STATISTICS,
  exportAlias: "WtrIP30",
};
export const MAX_WATER_PLUS_2: Attribute = {
  key: "MaxWaterPlus2",
  label: "Water IP90",
  dataType: FLOAT,
  group: STATISTICS,
  exportAlias: "WtrIP90",
};
export const WATER_CUT_TOTAL_CUM: Attribute = {
  key: "WaterCutTotalCum",
  label: "Water Cut",
  dataType: FLOAT,
  group: STATISTICS,
  exportAlias: "WtrCut",
};
export const WATER_OIL_RATIO_TOTAL_CUM: Attribute = {
  key: "WaterOilRatioTotalCum",
  label: "Water Oil Ratio",
  dataType: FLOAT,
  group: STATISTICS,
  exportAlias: "WtrOilRatio",
};
export const CUM_GOR: Attribute = {
  key: "CumGOR",
  label: "GOR Total Cum",
  dataType: FLOAT,
  group: STATISTICS,
  exportAlias: "GORCum",
};
export const WELL_FORECAST_BOE_REMAINING: Attribute = {
  key: "WellForecastBOERemaining",
  label: "BOE Remaining",
  dataType: FLOAT,
  group: STATISTICS,
  exportAlias: "BOERemain",
};
export const WELL_FORECAST_GAS_REMAINING: Attribute = {
  key: "WellForecastGasRemaining",
  label: "Gas Remaining",
  dataType: FLOAT,
  group: STATISTICS,
  exportAlias: "GasRemain",
};
export const WELL_FORECAST_OIL_REMAINING: Attribute = {
  key: "WellForecastOilRemaining",
  label: "Oil Remaining",
  dataType: FLOAT,
  group: STATISTICS,
  exportAlias: "OilRemain",
};
export const WELL_FORECAST_WATER_REMAINING: Attribute = {
  key: "WellForecastWaterRemaining",
  label: "Water Remaining",
  dataType: FLOAT,
  group: STATISTICS,
  exportAlias: "WtrRemain",
};
export const CUM_VENT_FLARE_VOL: Attribute = {
  key: "CumVentFlareVol",
  label: "Vent Flare Cum",
  dataType: FLOAT,
  group: STATISTICS,
  exportAlias: "VFlareCum",
};
export const EUR_PER_FT: Attribute = {
  key: "EURPerFt",
  label: "EUR Per Lateral Length",
  dataType: FLOAT,
  group: STATISTICS,
  exportAlias: "EURPerFt",
};

//New Attributes as of Sprint 25
export const OIL_EUR_PER_FT: Attribute = {
  key: "OilEURPerFt",
  label: "Oil EUR Per Lateral Length",
  dataType: FLOAT,
  group: STATISTICS,
  exportAlias: "OilEURPFt",
};

export const GAS_EUR_PER_FT: Attribute = {
  key: "GasEURPerFt",
  label: "Gas EUR Per Lateral Length",
  dataType: FLOAT,
  group: STATISTICS,
  exportAlias: "GasEURPFt",
};

export const OIL_CUM_PER_FT: Attribute = {
  key: "OilCumPerFt",
  label: "Oil Cum Per Lateral Length",
  dataType: FLOAT,
  group: STATISTICS,
  exportAlias: "OilCumPFt",
};

export const GAS_CUM_PER_FT: Attribute = {
  key: "GasCumPerFt",
  label: "Gas Cum Per Lateral Length",
  dataType: FLOAT,
  group: STATISTICS,
  exportAlias: "GasCumPFt",
};

export const WATER_CUM_PER_FT: Attribute = {
  key: "WaterCumPerFt",
  label: "Water Cum Per Lateral Length",
  dataType: FLOAT,
  group: STATISTICS,
  exportAlias: "WtrCumPFt",
};

export const BOE_CUM_PER_FT: Attribute = {
  key: "BOECumPerFt",
  label: "BOE Cum Per Lateral Length",
  dataType: FLOAT,
  group: STATISTICS,
  exportAlias: "BOECumPFt",
};

export const BOE_IP90: Attribute = {
  key: "BOEIP90",
  label: "BOE IP90",
  dataType: FLOAT,
  group: STATISTICS,
  exportAlias: "BOEIP90",
};

// New Log Curve Attributes as of Sprint 29
export const VSHALE: Attribute = {
  key: "Vshale",
  label: "Vshale",
  dataType: FLOAT,
  group: STATISTICS,
  exportAlias: "Vshale",
};
export const DENSITY: Attribute = {
  key: "Density",
  label: "Density",
  dataType: FLOAT,
  group: STATISTICS,
  exportAlias: "Density",
};
export const NEUTRON_POROSITY: Attribute = {
  key: "NeutronPorosity",
  label: "Neutron Porosity",
  dataType: FLOAT,
  group: STATISTICS,
  exportAlias: "NPHI",
};
export const RESISTIVITY: Attribute = {
  key: "Resistivity",
  label: "Resistivity",
  dataType: FLOAT,
  group: STATISTICS,
  exportAlias: "Resistivit",
};
export const SONIC: Attribute = {
  key: "Sonic",
  label: "Sonic",
  dataType: FLOAT,
  group: STATISTICS,
  exportAlias: "Sonic",
};

// New Recent Attributes as of Sprint 33

export const RECENT_1MO_GAS: Attribute = {
  key: "Recent1MoGas",
  label: "Recent1MoGas",
  dataType: FLOAT,
  group: STATISTICS,
  exportAlias: "Recent1MoG",
};

export const RECENT_1MO_OIL: Attribute = {
  key: "Recent1MoOil",
  label: "Recent1MoOil",
  dataType: FLOAT,
  group: STATISTICS,
  exportAlias: "Recent1MoO",
};

export const RECENT_1MO_WATER: Attribute = {
  key: "Recent1MoWater",
  label: "Recent1MoWater",
  dataType: FLOAT,
  group: STATISTICS,
  exportAlias: "Recent1MoW",
};

export const RECENT_6MO_GAS: Attribute = {
  key: "Recent6MoGas",
  label: "Recent6MoGas",
  dataType: FLOAT,
  group: STATISTICS,
  exportAlias: "Recent6MoG",
};

export const RECENT_6MO_OIL: Attribute = {
  key: "Recent6MoOil",
  label: "Recent6MoOil",
  dataType: FLOAT,
  group: STATISTICS,
  exportAlias: "Recent6MoO",
};

export const RECENT_6MO_WATER: Attribute = {
  key: "Recent6MoWater",
  label: "Recent6MoWater",
  dataType: FLOAT,
  group: STATISTICS,
  exportAlias: "Recent6MoW",
};

export const RECENT_1YR_GAS: Attribute = {
  key: "Recent1YrGas",
  label: "Recent1YrGas",
  dataType: FLOAT,
  group: STATISTICS,
  exportAlias: "Recent1YrG",
};

export const RECENT_1YR_OIL: Attribute = {
  key: "Recent1YrOil",
  label: "Recent1YrOil",
  dataType: FLOAT,
  group: STATISTICS,
  exportAlias: "Recent1YrO",
};

export const RECENT_1YR_WATER: Attribute = {
  key: "Recent1YrWater",
  label: "Recent1YrWater",
  dataType: FLOAT,
  group: STATISTICS,
  exportAlias: "Recent1YrW",
};

// UNGROUPED ATTRIBUTES
export const DRILLING_DAYS: Attribute = {
  key: "DrillingDays",
  label: "Drilling Days",
  dataType: INTEGER,
  group: NO_GROUP,
  exportAlias: "DrillDays",
};

export const TREATMENT_REMARKS: Attribute = {
  key: "TreatmentRemarks",
  label: "Treatment Remarks",
  dataType: TEXT,
  group: NO_GROUP,
  exportAlias: "TreatRemar",
};

//DECLINE CURVE PARAMETERS ATTRIBUTES
export const OIL_ALGORITHM: Attribute = {
  key: "oilAlgorithm",
  label: "Oil Algorithm",
  dataType: TEXT,
  group: NO_GROUP,
  exportAlias: "OilAlgo",
};

export const OIL_INITIAL_RATE: Attribute = {
  key: "oilInitialRate",
  label: "Oil Initial Rate",
  dataType: FLOAT,
  group: NO_GROUP,
  exportAlias: "OilIniRate",
};

export const OIL_DECLINE_RATE: Attribute = {
  key: "oilDeclineRate",
  label: "Oil Decline Rate",
  dataType: FLOAT,
  group: NO_GROUP,
  exportAlias: "OilDeclineR",
};

export const OIL_B_FACTOR: Attribute = {
  key: "oilExponent",
  label: "Oil B-Factor",
  dataType: FLOAT,
  group: NO_GROUP,
  exportAlias: "OilBFactor",
};

export const OIL_START: Attribute = {
  key: "oilStart",
  label: "Oil Start",
  dataType: DATE,
  group: NO_GROUP,
  exportAlias: "OilStart",
};

export const GAS_ALGORITHM: Attribute = {
  key: "gasAlgorithm",
  label: "Gas Algorithm",
  dataType: TEXT,
  group: NO_GROUP,
  exportAlias: "GasAlgo",
};

export const GAS_INITIAL_RATE: Attribute = {
  key: "gasInitialRate",
  label: "Gas Initial Rate",
  dataType: FLOAT,
  group: NO_GROUP,
};

export const GAS_DECLINE_RATE: Attribute = {
  key: "gasDeclineRate",
  label: "Gas Decline Rate",
  dataType: FLOAT,
  group: NO_GROUP,
  exportAlias: "GasDeclineR",
};

export const GAS_B_FACTOR: Attribute = {
  key: "gasExponent",
  label: "Gas B-Factor",
  dataType: FLOAT,
  group: NO_GROUP,
  exportAlias: "GasBFactor",
};

export const GAS_START: Attribute = {
  key: "gasStart",
  label: "Gas Start",
  dataType: DATE,
  group: NO_GROUP,
  exportAlias: "GasStart",
};

export const WATER_ALGORITHM: Attribute = {
  key: "waterAlgorithm",
  label: "Water Algorithm",
  dataType: TEXT,
  group: NO_GROUP,
  exportAlias: "WtrAlgo",
};

export const WATER_INITIAL_RATE: Attribute = {
  key: "waterInitialRate",
  label: "Water Initial Rate",
  dataType: FLOAT,
  group: NO_GROUP,
  exportAlias: "WtrIniRate",
};

export const WATER_DECLINE_RATE: Attribute = {
  key: "waterDeclineRate",
  label: "Water Decline Rate",
  dataType: FLOAT,
  group: NO_GROUP,
  exportAlias: "WtrDeclineR",
};

export const WATER_B_FACTOR: Attribute = {
  key: "waterExponent",
  label: "Water B-Factor",
  dataType: FLOAT,
  group: NO_GROUP,
  exportAlias: "WtrBFactor",
};

export const WATER_START: Attribute = {
  key: "waterStart",
  label: "Water Start",
  dataType: DATE,
  group: NO_GROUP,
  exportAlias: "WtrStart",
};

// ID
export const STATE_WELL_ID: Attribute = {
  key: "StateWellID",
  label: "State Well ID",
  dataType: TEXT,
  group: ID,
  exportAlias: "StWellID",
};
export const WELL_ID: Attribute = {
  key: "WellID",
  label: "WellID",
  dataType: INTEGER,
  group: ID,
};

//Additional Attr
export const CUM_YIELD: Attribute = {
  key: "CumYield",
  label: "Cum Yield",
  dataType: FLOAT,
  group: NO_GROUP,
  exportAlias: "YLDCum",
};

export const SURFACE_UWI: Attribute = {
  key: "SurfaceUWI",
  label: "Surface UWI",
  dataType: TEXT,
  group: NO_GROUP,
};

export const WELL_FORECAST_YIELD_ULTIMATE: Attribute = {
  key: "WellForecastYieldUltimate",
  label: "Well Forecast Yield Ultimate",
  dataType: FLOAT,
  group: NO_GROUP,
  exportAlias: "YLDUtimate",
};

export const LOE_COST: Attribute = {
  key: "LoeCost",
  label: "LOE",
  dataType: FLOAT,
  group: WELL_ECONOMICS,
  exportAlias: "LoeCost",
};

export const GPT_COST: Attribute = {
  key: "GptCost",
  label: "GP&T",
  dataType: FLOAT,
  group: WELL_ECONOMICS,
  exportAlias: "GptCost",
};

export const WATER_COST: Attribute = {
  key: "WaterCost",
  label: "Water Expenses",
  dataType: FLOAT,
  group: WELL_ECONOMICS,
  exportAlias: "WaterCost",
};

export const FIXED_COST: Attribute = {
  key: "FixedCost",
  label: "Fixed Cost",
  dataType: INTEGER,
  group: WELL_ECONOMICS,
  exportAlias: "FixedCost",
};

export const DRILLING_COMPLETION_CAP_EX: Attribute = {
  key: "DrillingCompletionCapEx",
  label: "Capex",
  dataType: FLOAT,
  group: WELL_ECONOMICS,
  exportAlias: "DCCapex",
};

export const INFRA_CAP_EX: Attribute = {
  key: "InfraCapEx",
  label: "Infrastructure",
  dataType: FLOAT,
  group: WELL_ECONOMICS,
  exportAlias: "InfraCapEx",
};

export const WORKING_INTEREST: Attribute = {
  key: "WorkingInterest",
  label: "Working Interest",
  dataType: INTEGER,
  group: WELL_ECONOMICS,
  exportAlias: "WorkingInt",
};

export const ROYALTY: Attribute = {
  key: "Royalty",
  label: "Royalty",
  dataType: INTEGER,
  group: WELL_ECONOMICS,
  exportAlias: "Royalty",
};

export const INTERNAL_RATE_OF_RETURN: Attribute = {
  key: "InternalRateOfReturn",
  label: "IRR",
  dataType: INTEGER,
  group: WELL_ECONOMICS,
  exportAlias: "IRR",
};

export const BREAK_EVEN_PRICE: Attribute = {
  key: "BreakEvenPrice",
  label: "Breakeven Price",
  dataType: FLOAT,
  group: WELL_ECONOMICS,
  exportAlias: "BreakEven",
};

export const NET_PRESENT_VALUE: Attribute = {
  key: "NetPresentValue",
  label: "NPV",
  dataType: INTEGER,
  group: WELL_ECONOMICS,
  exportAlias: "NPV",
};

export const PAYOUT: Attribute = {
  key: "Payout",
  label: "Payback",
  dataType: FLOAT,
  group: WELL_ECONOMICS,
  exportAlias: "Payout",
};

//Permits
export const PERMIT_NUMBER: Attribute = {
  key: "PermitNumber",
  label: "Permit Number",
  dataType: TEXT,
  group: PERMITS,
};

export const PERMIT_APPROVAL_DATE: Attribute = {
  key: "PermitApprovalDate",
  label: "Permit Approved Date",
  dataType: DATE,
  group: PERMITS,
};

export const PERMIT_POSTED_DATE: Attribute = {
  key: "PermitPostedDate",
  label: "Permit Posted Date",
  dataType: DATE,
  group: PERMITS,
};

export const PERMIT_TOTAL_DEPTH: Attribute = {
  key: "ProposedTotalDepth",
  label: "Permit Depth",
  dataType: FLOAT,
  group: PERMITS,
};

export const PERMIT_FORMATION_DEPTH: Attribute = {
  key: "FormationDepth",
  label: "Permit Formation Depth",
  dataType: FLOAT,
  group: PERMITS,
};

export const PERMIT_HAS_H2S: Attribute = {
  key: "H2SFlag",
  label: "Permit Has H2S",
  dataType: BOOLEAN,
  group: PERMITS,
};

export const PERMIT_TYPE: Attribute = {
  key: "PermitType",
  label: "Permit Type",
  dataType: TEXT,
  group: NO_GROUP,
};

export const PERMIT_PURPOSE: Attribute = {
  key: "WellPurpose",
  label: "Permit Purpose",
  dataType: TEXT,
  group: NO_GROUP,
};

export const OPERATOR_CONTACT: Attribute = {
  key: "OperatorContact",
  label: "Operator Contact",
  dataType: TEXT,
  group: NO_GROUP,
};

export const OPERATOR_PHONE_NUMBER: Attribute = {
  key: "OperatorPhoneNumber",
  label: "Operator Phone Number",
  dataType: TEXT,
  group: NO_GROUP,
};

export const OPERATOR_ADDRESS: Attribute = {
  key: "OperatorAddress",
  label: "Operator Address",
  dataType: TEXT,
  group: NO_GROUP,
};

export const OPERATOR_CITY: Attribute = {
  key: "OperatorCity",
  label: "Operator City",
  dataType: TEXT,
  group: NO_GROUP,
};

export const OPERATOR_STATE: Attribute = {
  key: "OperatorState",
  label: "Operator State",
  dataType: TEXT,
  group: NO_GROUP,
};

export const OPERATOR_ZIP_CODE: Attribute = {
  key: "OperatorZipCode",
  label: "Operator ZipCode",
  dataType: TEXT,
  group: NO_GROUP,
};

//REQUIRED ATTRIBUTES
export const RECORD_TYPE: Attribute = {
  key: "RecordType",
  label: "Record Type",
  dataType: TEXT_SELECT,
  group: NO_GROUP,
};

export const MAJOR_PHASE: Attribute = {
  key: "CalculatedMajorPhase",
  label: "Major Phase",
  dataType: TEXT_SELECT,
  group: NO_GROUP,
};

export const DATE_TYPE_FIELDS = [
  COMPLETION_DATE.key,
  TD_DATE.key,
  PLUG_DATE.key,
  FIRST_MONTH.key,
  LAST_MONTH.key,
  SPUD_DATE.key,
];

export const ATTRIBUTE_INFO_BY_KEY: KeyToAttributeMapper = {
  [RECORD_TYPE.key]: RECORD_TYPE,
  [COUNTRY.key]: COUNTRY,
  [STATE_NAME.key]: STATE_NAME,
  [COUNTY.key]: COUNTY,
  [WELL_NAME.key]: WELL_NAME,
  [WELL_NUMBER.key]: WELL_NUMBER,
  [FIELD_NAME.key]: FIELD_NAME,
  [BASIN_NAME.key]: BASIN_NAME,
  [DISPLAY_FORMATION.key]: DISPLAY_FORMATION,
  [INTERPRETED_PRODUCING_FORMATION.key]: INTERPRETED_PRODUCING_FORMATION,
  [OPERATOR_NAME.key]: OPERATOR_NAME,
  [WELL_STATUS.key]: WELL_STATUS,
  [WELL_TYPE.key]: WELL_TYPE,
  [MEASURED_DEPTH.key]: MEASURED_DEPTH,
  [TOTAL_VERTICAL_DEPTH.key]: TOTAL_VERTICAL_DEPTH,
  [SLANT.key]: SLANT,
  [LAST_INJECTION_TYPE.key]: LAST_INJECTION_TYPE,
  [LAST_INJECTION_FORMATION.key]: LAST_INJECTION_FORMATION,
  [ULTIMATE_OWNER.key]: ULTIMATE_OWNER,
  [HAS_PRODUCTION.key]: HAS_PRODUCTION,
  [HAS_FORECAST.key]: HAS_FORECAST,
  [TEST.key]: TEST,
  [PERF.key]: PERF,
  [HAS_INJECTION.key]: HAS_INJECTION,
  [HAS_VENT_FLARE.key]: HAS_VENT_FLARE,
  [OFFSHORE_FLG.key]: OFFSHORE_FLG,
  [HAS_WELL_ECONOMICS.key]: HAS_WELL_ECONOMICS,
  [SPUD_DATE.key]: SPUD_DATE,
  [TD_DATE.key]: TD_DATE,
  [COMPLETION_DATE.key]: COMPLETION_DATE,
  [FIRST_MONTH.key]: FIRST_MONTH,
  [LAST_MONTH.key]: LAST_MONTH,
  [PLUG_DATE.key]: PLUG_DATE,
  [SECTION.key]: SECTION,
  [TOWNSHIP.key]: TOWNSHIP,
  [TOWNSHIP_DIRECTION.key]: TOWNSHIP_DIRECTION,
  [RANGE.key]: RANGE,
  [RANGE_DIRECTION.key]: RANGE_DIRECTION,
  [DISTRICT.key]: DISTRICT,
  [AREA.key]: AREA,
  [OFFSHORE_BLOCK.key]: OFFSHORE_BLOCK,
  [LATERAL_LENGTH.key]: LATERAL_LENGTH,
  [PERF_INTERVAL_TOP.key]: PERF_INTERVAL_TOP,
  [PERF_INTERVAL_BOTTOM.key]: PERF_INTERVAL_BOTTOM,
  [NUMBER_OF_STAGES.key]: NUMBER_OF_STAGES,
  [FRAC_FLUID_AMOUNT.key]: FRAC_FLUID_AMOUNT,
  [PROPPANT_AMOUNT.key]: PROPPANT_AMOUNT,
  [MAX_TREATMENT_RATE.key]: MAX_TREATMENT_RATE,
  [MAX_TREATMENT_PRESSURE.key]: MAX_TREATMENT_PRESSURE,
  [ACID_AMOUNT.key]: ACID_AMOUNT,
  [PROPPANT_AMOUNT_PER_FT.key]: PROPPANT_AMOUNT_PER_FT,
  [FRAC_FLUID_AMOUNT_PER_FT.key]: FRAC_FLUID_AMOUNT_PER_FT,
  [CUM_BOE.key]: CUM_BOE,
  [CUM_GAS.key]: CUM_GAS,
  [CUM_OIL.key]: CUM_OIL,
  [CUM_WATER.key]: CUM_WATER,
  [CUM_INJECTION_LIQUID.key]: CUM_INJECTION_LIQUID,
  [CUM_INJECTION_GAS.key]: CUM_INJECTION_GAS,
  [WELL_FORECAST_BOE_ULTIMATE.key]: WELL_FORECAST_BOE_ULTIMATE,
  [WELL_FORECAST_GAS_ULTIMATE.key]: WELL_FORECAST_GAS_ULTIMATE,
  [WELL_FORECAST_OIL_ULTIMATE.key]: WELL_FORECAST_OIL_ULTIMATE,
  [WELL_FORECAST_WATER_ULTIMATE.key]: WELL_FORECAST_WATER_ULTIMATE,
  [MAX_BOE.key]: MAX_BOE,
  [MAX_GAS.key]: MAX_GAS,
  [MAX_GAS_PLUS_2.key]: MAX_GAS_PLUS_2,
  [MAX_OIL.key]: MAX_OIL,
  [MAX_OIL_PLUS_2.key]: MAX_OIL_PLUS_2,
  [MAX_WATER.key]: MAX_WATER,
  [MAX_WATER_PLUS_2.key]: MAX_WATER_PLUS_2,
  [WATER_CUT_TOTAL_CUM.key]: WATER_CUT_TOTAL_CUM,
  [WATER_OIL_RATIO_TOTAL_CUM.key]: WATER_OIL_RATIO_TOTAL_CUM,
  [CUM_GOR.key]: CUM_GOR,
  [BOE_MAX_PER_FT.key]: BOE_MAX_PER_FT,
  [WELL_FORECAST_BOE_REMAINING.key]: WELL_FORECAST_BOE_REMAINING,
  [ELEVATION_GROUND.key]: ELEVATION_GROUND,
  [ELEVATION_KELLY_BUSHING.key]: ELEVATION_KELLY_BUSHING,
  [EUR_PER_FT.key]: EUR_PER_FT,
  [DISPOSITION_TYPE.key]: DISPOSITION_TYPE,
  [WELL_FORECAST_GAS_REMAINING.key]: WELL_FORECAST_GAS_REMAINING,
  [WELL_FORECAST_GOR_ULTIMATE.key]: WELL_FORECAST_GOR_ULTIMATE,
  [LEASE_NAME.key]: LEASE_NAME,
  [LEASE_NAME_AND_ID.key]: LEASE_NAME_AND_ID,
  [WELL_FORECAST_OIL_REMAINING.key]: WELL_FORECAST_OIL_REMAINING,
  [STATE_LEASE_ID.key]: STATE_LEASE_ID,
  [STATE_WELL_ID.key]: STATE_WELL_ID,
  [CUM_VENT_FLARE_VOL.key]: CUM_VENT_FLARE_VOL,
  [WELL_FORECAST_WATER_CUT_ULTIMATE.key]: WELL_FORECAST_WATER_CUT_ULTIMATE,
  [WELL_FORECAST_WATER_REMAINING.key]: WELL_FORECAST_WATER_REMAINING,
  //New Attributes as of Sprint 18
  [CLOSEST_HORIZONTAL_DISTANCE_IN_ZONE.key]:
    CLOSEST_HORIZONTAL_DISTANCE_IN_ZONE,
  [CLOSEST_HORIZONTAL_DISTANCE.key]: CLOSEST_HORIZONTAL_DISTANCE,
  [CLOSEST_VERTICAL_DISTANCE.key]: CLOSEST_VERTICAL_DISTANCE,
  [COUNT_OF_PARENT_WELLS.key]: COUNT_OF_PARENT_WELLS,
  [COUNT_OF_CHILD_WELLS.key]: COUNT_OF_CHILD_WELLS,
  [COUNT_OF_SIBLING_WELLS.key]: COUNT_OF_SIBLING_WELLS,
  //New Attributes as of Sprint 25
  [OIL_EUR_PER_FT.key]: OIL_EUR_PER_FT,
  [GAS_EUR_PER_FT.key]: GAS_EUR_PER_FT,
  [OIL_CUM_PER_FT.key]: OIL_CUM_PER_FT,
  [GAS_CUM_PER_FT.key]: GAS_CUM_PER_FT,
  [WATER_CUM_PER_FT.key]: WATER_CUM_PER_FT,
  [BOE_CUM_PER_FT.key]: BOE_CUM_PER_FT,
  [BOE_IP90.key]: BOE_IP90,
  // New Log Curve Attributes as of Sprint 29
  [VSHALE.key]: VSHALE,
  [DENSITY.key]: DENSITY,
  [NEUTRON_POROSITY.key]: NEUTRON_POROSITY,
  [RESISTIVITY.key]: RESISTIVITY,
  [SONIC.key]: SONIC,
  //New Attribute HasDST as of Sprint 30
  [HAS_DST.key]: HAS_DST,
  //
  // New Recent Attributes as of Sprint 33
  [RECENT_1MO_OIL.key]: RECENT_1MO_OIL,
  [RECENT_1MO_GAS.key]: RECENT_1MO_GAS,
  [RECENT_1MO_WATER.key]: RECENT_1MO_WATER,
  [RECENT_6MO_OIL.key]: RECENT_6MO_OIL,
  [RECENT_6MO_GAS.key]: RECENT_6MO_GAS,
  [RECENT_6MO_WATER.key]: RECENT_6MO_WATER,
  [RECENT_1YR_OIL.key]: RECENT_1YR_OIL,
  [RECENT_1YR_GAS.key]: RECENT_1YR_GAS,
  [RECENT_1YR_WATER.key]: RECENT_1YR_WATER,
  [HAS_LAS.key]: HAS_LAS,
  [HAS_RASTER.key]: HAS_RASTER,

  // New Attribute as of Sprint 36
  [HAS_WELL_FILE.key]: HAS_WELL_FILE,

  // New Attribute as of Sprint 39
  [LOE_COST.key]: LOE_COST,
  [GPT_COST.key]: GPT_COST,
  [WATER_COST.key]: WATER_COST,
  [FIXED_COST.key]: FIXED_COST,
  [DRILLING_COMPLETION_CAP_EX.key]: DRILLING_COMPLETION_CAP_EX,
  [INFRA_CAP_EX.key]: INFRA_CAP_EX,
  [WORKING_INTEREST.key]: WORKING_INTEREST,
  [ROYALTY.key]: ROYALTY,
  [INTERNAL_RATE_OF_RETURN.key]: INTERNAL_RATE_OF_RETURN,
  [BREAK_EVEN_PRICE.key]: BREAK_EVEN_PRICE,
  [NET_PRESENT_VALUE.key]: NET_PRESENT_VALUE,
  [PAYOUT.key]: PAYOUT,

  //New Attribute as of Sprint 42
  [CUM_YIELD.key]: CUM_YIELD,
  [WELL_FORECAST_YIELD_ULTIMATE.key]: WELL_FORECAST_YIELD_ULTIMATE,

  //New Attribute as of Sprint 44
  [PERMIT_NUMBER.key]: PERMIT_NUMBER,
  [PERMIT_APPROVAL_DATE.key]: PERMIT_APPROVAL_DATE,
  [PERMIT_TOTAL_DEPTH.key]: PERMIT_TOTAL_DEPTH,
  [PERMIT_POSTED_DATE.key]: PERMIT_POSTED_DATE,
  [PERMIT_FORMATION_DEPTH.key]: PERMIT_FORMATION_DEPTH,
  [PERMIT_HAS_H2S.key]: PERMIT_HAS_H2S,
  [PERMIT_TYPE.key]: PERMIT_TYPE,
  [PERMIT_PURPOSE.key]: PERMIT_PURPOSE,

  //New Attribute as of sprint 62
  [HAS_WFT.key]: HAS_WFT,
};
