import { memo, useCallback, useEffect, useMemo, useState } from "react";

import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import {
  Button,
  Checkbox,
  Dialog,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  IconButton,
  Typography,
} from "@mui/material";

import classNames from "classnames";

import { WellSystemData } from "../../../types/panels/wellPanel/wellPanel";

import {
  GUN_BARREL_SECTION,
  UWI,
} from "../../../constants/panels/wellPanel/wellInfo";
import {
  WELL_CHARTS_EXPORT_CATEGORY,
  WELL_EXPORT_ALL,
  WELL_INFORMATION_BY_KEY,
  WELL_INFORMATION_EXPORT_CATEGORY,
  WELL_PANEL_CHARTS_BY_KEY,
  WELL_PANEL_CHARTS_EXPORT_CATEGORY,
  WELL_PANEL_INFORMATION_EXPORT_CATEGORY,
} from "../../../constants/panels/wellPanel/wellPanelExport";

import useExportPanelStore from "../../../store/exportPanel/exportPanelStore";

import useExportWellPanelData from "../../../customHooks/exports/useExportWellPanelData";
import useExportWellPanel from "../../../customHooks/panels/wellPanel/export/useExportWellPanel";
import useWellPanelExportChecking from "../../../customHooks/panels/wellPanel/export/useWellPanelExportChecking";

import WellItemsAccordion from "../../common/WellItemsAccordion";

import { CloseIcon } from "../../../assets/CustomIcons";

const ExportWellPanel = () => {
  const [hasTriggeredApiCalls, setHasTriggeredApiCalls] = useState(false);
  const isOpenExportWellPanelDialog = useExportPanelStore(
    (state) => state.isOpenExportWellPanelDialog
  );

  const updateIsOpenExportWellPanelDialog = useExportPanelStore(
    (state) => state.updateIsOpenExportWellPanelDialog
  );

  const exportModalProps = useExportPanelStore(
    (state) => state.exportModalProps
  );

  const activePage = exportModalProps?.activeWellPanelInfo?.activePage ?? 0;

  const activeWellData =
    exportModalProps?.activeWellPanelInfo?.wellSystemData?.find(
      (charts: WellSystemData) => charts.pageNumber === activePage
    ) ?? undefined;

  const { exportWellPanelExcel } = useExportWellPanelData();
  const {
    updateAllSectionsData,
    checkLoadedSectionsComplete,
    fetchAllDataLoading,
    fetchAllDataSuccess,
    loadingDstData,
    loadingWftData,
  } = useWellPanelExportChecking();

  const {
    checkedItems,
    wellInformationSectionList,
    plotChartSectionList,
    handleCheckedCategoryItem,
    handleCheckedItem,
    handleExportImagePlots,
  } = useExportWellPanel();

  const handleCloseModal = () => {
    updateIsOpenExportWellPanelDialog(false);
    setHasTriggeredApiCalls(false);
  };
  const handleOnExportClick = useCallback(() => {
    if (exportModalProps?.activeWellPanelInfo?.wellSystemData) {
      if (
        checkLoadedSectionsComplete(
          exportModalProps.activeWellPanelInfo.wellSystemData
        )
      ) {
        exportWellPanelExcel(
          exportModalProps.activeWellPanelInfo.wellSystemData,
          checkedItems
        );
      } else {
        updateAllSectionsData(exportModalProps.activeWellPanelInfo);
        setHasTriggeredApiCalls(true);
      }
    }
  }, [
    exportModalProps?.activeWellPanelInfo,
    exportModalProps?.activeWellPanelInfo?.wellSystemData,
    checkedItems,
  ]);

  useEffect(() => {
    if (
      isOpenExportWellPanelDialog &&
      hasTriggeredApiCalls &&
      !fetchAllDataLoading &&
      !loadingDstData &&
      !loadingWftData &&
      fetchAllDataSuccess &&
      exportModalProps?.activeWellPanelInfo?.wellSystemData &&
      checkLoadedSectionsComplete(
        exportModalProps.activeWellPanelInfo.wellSystemData
      )
    ) {
      exportWellPanelExcel(
        exportModalProps.activeWellPanelInfo.wellSystemData,
        checkedItems
      );
      setHasTriggeredApiCalls(false);
    }
  }, [
    fetchAllDataLoading,
    loadingDstData,
    loadingWftData,
    fetchAllDataSuccess,
    exportModalProps?.activeWellPanelInfo,
    exportModalProps?.activeWellPanelInfo?.wellSystemData,
    isOpenExportWellPanelDialog,
    hasTriggeredApiCalls,
  ]);

  const isModalChecked = useMemo(() => {
    return Boolean(
      plotChartSectionList.every((list) => checkedItems.includes(list)) &&
        wellInformationSectionList.every((list) => checkedItems.includes(list))
    );
  }, [plotChartSectionList, wellInformationSectionList, checkedItems]);

  const isActiveWellPanelHasCharts = useMemo(() => {
    return Boolean(
      activeWellData?.gunBarrelData || activeWellData?.productionData
    );
  }, [activeWellData?.gunBarrelData, activeWellData?.productionData]);

  const isChartVisible = (section: string) => {
    if (section === GUN_BARREL_SECTION.key) {
      return Boolean(activeWellData?.gunBarrelData);
    } else {
      return Boolean(activeWellData?.productionData);
    }
  };

  const disableExportImage = useMemo(() => {
    return Boolean(
      plotChartSectionList.every((list) => !checkedItems.includes(list)) ||
        !isActiveWellPanelHasCharts
    );
  }, [plotChartSectionList, isActiveWellPanelHasCharts, checkedItems]);

  const disableExportExcel = useMemo(() => {
    return Boolean(
      wellInformationSectionList.every(
        (list) => !checkedItems.includes(list)
      ) ||
        fetchAllDataLoading ||
        loadingDstData ||
        loadingWftData
    );
  }, [
    wellInformationSectionList,
    fetchAllDataLoading,
    checkedItems,
    loadingDstData,
    loadingWftData,
  ]);

  return (
    <>
      {exportModalProps && (
        <Dialog
          className="export-well-panel-modal"
          open={isOpenExportWellPanelDialog}
          disablePortal
        >
          <DialogTitle>
            <div className="uwi-label">
              Export{" "}
              <div className="uwi-label">
                <Typography>
                  UWI {(activeWellData?.wellCardData[UWI.key] as string) ?? ""}
                </Typography>
              </div>
            </div>
            <div className="close-button">
              <IconButton disableRipple size="small" onClick={handleCloseModal}>
                <CloseIcon />
              </IconButton>
            </div>
          </DialogTitle>

          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  name="All"
                  disableRipple
                  checked={isModalChecked}
                  onChange={(e, checked) =>
                    handleCheckedCategoryItem(WELL_EXPORT_ALL, checked)
                  }
                  checkedIcon={<CheckBoxOutlinedIcon />}
                />
              }
              label={<Typography>All</Typography>}
            />
            <WellItemsAccordion
              title={WELL_PANEL_INFORMATION_EXPORT_CATEGORY.label}
              hasCheckbox
              checkedAccordion={wellInformationSectionList.every((list) =>
                checkedItems.includes(list)
              )}
              onChecked={(checked) => {
                handleCheckedCategoryItem(
                  WELL_INFORMATION_EXPORT_CATEGORY,
                  checked
                );
              }}
            >
              <FormGroup>
                {wellInformationSectionList.length &&
                  wellInformationSectionList.map((key) => (
                    <FormControlLabel
                      key={key}
                      control={
                        <Checkbox
                          onChange={(e) =>
                            handleCheckedItem(WELL_INFORMATION_BY_KEY[key].key)
                          }
                          value={
                            <Typography>
                              {WELL_INFORMATION_BY_KEY[key].displayLabel}
                            </Typography>
                          }
                          checked={checkedItems.includes(key)}
                          disableRipple
                          checkedIcon={<CheckBoxOutlinedIcon />}
                        />
                      }
                      label={
                        <Typography>
                          {WELL_INFORMATION_BY_KEY[key].displayLabel}
                        </Typography>
                      }
                    />
                  ))}
              </FormGroup>
            </WellItemsAccordion>
            {/* For Charts and Plots Exports */}
            {plotChartSectionList.length > 0 && isActiveWellPanelHasCharts && (
              <WellItemsAccordion
                title={WELL_PANEL_CHARTS_EXPORT_CATEGORY.label}
                hasCheckbox
                checkedAccordion={plotChartSectionList.every((list) =>
                  checkedItems.includes(list)
                )}
                onChecked={(checked) => {
                  handleCheckedCategoryItem(
                    WELL_CHARTS_EXPORT_CATEGORY,
                    checked
                  );
                }}
              >
                <FormGroup>
                  {plotChartSectionList.length > 0 &&
                    plotChartSectionList.map(
                      (key) =>
                        isChartVisible(key) && (
                          <FormControlLabel
                            key={key}
                            control={
                              <Checkbox
                                onChange={(e) =>
                                  handleCheckedItem(
                                    WELL_PANEL_CHARTS_BY_KEY[key].key
                                  )
                                }
                                value={
                                  <Typography>
                                    {WELL_PANEL_CHARTS_BY_KEY[key].displayLabel}
                                  </Typography>
                                }
                                checked={checkedItems.includes(key)}
                                disableRipple
                                checkedIcon={<CheckBoxOutlinedIcon />}
                              />
                            }
                            label={
                              <Typography>
                                {WELL_PANEL_CHARTS_BY_KEY[key].displayLabel}
                              </Typography>
                            }
                          />
                        )
                    )}
                </FormGroup>
              </WellItemsAccordion>
            )}
          </FormGroup>
          <div className="export-button-groups">
            <div className="close-button">
              <Button onClick={handleCloseModal} disableRipple aria-disabled>
                CLOSE
              </Button>
            </div>
            <div className="round-buttons">
              <div
                className={classNames("excel-export-button", {
                  "disabled-excel": checkedItems.length === 0,
                })}
              >
                <Button
                  disableRipple
                  disabled={disableExportExcel}
                  onClick={() => {
                    handleOnExportClick();
                  }}
                >
                  <Typography>EXPORT AS EXCEL</Typography>
                </Button>
              </div>
              <div
                className={classNames("image-export-button", {
                  "disabled-image": disableExportImage,
                })}
              >
                <Button
                  disableRipple
                  disabled={disableExportImage}
                  onClick={() =>
                    handleExportImagePlots(
                      (activeWellData?.wellCardData[UWI.key] as string) ?? "",
                      exportModalProps.gunBarrelRef,
                      exportModalProps.productionRef,
                      exportModalProps.cashflowRef
                    )
                  }
                >
                  <Typography> EXPORT AS IMAGE</Typography>
                </Button>
              </div>
            </div>
          </div>
        </Dialog>
      )}
    </>
  );
};
export default memo(ExportWellPanel);
