import { Casings } from "../../types/panels/wellPanel/casingsType";
import { WellDataExport } from "../../types/panels/wellPanel/exportWellPanel";
import { Formations } from "../../types/panels/wellPanel/formationType";
import { Perforation } from "../../types/panels/wellPanel/perforationType";
import { Permits } from "../../types/panels/wellPanel/permitsType";
import {
  DstPressureDataWellPanelExport,
  WftPressureDataWellPanelExport,
} from "../../types/panels/wellPanel/pressureDataType";
import { Test } from "../../types/panels/wellPanel/testType";
import { WellData } from "../../types/panels/wellPanel/wellPanel";

import {
  PERMIT_APPROVAL_DATE,
  PERMIT_NUMBER,
  PERMIT_POSTED_DATE,
  PERMIT_PURPOSE,
  PERMIT_TOTAL_DEPTH,
  WELL_NAME,
  WELL_NUMBER,
} from "../../constants/attributes";
import {
  CONDENSATE_AMT,
  DOC_TYPE,
  DST_NO,
  DST_TEMP,
  FINAL_SHUT_IN_END_TGS,
  FORMATION,
  FORMATION_PRESSURE,
  FORMATION_WATER_RESISTIVITY,
  INITIAL_SHUT_IN_END_TGS,
  OIL_AMT,
  PERMEABILITY,
  POROSITY,
  REPORTED_FORMATION,
  SAMPLE_REMARKS,
  SUMMARY_TEST_REMARKS,
  TEST_DEPTH,
  TEST_NUMBER,
  TEST_TYPE,
  TGS_LANDING_ZONE,
  TOTAL_GAS_AMT,
  WATER_AMT,
  WATER_SATURATION,
} from "../../constants/panels/wellPanel/pressureData";
import {
  UWI,
  WELL_INFO_COMPLETION_DATA_ATTRIBUTES,
  WELL_INFO_DECLINE_CURVE_ATTRIBUTES,
  WELL_INFO_HEADER_ATTRIBUTES,
  WELL_INFO_STATISTICS_ATTRIBUTES,
  WELL_INFO_WELL_NAME,
  WELL_INFO_WELL_NUMBER,
  WELL_INFO_WELL_STATUS,
  WELL_PANEL_FLAGS,
  WELL_PANEL_SECTION,
} from "../../constants/panels/wellPanel/wellInfo";
import {
  BOTTOM_DEPTH,
  CASING_GRADE,
  CASING_PURPOSE,
  CASING_SIZE,
  CASING_TYPE,
  CASING_WEIGHT,
  FORMATION_NAME,
  GAS,
  HOLE_SIZE,
  OIL,
  PERF_BOTTOM,
  PERF_DATE,
  PERF_TOP,
  PERMIT_BOTTOM_HOLE_LAT,
  PERMIT_BOTTOM_HOLE_LONGITUDE,
  PERMIT_FORMATION,
  PERMIT_SURFACE_LATITUDE,
  PERMIT_SURFACE_LONGITUDE,
  PERMIT_TYPE_CODE,
  TEST_DATE,
  TEST_DURATION,
  TOP_DEPTH,
  WATER,
  WELL_ORIENTATION,
} from "../../constants/panels/wellPanel/wellPanelAttributes";

import { formatAttributeByKey } from "../../utils/formatters/attributeFormatter";

import usePanelFormattingRule from "../panels/usePanelFormattingRule";

const useSortWellPanelData = () => {
  const {
    formatRateAttributes,
    formatBFactorAttributes,
    formatLatLongAttributes,
  } = usePanelFormattingRule();
  const formatExportedVal = (
    attrkey: string,
    val?: number | string | boolean | null | Date
  ) => {
    return formatAttributeByKey(attrkey, val, false);
  };

  const formatExportedRates = (attrkey: string, val: number | string) => {
    return formatRateAttributes(attrkey, val, false);
  };

  const formateExportedBFactor = (attrkey: string, val: number | string) => {
    return formatBFactorAttributes(attrkey, val, false);
  };

  const sortWellPanelContent = (data: WellData[], exportList: string[]) => {
    const headerArray: WellDataExport[] = [];
    const statisticsArray: WellDataExport[] = [];
    const declineCurveParametersArray: WellDataExport[] = [];
    const completionArray: WellDataExport[] = [];

    data?.forEach((item) => {
      if (exportList.includes(WELL_PANEL_SECTION.HEADER_SECTION.key)) {
        headerArray?.push({
          [UWI.label]: formatExportedVal(UWI.key, item.uwi),
          [WELL_INFO_WELL_NAME.label]: formatExportedVal(
            WELL_INFO_WELL_NAME.key,
            item.wellName
          ),
          [WELL_INFO_WELL_NUMBER.label]: formatExportedVal(
            WELL_INFO_WELL_NUMBER.key,
            item.wellNumber
          ),
          [WELL_INFO_HEADER_ATTRIBUTES.STATE_NAME.label]: formatExportedVal(
            WELL_INFO_HEADER_ATTRIBUTES.STATE_NAME.key,
            item.stateName
          ),
          [WELL_INFO_HEADER_ATTRIBUTES.COUNTY.label]: formatExportedVal(
            WELL_INFO_HEADER_ATTRIBUTES.COUNTY.key,
            item.county
          ),
          [WELL_INFO_WELL_STATUS.label]: formatExportedVal(
            WELL_INFO_WELL_STATUS.key,
            item.wellStatus
          ),
          [WELL_INFO_HEADER_ATTRIBUTES.WELL_TYPE.label]: formatExportedVal(
            WELL_INFO_HEADER_ATTRIBUTES.WELL_TYPE.key,
            item.wellType
          ),
          [WELL_INFO_HEADER_ATTRIBUTES.SLANT.label]: formatExportedVal(
            WELL_INFO_HEADER_ATTRIBUTES.SLANT.key,
            item.slant
          ),
          [WELL_INFO_HEADER_ATTRIBUTES.OPERATOR_NAME.label]: formatExportedVal(
            WELL_INFO_HEADER_ATTRIBUTES.OPERATOR_NAME.key,
            item.operatorName
          ),
          [WELL_INFO_HEADER_ATTRIBUTES.ULTIMATE_OWNER.label]: formatExportedVal(
            WELL_INFO_HEADER_ATTRIBUTES.ULTIMATE_OWNER.key,
            item.ultimateOwner
          ),
          [WELL_INFO_HEADER_ATTRIBUTES.FIELD_NAME.label]: formatExportedVal(
            WELL_INFO_HEADER_ATTRIBUTES.FIELD_NAME.key,
            item.fieldName
          ),
          [WELL_INFO_HEADER_ATTRIBUTES.BASIN_NAME.label]: formatExportedVal(
            WELL_INFO_HEADER_ATTRIBUTES.BASIN_NAME.key,
            item.basinName
          ),
          [WELL_INFO_HEADER_ATTRIBUTES.DISPLAY_FORMATION.label]:
            formatExportedVal(
              WELL_INFO_HEADER_ATTRIBUTES.DISPLAY_FORMATION.key,
              item.displayFormation
            ),
          [WELL_INFO_HEADER_ATTRIBUTES.INTERPRETED_PRODUCING_FORMATION.label]:
            formatExportedVal(
              WELL_INFO_HEADER_ATTRIBUTES.INTERPRETED_PRODUCING_FORMATION.key,
              item.interpretedProducingFormation
            ),
          [WELL_INFO_HEADER_ATTRIBUTES.MEASURED_DEPTH.label]: formatExportedVal(
            WELL_INFO_HEADER_ATTRIBUTES.MEASURED_DEPTH.key,
            item.measuredDepth
          ),
          [WELL_INFO_HEADER_ATTRIBUTES.TOTAL_VERTICAL_DEPTH.label]:
            formatExportedVal(
              WELL_INFO_HEADER_ATTRIBUTES.TOTAL_VERTICAL_DEPTH.key,
              item.totalVerticalDepth
            ),
          [WELL_INFO_HEADER_ATTRIBUTES.LATERAL_LENGTH.label]: formatExportedVal(
            WELL_INFO_HEADER_ATTRIBUTES.LATERAL_LENGTH.key,
            item.lateralLength
          ),
          [WELL_INFO_HEADER_ATTRIBUTES.SPUD_DATE.label]: formatExportedVal(
            WELL_INFO_HEADER_ATTRIBUTES.SPUD_DATE.key,
            item.spudDate
          ),
          [WELL_INFO_HEADER_ATTRIBUTES.COMPLETION_DATE.label]:
            formatExportedVal(
              WELL_INFO_HEADER_ATTRIBUTES.COMPLETION_DATE.key,
              item.completionDate
            ),
          [WELL_INFO_HEADER_ATTRIBUTES.ELEVATION_GROUND.label]:
            formatExportedVal(
              WELL_INFO_HEADER_ATTRIBUTES.ELEVATION_GROUND.key,
              item[WELL_INFO_HEADER_ATTRIBUTES.ELEVATION_GROUND.key]
            ),
          [WELL_INFO_HEADER_ATTRIBUTES.HAS_PRODUCTION.label]: formatExportedVal(
            WELL_INFO_HEADER_ATTRIBUTES.HAS_PRODUCTION.key,
            item.hasProduction
          ),
          [WELL_INFO_HEADER_ATTRIBUTES.HAS_FORECAST.label]: formatExportedVal(
            WELL_INFO_HEADER_ATTRIBUTES.HAS_FORECAST.key,
            item.hasForecast
          ),
          [WELL_INFO_HEADER_ATTRIBUTES.PERF_INTERVAL_TOP.label]:
            formatExportedVal(
              WELL_INFO_HEADER_ATTRIBUTES.PERF_INTERVAL_TOP.key,
              item.perfIntervalTop
            ),
          [WELL_INFO_HEADER_ATTRIBUTES.PERF_INTERVAL_BOTTOM.label]:
            formatExportedVal(
              WELL_INFO_HEADER_ATTRIBUTES.PERF_INTERVAL_BOTTOM.key,
              item.perfIntervalBottom
            ),

          [WELL_INFO_HEADER_ATTRIBUTES.CLOSEST_HORIZONTAL_DISTANCE.label]:
            formatExportedVal(
              WELL_INFO_HEADER_ATTRIBUTES.CLOSEST_HORIZONTAL_DISTANCE.key,
              item.closestHorizontalDistanceInZone
            ),
          [WELL_INFO_HEADER_ATTRIBUTES.CLOSEST_HORIZONTAL_DISTANCE_IN_ZONE
            .label]: formatExportedVal(
            WELL_INFO_HEADER_ATTRIBUTES.CLOSEST_HORIZONTAL_DISTANCE_IN_ZONE.key,
            item.closestHorizontalDistance
          ),
          [WELL_INFO_HEADER_ATTRIBUTES.CLOSEST_VERTICAL_DISTANCE.label]:
            formatExportedVal(
              WELL_INFO_HEADER_ATTRIBUTES.CLOSEST_VERTICAL_DISTANCE.key,
              item.closestVerticalDistance
            ),
          [WELL_INFO_HEADER_ATTRIBUTES.COUNT_OF_PARENT_WELLS.label]:
            formatExportedVal(
              WELL_INFO_HEADER_ATTRIBUTES.COUNT_OF_PARENT_WELLS.key,
              item.countOfParentWells
            ),
          [WELL_INFO_HEADER_ATTRIBUTES.COUNT_OF_CHILD_WELLS.label]:
            formatExportedVal(
              WELL_INFO_HEADER_ATTRIBUTES.COUNT_OF_CHILD_WELLS.key,
              item.countOfChildWells
            ),
          [WELL_INFO_HEADER_ATTRIBUTES.COUNT_OF_SIBLING_WELLS.label]:
            formatExportedVal(
              WELL_INFO_HEADER_ATTRIBUTES.COUNT_OF_SIBLING_WELLS.key,
              item.countOfSiblingWells
            ),
        });
      }

      if (
        exportList.includes(WELL_PANEL_SECTION.STATISTICS_SECTION.key) &&
        (item[WELL_PANEL_FLAGS.HAS_PRODUCTION.key] ||
          item[WELL_PANEL_FLAGS.HAS_INJECTION.key])
      ) {
        statisticsArray?.push({
          [UWI.label]: item.uwi,
          [WELL_INFO_STATISTICS_ATTRIBUTES.FIRST_MONTH.label]:
            formatExportedVal(
              WELL_INFO_STATISTICS_ATTRIBUTES.FIRST_MONTH.key,
              item.firstMonth
            ),
          [WELL_INFO_STATISTICS_ATTRIBUTES.LAST_MONTH.label]: formatExportedVal(
            WELL_INFO_STATISTICS_ATTRIBUTES.LAST_MONTH.key,
            item.lastMonth
          ),
          [WELL_INFO_STATISTICS_ATTRIBUTES.CUM_OIL.label]: formatExportedVal(
            WELL_INFO_STATISTICS_ATTRIBUTES.CUM_OIL.key,
            item.cumOil
          ),
          [WELL_INFO_STATISTICS_ATTRIBUTES.MAX_OIL.label]: formatExportedVal(
            WELL_INFO_STATISTICS_ATTRIBUTES.MAX_OIL.key,
            item.maxOil
          ),
          [WELL_INFO_STATISTICS_ATTRIBUTES.MAX_OIL_PLUS_2.label]:
            formatExportedVal(
              WELL_INFO_STATISTICS_ATTRIBUTES.MAX_OIL_PLUS_2.key,
              item.maxOilPlus2
            ),
          [WELL_INFO_STATISTICS_ATTRIBUTES.WELL_FORECAST_OIL_ULTIMATE.label]:
            formatExportedVal(
              WELL_INFO_STATISTICS_ATTRIBUTES.WELL_FORECAST_OIL_ULTIMATE.key,
              item.wellForecastOilUltimate
            ),
          [WELL_INFO_STATISTICS_ATTRIBUTES.CUM_GAS.label]: formatExportedVal(
            WELL_INFO_STATISTICS_ATTRIBUTES.CUM_GAS.key,
            item.cumGas
          ),
          [WELL_INFO_STATISTICS_ATTRIBUTES.MAX_GAS.label]: formatExportedVal(
            WELL_INFO_STATISTICS_ATTRIBUTES.MAX_GAS.key,
            item.maxGas
          ),
          [WELL_INFO_STATISTICS_ATTRIBUTES.MAX_GAS_PLUS_2.label]:
            formatExportedVal(
              WELL_INFO_STATISTICS_ATTRIBUTES.MAX_GAS_PLUS_2.key,
              item.maxGasPlus2
            ),
          [WELL_INFO_STATISTICS_ATTRIBUTES.WELL_FORECAST_GAS_ULTIMATE.label]:
            formatExportedVal(
              WELL_INFO_STATISTICS_ATTRIBUTES.WELL_FORECAST_GAS_ULTIMATE.key,
              item.wellForecastGasUltimate
            ),
          [WELL_INFO_STATISTICS_ATTRIBUTES.CUM_WATER.label]: formatExportedVal(
            WELL_INFO_STATISTICS_ATTRIBUTES.CUM_WATER.key,
            item.cumWater
          ),
          [WELL_INFO_STATISTICS_ATTRIBUTES.MAX_WATER.label]: formatExportedVal(
            WELL_INFO_STATISTICS_ATTRIBUTES.MAX_WATER.key,
            item.maxWater
          ),
          [WELL_INFO_STATISTICS_ATTRIBUTES.MAX_WATER_PLUS_2.label]:
            formatExportedVal(
              WELL_INFO_STATISTICS_ATTRIBUTES.MAX_WATER_PLUS_2.key,
              item.maxWaterPlus2
            ),
          [WELL_INFO_STATISTICS_ATTRIBUTES.WELL_FORECAST_WATER_ULTIMATE.label]:
            formatExportedVal(
              WELL_INFO_STATISTICS_ATTRIBUTES.WELL_FORECAST_WATER_ULTIMATE.key,
              item.wellForecastWaterUltimate
            ),
          [WELL_INFO_STATISTICS_ATTRIBUTES.CUM_INJECTION_LIQUID.label]:
            formatExportedVal(
              WELL_INFO_STATISTICS_ATTRIBUTES.CUM_INJECTION_LIQUID.key,
              item.cumInjectionLiquid
            ),
          [WELL_INFO_STATISTICS_ATTRIBUTES.CUM_INJECTION_GAS.label]:
            formatExportedVal(
              WELL_INFO_STATISTICS_ATTRIBUTES.CUM_INJECTION_GAS.key,
              item.cumInjectionGas
            ),
        });
      }

      if (
        exportList.includes(
          WELL_PANEL_SECTION.DECLINE_CURVE_PARAMETERS_SECTION.key
        ) &&
        item[WELL_PANEL_FLAGS.HAS_FORECAST.key]
      ) {
        declineCurveParametersArray?.push({
          [WELL_INFO_DECLINE_CURVE_ATTRIBUTES.OIL_ALGORITHM.label]:
            formatExportedVal(
              WELL_INFO_DECLINE_CURVE_ATTRIBUTES.OIL_ALGORITHM.key,
              item[WELL_INFO_DECLINE_CURVE_ATTRIBUTES.OIL_ALGORITHM.key]
            ),
          [WELL_INFO_DECLINE_CURVE_ATTRIBUTES.OIL_INITIAL_RATE.label]:
            formatExportedRates(
              WELL_INFO_DECLINE_CURVE_ATTRIBUTES.OIL_INITIAL_RATE.key,
              item[
                WELL_INFO_DECLINE_CURVE_ATTRIBUTES.OIL_INITIAL_RATE.key
              ] as number
            ),
          [WELL_INFO_DECLINE_CURVE_ATTRIBUTES.OIL_DECLINE_RATE.label]:
            formatExportedRates(
              WELL_INFO_DECLINE_CURVE_ATTRIBUTES.OIL_DECLINE_RATE.key,
              item[
                WELL_INFO_DECLINE_CURVE_ATTRIBUTES.OIL_DECLINE_RATE.key
              ] as number
            ),
          [WELL_INFO_DECLINE_CURVE_ATTRIBUTES.OIL_B_FACTOR.label]:
            formateExportedBFactor(
              WELL_INFO_DECLINE_CURVE_ATTRIBUTES.OIL_B_FACTOR.key,
              item[
                WELL_INFO_DECLINE_CURVE_ATTRIBUTES.OIL_B_FACTOR.key
              ] as number
            ),
          [WELL_INFO_DECLINE_CURVE_ATTRIBUTES.OIL_START.label]:
            formatExportedVal(
              WELL_INFO_DECLINE_CURVE_ATTRIBUTES.OIL_START.key,
              item[WELL_INFO_DECLINE_CURVE_ATTRIBUTES.OIL_START.key]
            ),
          [WELL_INFO_DECLINE_CURVE_ATTRIBUTES.GAS_ALGORITHM.label]:
            formatExportedVal(
              WELL_INFO_DECLINE_CURVE_ATTRIBUTES.GAS_ALGORITHM.key,
              item[WELL_INFO_DECLINE_CURVE_ATTRIBUTES.GAS_ALGORITHM.key]
            ),
          [WELL_INFO_DECLINE_CURVE_ATTRIBUTES.GAS_INITIAL_RATE.label]:
            formatExportedRates(
              WELL_INFO_DECLINE_CURVE_ATTRIBUTES.GAS_INITIAL_RATE.key,
              item[
                WELL_INFO_DECLINE_CURVE_ATTRIBUTES.GAS_INITIAL_RATE.key
              ] as number
            ),
          [WELL_INFO_DECLINE_CURVE_ATTRIBUTES.GAS_DECLINE_RATE.label]:
            formatExportedRates(
              WELL_INFO_DECLINE_CURVE_ATTRIBUTES.GAS_DECLINE_RATE.key,
              item[
                WELL_INFO_DECLINE_CURVE_ATTRIBUTES.GAS_DECLINE_RATE.key
              ] as number
            ),
          [WELL_INFO_DECLINE_CURVE_ATTRIBUTES.GAS_B_FACTOR.label]:
            formateExportedBFactor(
              WELL_INFO_DECLINE_CURVE_ATTRIBUTES.GAS_B_FACTOR.key,
              item[
                WELL_INFO_DECLINE_CURVE_ATTRIBUTES.GAS_B_FACTOR.key
              ] as number
            ),
          [WELL_INFO_DECLINE_CURVE_ATTRIBUTES.GAS_START.label]:
            formatExportedVal(
              WELL_INFO_DECLINE_CURVE_ATTRIBUTES.GAS_START.key,
              item[WELL_INFO_DECLINE_CURVE_ATTRIBUTES.GAS_START.key]
            ),
          [WELL_INFO_DECLINE_CURVE_ATTRIBUTES.WATER_ALGORITHM.label]:
            formatExportedVal(
              WELL_INFO_DECLINE_CURVE_ATTRIBUTES.WATER_ALGORITHM.key,
              item[WELL_INFO_DECLINE_CURVE_ATTRIBUTES.WATER_ALGORITHM.key]
            ),
          [WELL_INFO_DECLINE_CURVE_ATTRIBUTES.WATER_INITIAL_RATE.label]:
            formatExportedRates(
              WELL_INFO_DECLINE_CURVE_ATTRIBUTES.WATER_INITIAL_RATE.key,
              item[
                WELL_INFO_DECLINE_CURVE_ATTRIBUTES.WATER_INITIAL_RATE.key
              ] as number
            ),
          [WELL_INFO_DECLINE_CURVE_ATTRIBUTES.WATER_DECLINE_RATE.label]:
            formatExportedRates(
              WELL_INFO_DECLINE_CURVE_ATTRIBUTES.WATER_DECLINE_RATE.key,
              item[
                WELL_INFO_DECLINE_CURVE_ATTRIBUTES.WATER_DECLINE_RATE.key
              ] as number
            ),
          [WELL_INFO_DECLINE_CURVE_ATTRIBUTES.WATER_B_FACTOR.label]:
            formateExportedBFactor(
              WELL_INFO_DECLINE_CURVE_ATTRIBUTES.WATER_B_FACTOR.key,
              item[
                WELL_INFO_DECLINE_CURVE_ATTRIBUTES.WATER_B_FACTOR.key
              ] as number
            ),
          [WELL_INFO_DECLINE_CURVE_ATTRIBUTES.WATER_START.label]:
            formatExportedVal(
              WELL_INFO_DECLINE_CURVE_ATTRIBUTES.WATER_START.key,
              item[WELL_INFO_DECLINE_CURVE_ATTRIBUTES.WATER_START.key]
            ),
        });
      }

      if (
        exportList.includes(WELL_PANEL_SECTION.COMPLETION_SECTION.key) &&
        item[WELL_PANEL_FLAGS.HAS_COMPLETION.key]
      ) {
        completionArray?.push({
          [UWI.label]: item.uwi,
          [WELL_INFO_COMPLETION_DATA_ATTRIBUTES.COMPLETION_DATE.label]:
            formatExportedVal(
              WELL_INFO_COMPLETION_DATA_ATTRIBUTES.COMPLETION_DATE.key,
              item.completionDate
            ),
          [WELL_INFO_COMPLETION_DATA_ATTRIBUTES.LATERAL_LENGTH.label]:
            formatExportedVal(
              WELL_INFO_COMPLETION_DATA_ATTRIBUTES.LATERAL_LENGTH.key,
              item.lateralLength
            ),
          [WELL_INFO_COMPLETION_DATA_ATTRIBUTES.DRILLING_DAYS.label]:
            formatExportedVal(
              WELL_INFO_COMPLETION_DATA_ATTRIBUTES.DRILLING_DAYS.key,
              item.drillingDays
            ),
          [WELL_INFO_COMPLETION_DATA_ATTRIBUTES.NUMBER_OF_STAGES.label]:
            formatExportedVal(
              WELL_INFO_COMPLETION_DATA_ATTRIBUTES.NUMBER_OF_STAGES.key,
              item.numberOfStages
            ),
          [WELL_INFO_COMPLETION_DATA_ATTRIBUTES.MAX_TREATMENT_PRESSURE.label]:
            formatExportedVal(
              WELL_INFO_COMPLETION_DATA_ATTRIBUTES.MAX_TREATMENT_PRESSURE.key,
              item.maxTreatmentRate
            ),
          [WELL_INFO_COMPLETION_DATA_ATTRIBUTES.MAX_TREATMENT_RATE.label]:
            formatExportedVal(
              WELL_INFO_COMPLETION_DATA_ATTRIBUTES.MAX_TREATMENT_RATE.key,
              item.maxTreatmentPressure
            ),
          [WELL_INFO_COMPLETION_DATA_ATTRIBUTES.PROPPANT_AMOUNT.label]:
            formatExportedVal(
              WELL_INFO_COMPLETION_DATA_ATTRIBUTES.PROPPANT_AMOUNT.key,
              item.proppantAmount
            ),
          [WELL_INFO_COMPLETION_DATA_ATTRIBUTES.FLUID_AMOUNT.label]:
            formatExportedVal(
              WELL_INFO_COMPLETION_DATA_ATTRIBUTES.FLUID_AMOUNT.key,
              item.fractureFluidAmount
            ),
          [WELL_INFO_COMPLETION_DATA_ATTRIBUTES.ACID_AMOUNT.label]:
            formatExportedVal(
              WELL_INFO_COMPLETION_DATA_ATTRIBUTES.ACID_AMOUNT.key,
              item.acidAmount
            ),
          [WELL_INFO_COMPLETION_DATA_ATTRIBUTES.TREATMENT_REMARKS.label]:
            formatExportedVal(
              WELL_INFO_COMPLETION_DATA_ATTRIBUTES.TREATMENT_REMARKS.key,
              item.treatmentRemarks
            ),
        });
      }
    });

    return {
      header: headerArray,
      statistics: statisticsArray,
      declineCurveParam: declineCurveParametersArray,
      completion: completionArray,
    };
  };

  const sortPerfData = (data: Perforation[]) => {
    if (!data.length) return [];
    const perfArray: any[] = [];
    const sortedData = data.flatMap((item) => item);

    sortedData.forEach((item) => {
      perfArray.push({
        [UWI.label]: formatExportedVal(UWI.key, item.uwi),
        [PERF_DATE.label]: formatExportedVal(PERF_DATE.key, item.perfDate),
        [PERF_TOP.label]: formatExportedVal(PERF_TOP.key, item.perfTop),
        [PERF_BOTTOM.label]: formatExportedVal(
          PERF_BOTTOM.key,
          item.perfBottom
        ),
      });
    });

    return perfArray;
  };

  const sortTestingData = (data: Test[]) => {
    if (!data.length) return [];
    const testArray: any[] = [];
    const sortedData = data.flatMap((item) => item);

    sortedData?.forEach((item: Test) => {
      testArray.push({
        [UWI.label]: formatExportedVal(UWI.key, item.uwi),
        [TEST_DATE.label]: formatExportedVal(TEST_DATE.key, item.testDate),
        [DOC_TYPE.label]: formatExportedVal(DOC_TYPE.key, item.testType),
        [OIL.label]: formatExportedVal(OIL.key, item.oil),
        [GAS.label]: formatExportedVal(GAS.key, item.gas),
        [WATER.label]: formatExportedVal(WATER.key, item.water),
        [TEST_DURATION.label]: formatExportedVal(
          TEST_DURATION.key,
          item.testDuration
        ),
      });
    });

    return testArray;
  };

  const sortDstPressureData = (data: DstPressureDataWellPanelExport[][]) => {
    if (!data.length) return [];
    const pressureDataArray: { [key: string]: string }[] = [];
    const sortedData = data.flatMap(
      (item: DstPressureDataWellPanelExport[]) => item
    );
    sortedData?.forEach((item: DstPressureDataWellPanelExport) => {
      pressureDataArray.push({
        [UWI.label]: formatExportedVal(UWI.key, item.uwi) as string,
        [DOC_TYPE.label]: item.TestType,
        [DST_NO.label]: item.DSTNo,
        [REPORTED_FORMATION.label]: item.Formation,
        [TGS_LANDING_ZONE.label]: item.TgsLandingZone,
        [DST_TEMP.label]: item.Temperature,
        ["Top  (of an interval)"]: item.Top,
        ["Bottom (of an interval)"]: item.Bottom,
        ["1st Flow"]: item.FirstFlow,
        ["1st Flow shut-in"]: item.FirstFlowShutIn,
        ["2nd Flow"]: item.SecondFlow,
        ["2nd Flow shut-in"]: item.SecondFlowShutIn,
        ["Recovery Remarks"]: item.RecoveryRemarks,
      });
    });

    return pressureDataArray;
  };

  const sortWftPressureData = (data: WftPressureDataWellPanelExport[][]) => {
    if (!data.length) return [];
    const pressureDataArray: { [key: string]: string }[] = [];
    const sortedData = data.flatMap(
      (item: WftPressureDataWellPanelExport[]) => item
    );
    sortedData?.forEach((item: WftPressureDataWellPanelExport) => {
      pressureDataArray.push({
        [UWI.label]: formatExportedVal(UWI.key, item.uwi) as string,
        [DOC_TYPE.label]: item.DocType,
        [TEST_NUMBER.label]: item.TestNumber,
        [FORMATION.label]: item.Formation,
        [TGS_LANDING_ZONE.label]: item.TgsLandingZone,
        [FORMATION_PRESSURE.label]: item.FormationPressure,
        [TEST_DEPTH.label]: item.TestDepth,
        [TEST_TYPE.label]: item.TestType,
        [INITIAL_SHUT_IN_END_TGS.label]: item.InitialShutInEndTgs,
        [FINAL_SHUT_IN_END_TGS.label]: item.FinalShutInEndTgs,
        [FORMATION_WATER_RESISTIVITY.label]: item.FormationWaterResistivity,
        [WATER_SATURATION.label]: item.WaterSaturation,
        [POROSITY.label]: item.Porosity,
        [PERMEABILITY.label]: item.Permeability,
        [OIL_AMT.label]: item.OilAmount,
        [TOTAL_GAS_AMT.label]: item.TotalGasAmount,
        [CONDENSATE_AMT.label]: item.CondensateAmount,
        [WATER_AMT.label]: item.WaterAmount,
        [SUMMARY_TEST_REMARKS.label]: item.SummaryTestRemarks,
        [SAMPLE_REMARKS.label]: item.SampleRemarks,
      });
    });

    return pressureDataArray;
  };

  const sortCasingData = (data: Casings[]) => {
    if (!data.length) return [];
    const casingArray: any[] = [];
    const sortedData = data.flatMap((item: any) => item);
    sortedData?.forEach((item: Casings) => {
      casingArray.push({
        [UWI.label]: formatExportedVal(UWI.key, item.uwi) as string,
        [CASING_TYPE.label]: formatExportedVal(
          CASING_TYPE.key,
          item.casingType
        ),
        [CASING_PURPOSE.label]: formatExportedVal(
          CASING_PURPOSE.key,
          item.casingPurpose
        ),
        [HOLE_SIZE.label]: formatExportedVal(HOLE_SIZE.key, item.holeSize),
        [CASING_SIZE.label]: formatExportedVal(
          CASING_SIZE.key,
          item.casingSize
        ),
        [CASING_WEIGHT.label]: formatExportedVal(
          CASING_WEIGHT.key,
          item.casingWeight
        ),
        [TOP_DEPTH.label]: formatExportedVal(TOP_DEPTH.key, item.topDepth),
        [BOTTOM_DEPTH.label]: formatExportedVal(
          BOTTOM_DEPTH.key,
          item.bottomDepth
        ),
        [CASING_GRADE.label]: formatExportedVal(
          CASING_GRADE.key,
          item.casingGrade
        ),
      });
    });

    return casingArray;
  };

  const sortPermitsData = (data: Permits[]) => {
    if (!data.length) return [];
    const permitsArray: any[] = [];
    const sortedData = data.flatMap((item: any) => item);

    sortedData.forEach((item: Permits) => {
      permitsArray.push({
        [UWI.label]: formatExportedVal(UWI.key, item.uwi),
        [PERMIT_NUMBER.label]: formatExportedVal(
          PERMIT_NUMBER.key,
          item.permitNumber
        ),
        [PERMIT_APPROVAL_DATE.label]: formatExportedVal(
          PERMIT_APPROVAL_DATE.key,
          item.permitApprovalDate
        ),
        [PERMIT_POSTED_DATE.label]: formatExportedVal(
          PERMIT_POSTED_DATE.key,
          item.permitPostedDate
        ),
        "Permit Proposed Depth": formatExportedVal(
          PERMIT_TOTAL_DEPTH.key,
          item.proposedTotalDepth
        ),
        [PERMIT_FORMATION.label]: formatExportedVal(
          PERMIT_FORMATION.key,
          item.formation
        ),
        [PERMIT_PURPOSE.label]: formatExportedVal(
          PERMIT_PURPOSE.key,
          item.wellPurpose
        ),
        [PERMIT_TYPE_CODE.label]: formatExportedVal(
          PERMIT_TYPE_CODE.key,
          item.permitTypeCode
        ),
        [WELL_ORIENTATION.label]: formatExportedVal(
          WELL_ORIENTATION.key,
          item.wellOrientation
        ),
        [WELL_NAME.label]: formatExportedVal(WELL_NAME.key, item.wellName),
        [WELL_NUMBER.label]: formatExportedVal(
          WELL_NUMBER.key,
          item.wellNumber
        ),
        [PERMIT_SURFACE_LATITUDE.label]: formatLatLongAttributes(
          item.surfaceLat
        ),
        [PERMIT_SURFACE_LONGITUDE.label]: formatLatLongAttributes(
          item.surfaceLong
        ),
        [PERMIT_BOTTOM_HOLE_LAT.label]: formatLatLongAttributes(
          item.bottomHoleLat
        ),
        [PERMIT_BOTTOM_HOLE_LONGITUDE.label]: formatLatLongAttributes(
          item.bottomHoleLong
        ),
      });
    });

    return permitsArray;
  };

  const sortFormationsData = (data: Formations[]) => {
    if (!data.length) return [];
    const formationsArray: any[] = [];
    const sortedData = data.flatMap((item: any) => item);

    sortedData.forEach((item: Formations) => {
      formationsArray.push({
        [UWI.label]: formatExportedVal(UWI.key, item.uwi),
        [FORMATION_NAME.label]: formatExportedVal(
          FORMATION_NAME.key,
          item.formationName
        ),
        [TOP_DEPTH.label]: formatExportedVal(TOP_DEPTH.key, item.topDepth),
      });
    });
    return formationsArray;
  };

  return {
    sortWellPanelContent,
    sortPerfData,
    sortCasingData,
    sortTestingData,
    sortDstPressureData,
    sortWftPressureData,
    sortPermitsData,
    sortFormationsData,
  };
};

export default useSortWellPanelData;
