import { useState } from "react";

import { ThemeProvider } from "@mui/material/styles";

import { IChartTypeInfo } from "../../../../types/charts/chartType/chartType";
import { ChartTypeHeaderControlsProps } from "../../../../types/charts/chartType/chartTypeHeader";
import { DefaultLayout } from "../../../../types/modularity/layout";
import { Modules } from "../../../../types/modularity/modules";

import { CHART_MODE_VIEW } from "../../../../constants/charts/chartModeView";
import { CHART_STATUS, CHART_TYPES } from "../../../../constants/charts/charts";

import useChartStore from "../../../../store/chart/chartStore";
import useModularityStore from "../../../../store/modularity/modularityStore";
import usePanelsStore from "../../../../store/panels/panelsStore";
import useStore from "../../../../store/useStore";

import { darkTheme } from "../../../../themes";
import { clone } from "../../../../utils";
import DefaultViewChartControls from "./chartTypeHeaderControls/DefaultViewChartControls";
import FullScreenViewChartControls from "./chartTypeHeaderControls/FullScreenViewChartControls";
import MenuControls from "./chartTypeHeaderControls/MenuControls";

const ChartTypeHeaderControls = ({
  chartId,
  chartType,
  chartTitle,
  onChartFullScreenClose,
  chartRendered,
  chartRef,
  chartMode,
  onChartExportData,
  disableExport,
}: ChartTypeHeaderControlsProps) => {
  const [anchorEl, setAnchorEl] = useState<
    (EventTarget & HTMLButtonElement) | null
  >(null);
  const [popoverAnchorEl, setPopoverAnchorEl] = useState<
    (EventTarget & HTMLButtonElement) | null
  >(null);
  const chartFullScreenLayout = useModularityStore(
    (state) => state.chartFullScreenLayout
  );
  const updateChartFullScreenLayout = useModularityStore(
    (state) => state.updateChartFullScreenLayout
  );
  const chartDefaultLayout = useModularityStore(
    (state) => state.chartDefaultLayout
  );
  const updateChartDefaultLayout = useModularityStore(
    (state) => state.updateChartDefaultLayout
  );
  const modules = useModularityStore((state) => state.modules);
  const updateModuleDataByKey = useModularityStore(
    (state) => state.updateModuleDataByKey
  );
  const updateModules = useModularityStore((state) => state.updateModules);
  const removeApiChartDataExcelByID = useStore(
    (state) => state.removeApiChartDataExcelByID
  );
  const removeApiChartDataForecastExcelByID = useStore(
    (state) => state.removeApiChartDataForecastExcelByID
  );
  const chartData = useChartStore((state) => state.chartData);
  const updateChartDataByKey = useChartStore(
    (state) => state.updateChartDataByKey
  );
  const removeChartDataByKey = useChartStore(
    (state) => state.removeChartDataByKey
  );

  const toggleFullScreenIndividualChart = useModularityStore(
    (state) => state.toggleFullScreenIndividualChart
  );
  const updateFullScreenChartId = useModularityStore(
    (state) => state.updateFullScreenChartId
  );

  const isFullScreenIndividualChart = useModularityStore(
    (state) => state.isFullScreenIndividualChart
  );
  const fullScreenChartId = useModularityStore(
    (state) => state.fullScreenChartId
  );

  const toggleAnalysisPanel = usePanelsStore(
    (state) => state.toggleAnalysisPanel
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleResetChart = () => {
    const copiedChartData: IChartTypeInfo[] = clone(chartData);
    const modifiedChartData: IChartTypeInfo[] = copiedChartData.map(
      (chart: IChartTypeInfo) => {
        if (
          chart.chartId === chartId &&
          chart.objectType !== CHART_TYPES.ASSET_ECONOMICS
        ) {
          chart.chartRange = undefined;
          chart.chartData.map((data) => {
            //sets visible parameter to true which is used by the legend
            data.visible = true;
          });
        }
        return chart;
      }
    );

    const updatedChartData: IChartTypeInfo | undefined = modifiedChartData.find(
      (data) => data.chartId === chartId
    );

    if (updatedChartData) {
      updateChartDataByKey(chartId, updatedChartData);
    }
  };

  const updateChartLayout = () => {
    const copiedChartFullScreenLayout: DefaultLayout[] = clone(
      chartFullScreenLayout
    );
    const filteredChartFullScreenLayout = copiedChartFullScreenLayout.filter(
      (chart) => {
        return chart.i !== chartId;
      }
    );
    updateChartFullScreenLayout(filteredChartFullScreenLayout);

    const copiedChartDefaultLayout: DefaultLayout[] = clone(chartDefaultLayout);
    const filteredChartDefaultLayout = copiedChartDefaultLayout.filter(
      (chart) => {
        return chart.i !== chartId;
      }
    );

    updateChartDefaultLayout(filteredChartDefaultLayout);
  };

  const minimizeChart = () => {
    const copiedChartdata: IChartTypeInfo[] = clone(chartData);
    const modifiedChartData = copiedChartdata.map((chart) => {
      if (chart.chartId === chartId) {
        chart.status = CHART_STATUS.COLLAPSED;
      }
      return chart;
    });
    const updatedChartData = modifiedChartData.find(
      (data) => data.chartId === chartId
    );

    if (updatedChartData) {
      updateChartDataByKey(chartId, updatedChartData);
    }
    updateChartLayout();

    const chartModule = (clone(modules) as Modules[]).find(
      (module) => module.module === chartId
    );

    if (chartModule) {
      chartModule.isExpanded = false;

      updateModuleDataByKey(chartId, chartModule);
    }
  };

  const fullScreenChart = () => {
    toggleFullScreenIndividualChart(true);
    updateFullScreenChartId(chartId);
  };

  const deleteChart = () => {
    updateChartLayout();

    const modifiedModule = (clone(modules) as Modules[]).filter((module) => {
      return module.module !== chartId;
    });

    updateModules(modifiedModule);

    if (!modifiedModule.length) toggleAnalysisPanel(false);
    removeChartDataByKey(chartId);
  };

  const handleDeleteChart = () => {
    removeApiChartDataExcelByID(chartId);
    removeApiChartDataForecastExcelByID(chartId);
    deleteChart();
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setPopoverAnchorEl(null);
  };

  const handleExportClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setPopoverAnchorEl(e.currentTarget);
  };

  const handlePopoverClose = () => {
    setPopoverAnchorEl(null);
  };

  return (
    <div className="plot-chart-toolbar">
      <ThemeProvider theme={darkTheme}>
        {fullScreenChartId === chartId &&
        isFullScreenIndividualChart &&
        chartMode === CHART_MODE_VIEW.FULLSCREEN_INDIVIDUAL_CHART ? (
          <FullScreenViewChartControls
            chartType={chartType}
            onClick={handleClick}
            onReset={handleResetChart}
            onClose={onChartFullScreenClose}
          />
        ) : (
          <DefaultViewChartControls
            chartType={chartType}
            onMinimize={minimizeChart}
            onReset={handleResetChart}
            onClick={handleClick}
            onFullScreen={fullScreenChart}
            onDelete={handleDeleteChart}
          />
        )}

        <MenuControls
          anchorEl={anchorEl}
          onClose={handleCloseMenu}
          popoverAnchorEl={popoverAnchorEl}
          onExportClick={handleExportClick}
          onPopoverClose={handlePopoverClose}
          chartId={chartId}
          chartType={chartType}
          chartRendered={chartRendered}
          chartRef={chartRef}
          chartTitle={chartTitle}
          chartMode={chartMode}
          onChartExportData={onChartExportData}
          disableExport={disableExport}
        />
      </ThemeProvider>
    </div>
  );
};

export default ChartTypeHeaderControls;
