import { useMemo } from "react";

import { DstPressureDataWellPanel } from "../../../../types/panels/wellPanel/pressureDataType";

import { DST_PRESSURE_DATA_TEST_TYPES } from "../../../../constants/panels/wellPanel/pressureData";

import TooltipInfoIcon from "../../../common/icons/TooltipInfoIcon";
import NoDataSection from "../../wellPanel/sections/NoDataSection";

interface InfoBannerSectionProps {
  panelContent?: DstPressureDataWellPanel;
}
const InfoBannerSection = ({ panelContent }: InfoBannerSectionProps) => {
  const hasNoChartData = useMemo(
    () =>
      panelContent?.DocType === DST_PRESSURE_DATA_TEST_TYPES.DST_PLUS &&
      !panelContent.IncrementalChartTrace &&
      !panelContent.HornerPlotChartTrace,
    [panelContent]
  );

  const hasNoDstPlusEntitlement = useMemo(
    () => panelContent?.DocType === DST_PRESSURE_DATA_TEST_TYPES.DST,
    [panelContent]
  );
  const infoMessage = useMemo(() => {
    if (hasNoChartData) {
      return (
        <p data-testid="dst-panel-info-section-no-charts">
          No Incremental Shut-In or Horner Plots <br /> available for this test.
          Export from ‘Export <br /> Manager’ for additional DST data.
        </p>
      );
    }

    if (hasNoDstPlusEntitlement) {
      return (
        <p data-testid="dst-panel-info-section-no-entitlement">
          Subscribe to DST+ to view <br /> incremental shut-in chart <br /> and
          Horner plot
        </p>
      );
    }
  }, [hasNoChartData, hasNoDstPlusEntitlement]);

  return (
    <>
      <NoDataSection
        message={
          <>
            <span>
              <TooltipInfoIcon />
            </span>
            {infoMessage}
          </>
        }
      />
    </>
  );
};

export default InfoBannerSection;
