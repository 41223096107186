/**
 * This helper util will be the ts version of the helper.js
 * Move the utility used in helper.js to this helper2.ts
 */

export const containsAtLeastOne = (array: any[]) => {
  return array && array.length > 0;
};

export const getTruncatedWellStatus = (wellStatus: string) => {
  const acronymIndex = wellStatus.indexOf("(");
  let acronym = "";
  let truncWellStatus = wellStatus;
  if (acronymIndex > -1) {
    truncWellStatus = wellStatus.slice(0, acronymIndex - 1);
    acronym = wellStatus.slice(acronymIndex - 1, wellStatus.length);
  }

  if (truncWellStatus.length > 12) {
    truncWellStatus = truncWellStatus.slice(0, 12) + "..." + acronym;
  } else {
    truncWellStatus = wellStatus;
  }
  return truncWellStatus;
};

export const capitalizeFirstLetterString = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

// might have issues when string contains the characters being replaced
export const convertJSONToStringWithSpaces = (value: any) => {
  // first replaceAll is for null values
  return JSON.stringify(value).replaceAll(`":`, `": `).replaceAll(',"', ', "');
};

export const getPositiveModulo = (dividend: number, divisor: number) => {
  return ((dividend % divisor) + divisor) % divisor;
};
