import {
  ChartDataFields,
  ChartType,
  IChartData,
  ITraceData,
} from "../../types/charts/chartType/chartType";
import { GroupedProductionResponseData } from "../../types/charts/chartType/productionChartData";

import {
  AGGREGATION_TYPE,
  CHART_ALL_OTHERS_KEY,
  CHART_GROUP_BY_TYPE,
  CHART_TYPES,
} from "../../constants/charts/charts";
import "../../constants/map/mapSettings";

import useChartStore from "../../store/chart/chartStore";

import { aggregateValues } from "../../utils/charts/aggregator";
import { differenceInMonths } from "../../utils/charts/dateHelper";
import {
  formatDefaultColorToRGB,
  formatDefaultTopColorsToRGB,
  formatHoverValue,
  formatTraceName,
  formattedTraceText,
} from "../../utils/charts/formatter";

import {
  getFormattedWellNameAndNumber,
  getLargestSignificantNumber,
} from "../../utils";
import useAttributeUnit from "../common/useAttributeUnit";
import useChartFormatter from "./useChartFormatter";

const useProductionDataChartTransformation = () => {
  const chartData = useChartStore((state) => state.chartData);
  const { getAttributeUnit } = useAttributeUnit();
  const { formatUnit } = useChartFormatter();

  const parseObj = (obj: GroupedProductionResponseData) => {
    return Object.keys(obj).reduce(function (
      result: GroupedProductionResponseData,
      key: string
    ) {
      result[key] = obj[key];
      return result;
    },
    {});
  };

  const buildTrace = (
    formattedTraceName: string,
    formattedUnit: string,
    isForForecastData: boolean,
    formattedText: string,
    markerColor: string,
    chartType: ChartType
  ) => {
    const trace: ITraceData = {
      id: formattedTraceName,
      unit: formattedUnit,
      type: "scatter",
      mode: "lines",
      ...(isForForecastData && {
        line: {
          dash: "dash",
          width: 2,
        },
      }),
      hoverlabel: { namelength: -1 },
      hoverinfo: "x+y+text",
      hovertemplate: "(%{x}, %{customdata:,}) <br>%{meta[0]}<extra></extra>",
      ...(chartType === CHART_TYPES.PRODUCTION_PLOT && {
        xhoverformat: "%b %Y",
      }),
      text: formattedText,
      meta: [formattedText],
      name: formattedTraceName,
      marker: { color: markerColor },
      visible: true,
      x: [],
      y: [],
      customdata: [],
    };
    return trace;
  };

  const getDefaultFormattedTrace = (
    data: GroupedProductionResponseData,
    currentChartData: IChartData,
    dataFieldsToDisplayList: ChartDataFields[],
    isForForecastData: boolean
  ) => {
    const chartTrace: ITraceData[] = [];
    const parseObjData = parseObj(data);
    const sortedObjData = Object.entries(parseObjData).sort((a, b) =>
      a[0].localeCompare(b[0], undefined, {
        numeric: true,
        sensitivity: "base",
      })
    );
    dataFieldsToDisplayList.forEach((dataFieldSelected: ChartDataFields) => {
      const formattedTraceName = formatTraceName(
        dataFieldSelected.hasDaily,
        currentChartData.chartShowDailyValues,
        dataFieldSelected.dailyDisplayName,
        dataFieldSelected.displayName,
        isForForecastData
      );

      const dataFieldUnit = getAttributeUnit(dataFieldSelected.name);
      const formattedUnit = formatUnit(
        dataFieldSelected.hasDaily,
        currentChartData.chartShowDailyValues,
        dataFieldSelected.hasLateralLength,
        currentChartData.chartShowByNormalizedLateralLength,
        dataFieldUnit
      );

      const formattedText = formattedTraceText(
        formattedTraceName,
        formattedUnit,
        Boolean(dataFieldUnit)
      );

      const dataInfo = buildTrace(
        formattedTraceName,
        formattedUnit,
        isForForecastData,
        formattedText,
        dataFieldSelected.color,
        currentChartData.chartType
      );

      sortedObjData.forEach(([key, value]) => {
        const fieldValue = value
          .filter((val: any) => {
            if (
              val[dataFieldSelected.responseData.origin]?.[
                dataFieldSelected.responseData.name
              ] !== undefined
            ) {
              return val;
            }
          })
          .map((returnedVal: any) =>
            returnedVal[dataFieldSelected.responseData.origin]?.[
              dataFieldSelected.responseData.name
            ]
              ? parseFloat(
                  returnedVal[dataFieldSelected.responseData.origin]?.[
                    dataFieldSelected.responseData.name
                  ]
                )
              : 0
          );

        if (fieldValue.length > 0) {
          //NOTE: Identified that parsing srting date to data caused performance degradation
          //As a fix, passing the string formatted date into plotly
          // dataInfo["x"].push(getFormattedXByChart(chartType, key));
          dataInfo["x"].push(key);
          const aggregatedFieldValues = aggregateValues(
            fieldValue,
            AGGREGATION_TYPE[currentChartData.chartType]
          );
          dataInfo["y"].push(aggregatedFieldValues);
          (dataInfo.customdata as string[]).push(
            formatHoverValue(aggregatedFieldValues)
          );
        }
      });

      chartTrace.push(dataInfo);
    });

    return chartTrace;
  };

  const getColor = (
    chartColorPallete: string[],
    defaultColor: string,
    filterAvailableColors: string[],
    productionDataTrace: ITraceData[] | undefined,
    isForForecastData: boolean,
    key: string,
    groupBy: string
  ) => {
    let dataColor = "";
    if (isForForecastData && productionDataTrace?.length) {
      let matchedProdData: ITraceData | undefined;

      switch (groupBy) {
        case CHART_GROUP_BY_TYPE.INDIVIDUAL_WELLS:
          matchedProdData = productionDataTrace.find(
            (prodTrace) => prodTrace.wellId === key
          );
          break;
        default:
          matchedProdData = productionDataTrace.find(
            (prodTrace) => prodTrace.attributeId === key
          );
          break;
      }

      if (matchedProdData) {
        dataColor = matchedProdData.marker.color as string;
      } else {
        // if there are no production data associated to the forecast, assign colors from filterAvailableColors pallete
        // filterAvailableColors is derived from filtering the color pallete with what is not used on the production data traces
        if (key.toLowerCase() === CHART_ALL_OTHERS_KEY) {
          dataColor = defaultColor;
        } else {
          dataColor = filterAvailableColors[0];
          filterAvailableColors.splice(0, 1);
        }
      }
    } else {
      // if data is for production directly assign colors from the chart color pallete
      // if there are no production data and there are just forecasts, directly assign colors from chart color pallete
      if (key.toLowerCase() === CHART_ALL_OTHERS_KEY) {
        dataColor = defaultColor;
      } else {
        dataColor = chartColorPallete[0];
        chartColorPallete.splice(0, 1);
      }
    }

    return dataColor;
  };

  const getIndividualWellsFormattedTrace = (
    data: GroupedProductionResponseData,
    currentChartData: IChartData,
    dataFieldsToDisplayList: ChartDataFields[],
    isForForecastData: boolean,
    groupBy: string,
    productionDataTrace?: ITraceData[]
  ) => {
    const chartTrace: ITraceData[] = [];
    const chartColorPallete = formatDefaultTopColorsToRGB();
    const defaultColor = formatDefaultColorToRGB();

    //For forecast data
    const prodDataColors = productionDataTrace?.map(
      (prodData) => prodData.marker.color
    );
    const filterAvailableColors = chartColorPallete.filter((x: string) => {
      return prodDataColors?.find((y) => x === y) === undefined;
    });

    Object.entries(data).forEach(([key, value]) => {
      const dataColor = getColor(
        chartColorPallete,
        defaultColor,
        filterAvailableColors,
        productionDataTrace,
        isForForecastData,
        key,
        groupBy
      );

      dataFieldsToDisplayList.forEach((dataFieldSelected: ChartDataFields) => {
        let wellInfo = "";
        if (value.length > 0) {
          // get WellName and WellNumber
          const wellName: string[] = Array.from(
            new Set(value.map((val: any) => val.wellName))
          );
          const wellNumber: string[] = Array.from(
            new Set(value.map((val: any) => val.wellNumber))
          );

          wellInfo = getFormattedWellNameAndNumber(wellName[0], wellNumber[0]);
        }
        const formattedTraceName = `${wellInfo}, ${formatTraceName(
          dataFieldSelected.hasDaily,
          currentChartData.chartShowDailyValues,
          dataFieldSelected.dailyDisplayName,
          dataFieldSelected.displayName,
          isForForecastData
        )}`;

        const dataFieldUnit = getAttributeUnit(dataFieldSelected.name);

        const formattedUnit = formatUnit(
          dataFieldSelected.hasDaily,
          currentChartData?.chartShowDailyValues ?? false,
          dataFieldSelected.hasLateralLength,
          currentChartData?.chartShowByNormalizedLateralLength ?? false,
          dataFieldUnit
        );

        const formattedText = formattedTraceText(
          formattedTraceName,
          formattedUnit,
          Boolean(dataFieldUnit)
        );

        const dataInfo = buildTrace(
          formattedTraceName,
          formattedUnit,
          isForForecastData,
          formattedText,
          dataColor,
          currentChartData.chartType
        );

        dataInfo.wellId = key; //An additional unique id for individual wells

        value.forEach((productionData: any) => {
          if (
            productionData[dataFieldSelected.responseData.origin]?.[
              dataFieldSelected.responseData.name
            ] !== undefined
          ) {
            //NOTE: Identified that parsing srting date to data caused performance degradation
            //As a fix, passing the string formatted date into plotly
            // dataInfo["x"].push(
            //   getFormattedXByChart(chartType, val.production)
            // );
            dataInfo["x"].push(productionData.production);
            const fieldValue = productionData[
              dataFieldSelected.responseData.origin
            ]?.[dataFieldSelected.responseData.name]
              ? parseFloat(
                  productionData[dataFieldSelected.responseData.origin][
                    dataFieldSelected.responseData.name
                  ]
                )
              : 0;
            dataInfo["y"].push(fieldValue);
            (dataInfo.customdata as string[]).push(
              formatHoverValue(fieldValue)
            );
          }
        });

        chartTrace.push(dataInfo);
      });
    });

    return chartTrace;
  };

  const getAttributeFormattedTrace = (
    data: GroupedProductionResponseData,
    currentChartData: IChartData,
    dataFieldsToDisplayList: ChartDataFields[],
    isForForecastData: boolean,
    groupBy: string | undefined,
    productionDataTrace?: ITraceData[]
  ) => {
    const chartTrace: ITraceData[] = [];
    const chartColorPallete = formatDefaultTopColorsToRGB();
    const defaultColor = formatDefaultColorToRGB();

    //For forecast data
    const prodDataColors = productionDataTrace?.map(
      (prodData) => prodData.marker.color
    );
    const filterAvailableColors = chartColorPallete.filter((x: string) => {
      return prodDataColors?.find((y) => x === y) === undefined;
    });

    Object.entries(data).forEach(([key, value]) => {
      const dataColor = getColor(
        chartColorPallete,
        defaultColor,
        filterAvailableColors,
        productionDataTrace,
        isForForecastData,
        key,
        groupBy ?? ""
      );

      dataFieldsToDisplayList.forEach((dataFieldSelected: ChartDataFields) => {
        const formattedTraceName = `${key}, ${formatTraceName(
          dataFieldSelected.hasDaily,
          currentChartData.chartShowDailyValues,
          dataFieldSelected.dailyDisplayName,
          dataFieldSelected.displayName,
          isForForecastData
        )}`;

        const dataFieldUnit = getAttributeUnit(dataFieldSelected.name);

        const formattedUnit = formatUnit(
          dataFieldSelected.hasDaily,
          currentChartData?.chartShowDailyValues ?? false,
          dataFieldSelected.hasLateralLength,
          currentChartData?.chartShowByNormalizedLateralLength ?? false,
          dataFieldUnit
        );

        const formattedText = formattedTraceText(
          formattedTraceName,
          formattedUnit,
          Boolean(dataFieldUnit)
        );

        const dataInfo = buildTrace(
          formattedTraceName,
          formattedUnit,
          isForForecastData,
          formattedText,
          dataColor,
          currentChartData.chartType
        );
        dataInfo.attributeId = key;

        value.forEach((productionData: any) => {
          if (
            productionData[dataFieldSelected.responseData.origin]?.[
              dataFieldSelected.responseData.name
            ] !== undefined
          ) {
            //NOTE: Identified that parsing srting date to data caused performance degradation
            //As a fix, passing the string formatted date into plotly
            // dataInfo["x"].push(
            //   getFormattedXByChart(chartType, val.production)
            // );
            dataInfo["x"].push(productionData.production);
            const fieldValue = productionData[
              dataFieldSelected.responseData.origin
            ]?.[dataFieldSelected.responseData.name]
              ? parseFloat(
                  productionData[dataFieldSelected.responseData.origin][
                    dataFieldSelected.responseData.name
                  ]
                )
              : 0;
            dataInfo["y"].push(fieldValue);
            (dataInfo.customdata as string[]).push(
              formatHoverValue(fieldValue)
            );
          }
        });

        chartTrace.push(dataInfo);
      });
    });

    return chartTrace;
  };

  const formatChartTrace = (
    id: string,
    data: GroupedProductionResponseData,
    groupBy: string | undefined,
    isForForecastData: boolean,
    productionDataTrace: ITraceData[]
  ) => {
    let chartInfo: ITraceData[] = [];
    const currentChartData = chartData.find((data) => data.chartId === id) as
      | IChartData
      | undefined;

    const dataFieldsToDisplayList: ChartDataFields[] | undefined =
      isForForecastData
        ? currentChartData?.chartDisplayedDataFields.filter(
            (dataField: ChartDataFields) => dataField.hasForecast
          )
        : currentChartData?.chartDisplayedDataFields;

    if (!currentChartData || !dataFieldsToDisplayList || !data) {
      return chartInfo;
    }

    if (groupBy === CHART_GROUP_BY_TYPE.DEFAULT) {
      chartInfo = getDefaultFormattedTrace(
        data,
        currentChartData,
        dataFieldsToDisplayList,
        isForForecastData
      );
    } else if (groupBy === CHART_GROUP_BY_TYPE.INDIVIDUAL_WELLS) {
      chartInfo = getIndividualWellsFormattedTrace(
        data,
        currentChartData,
        dataFieldsToDisplayList,
        isForForecastData,
        groupBy,
        productionDataTrace
      );
    } else {
      chartInfo = getAttributeFormattedTrace(
        data,
        currentChartData,
        dataFieldsToDisplayList,
        isForForecastData,
        groupBy,
        productionDataTrace
      );
    }

    return chartInfo;
  };

  const formatChartCustomTicks = (chartData: ITraceData[]) => {
    try {
      const maxValues: { [key: string]: number } = {};
      chartData.forEach((data) => {
        if (data.wellId) {
          //for grouped by individual wells
          maxValues[data.id + "-" + data.wellId] = Math.max(
            ...Object.values({ ...data.y })
          );
        } else {
          maxValues[data.id] = Math.max(...Object.values({ ...data.y }));
        }
      });

      let largestNumber: number = Math.round(
        Math.max(...Object.values(maxValues))
      );

      if (largestNumber === 0) {
        largestNumber = Math.max(...Object.values(maxValues));
      }

      const largestSignificantNumber =
        getLargestSignificantNumber(largestNumber);

      const newYData: number[] = [];
      const tickDivider = 5;

      let initialTickVal = 0;
      let currentTickVal = 0;
      if (largestSignificantNumber < 5) {
        initialTickVal = Number(
          (largestSignificantNumber / tickDivider).toFixed(2)
        );
        currentTickVal = initialTickVal;

        for (let i = 0; i < 7; i++) {
          newYData.push(currentTickVal);
          currentTickVal = Number((initialTickVal + currentTickVal).toFixed(2));
        }
      } else {
        initialTickVal = Math.round(largestSignificantNumber / tickDivider);
        currentTickVal = initialTickVal;

        for (let i = 0; i < 7; i++) {
          newYData.push(currentTickVal);
          currentTickVal += initialTickVal;
        }
      }

      return newYData;
    } catch (error) {
      console.debug(error);
      return [-Infinity, -Infinity, -Infinity];
    }
  };

  const formatXTicks = (chartData: ITraceData[]) => {
    try {
      const minmaxValue: { [key: string]: number } = {};
      chartData.forEach((data) => {
        if (data.wellId) {
          //for grouped by individual wells
          minmaxValue[data.id + "-" + data.wellId] = differenceInMonths(
            new Date(data.x[0]),
            new Date(data.x[data.x.length - 1])
          );
        } else {
          minmaxValue[data.id] = differenceInMonths(
            new Date(data.x[0]),
            new Date(data.x[data.x.length - 1])
          );
        }
      });
      return Object.values(minmaxValue).filter((val) => val > 5).length > 0;
    } catch (error) {
      console.debug(error);
      return false;
    }
  };

  return {
    formatChartTrace,
    formatChartCustomTicks,
    formatXTicks,
  };
};

export default useProductionDataChartTransformation;
