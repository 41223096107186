import { FC, useEffect, useMemo } from "react";

import { WellPanelSectionsProps } from "../../../../types/panels/wellPanel/common";
import {
  DstPressureDataResponseBody,
  WftPressureDataResponseBody,
} from "../../../../types/panels/wellPanel/dataLakeType";

import { SORT_ORDER } from "../../../../constants/dataLake";
import { dataLakeSize } from "../../../../constants/panels/wellPanel/logs";
import {
  DST_PRESSURE_DATA_FIELDS,
  DST_PRESSURE_DATA_SEARCHTYPES,
  PRESSURE_DATA_SORT_FIELDS,
  WFT_PRESSURE_DATA_FIELDS,
  WFT_PRESSURE_DATA_SEARCHTYPES,
} from "../../../../constants/panels/wellPanel/pressureData";
import {
  WELL_PANEL_FLAGS,
  WELL_PANEL_SECTION,
} from "../../../../constants/panels/wellPanel/wellInfo";

import usePanelsStore from "../../../../store/panels/panelsStore";

import useDatalake from "../../../../customHooks/panels/wellPanel/useDatalake";
import useDstPressureData from "../../../../customHooks/panels/wellPanel/useDstPressureData";
import useWftPressureData from "../../../../customHooks/panels/wellPanel/useWftPressureData";

import { convertUWI } from "../../../../utils/common/wells";

import TooltipInfoIcon from "../../../common/icons/TooltipInfoIcon";
import WellPanelAccordion from "../common/WellPanelAccordion";
import PressureDataSection from "../common/sectionAccordion/PressureDataSection";
import NoDataSection from "./NoDataSection";

const PressureData: FC<WellPanelSectionsProps> = ({
  wellData,
  activePage,
  groupedWellID,
  layer,
  isExpanded,
  onChange,
}) => {
  const updateWellCardDataByTypeAndKey = usePanelsStore(
    (state) => state.updateWellCardDataByTypeAndKey
  );
  const updateIsDstPressureDataLoading = usePanelsStore(
    (state) => state.updateIsDstPressureDataLoading
  );

  const updateIsWftPressureDataLoading = usePanelsStore(
    (state) => state.updateIsWftPressureDataLoading
  );

  const {
    dataLakeResponse: dstDataResponse,
    loadingRequest: dstLoadingRequest,
    successRequest: dstSuccessRequest,
    getDataLakeData: getDstDataLakeData,
  } = useDatalake();

  const {
    dataLakeResponse: wftDataResponse,
    loadingRequest: wftLoadingRequest,
    successRequest: wftSuccessRequest,
    getDataLakeData: getWftDataLakeData,
  } = useDatalake();

  const { buildDstPressureData } = useDstPressureData();
  const { buildWftPressureData } = useWftPressureData();

  useEffect(() => {
    if (dstSuccessRequest && dstDataResponse) {
      const mappedData = buildDstPressureData(
        dstDataResponse as DstPressureDataResponseBody[]
      );

      updateWellCardDataByTypeAndKey(
        { type: "dstPressuredata", dstPressuredata: mappedData },
        wellData.wellId,
        layer,
        groupedWellID
      );
      updateIsDstPressureDataLoading(false);
    }
  }, [dstDataResponse, dstSuccessRequest]);

  useEffect(() => {
    if (wftSuccessRequest && wftDataResponse) {
      const mappedData = buildWftPressureData(
        wftDataResponse as WftPressureDataResponseBody[]
      );

      updateWellCardDataByTypeAndKey(
        { type: "wftPressuredata", wftPressuredata: mappedData },
        wellData.wellId,
        layer,
        groupedWellID
      );
      updateIsWftPressureDataLoading(false);
    }
  }, [wftDataResponse, wftSuccessRequest]);

  useEffect(() => {
    if (
      wellData.pageNumber === activePage &&
      !wellData.dstPressuredata &&
      wellData.wellCardData[WELL_PANEL_FLAGS.HAS_DST.key]
    ) {
      const requestBody = {
        SearchTypes: DST_PRESSURE_DATA_SEARCHTYPES,
        Query: `Uwi:${convertUWI(wellData.wellCardData.uwi as string, -2)}`,
        IncludeFields: DST_PRESSURE_DATA_FIELDS,
        From: 0, // from count to start at (paging)
        Size: dataLakeSize,
        Sort: {
          SortOrder: SORT_ORDER.ASC,
          SortPropertyNames: PRESSURE_DATA_SORT_FIELDS,
        },
      };
      getDstDataLakeData(requestBody);
      updateIsDstPressureDataLoading(true);
    }
  }, [wellData.pageNumber, wellData.dstPressuredata, activePage]);

  useEffect(() => {
    if (
      wellData.pageNumber === activePage &&
      !wellData.wftPressureData &&
      wellData.wellCardData[WELL_PANEL_FLAGS.HAS_WFT.key]
    ) {
      const requestBody = {
        SearchTypes: WFT_PRESSURE_DATA_SEARCHTYPES,
        Query: `Uwi:${convertUWI(wellData.wellCardData.uwi as string, -2)}`,
        IncludeFields: WFT_PRESSURE_DATA_FIELDS,
        From: 0, // from count to start at (paging)
        Size: dataLakeSize,
        Sort: {
          SortOrder: SORT_ORDER.ASC,
          SortPropertyNames: PRESSURE_DATA_SORT_FIELDS,
        },
      };
      getWftDataLakeData(requestBody);
      updateIsWftPressureDataLoading(true);
    }
  }, [wellData.pageNumber, wellData.wftPressureData, activePage]);

  const handleOnChange = () => {
    onChange(WELL_PANEL_SECTION.PRESSURE_DATA_SECTION);
  };

  const showGeneralInfoSection = useMemo(
    () =>
      !wellData.dstPressuredata?.length &&
      !wellData.wftPressureData?.length &&
      !!wellData.wellCardData[WELL_PANEL_FLAGS.HAS_WFT.key] &&
      !!wellData.wellCardData[WELL_PANEL_FLAGS.HAS_DST.key],

    [wellData]
  );

  return (
    <WellPanelAccordion
      expanded={isExpanded}
      header={WELL_PANEL_SECTION.PRESSURE_DATA_SECTION.displayLabel}
      onChange={handleOnChange}
      isLoading={dstLoadingRequest || wftLoadingRequest}
    >
      <div className="well-panel-section-accordion-container">
        <PressureDataSection
          pressureDataType={"DST"}
          pressureDataFlag={
            wellData.wellCardData[WELL_PANEL_FLAGS.HAS_DST.key] as boolean
          }
          enableInfoSectionByType={!showGeneralInfoSection}
          data={wellData?.dstPressuredata ?? []}
          wellId={wellData.wellId}
          layer={layer}
          groupedWellID={groupedWellID}
        />
        <PressureDataSection
          pressureDataType={"WFT"}
          pressureDataFlag={
            wellData.wellCardData[WELL_PANEL_FLAGS.HAS_WFT.key] as boolean
          }
          enableInfoSectionByType={!showGeneralInfoSection}
          data={wellData?.wftPressureData ?? []}
          wellId={wellData.wellId}
          layer={layer}
          groupedWellID={groupedWellID}
        />
        {showGeneralInfoSection && (
          <NoDataSection
            message={
              <>
                <span>
                  <TooltipInfoIcon />
                </span>
                Subscribe to DSTs & WFTs to <br /> view Pressure Data
              </>
            }
          />
        )}
      </div>
    </WellPanelAccordion>
  );
};

export default PressureData;
