import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";

import classNames from "classnames";
import { Button } from "component-library";
import { isEqual } from "lodash";

import {
  AssetEconomicsChartProps,
  IAssetEconomicsData,
} from "../../../../types/charts/chartType/chartType";
import { TargetUoMKey } from "../../../../types/charts/chartTypeSettings/exonomicsTypeSettings";
import { Modules } from "../../../../types/modularity/modules";
import { WellEconomicsInputs } from "../../../../types/panels/wellPanel/wellEconomicsType";

import {
  ECONOMICS_DISPLAY_BY,
  TARGET_UOM_ECONOMICS,
} from "../../../../constants/charts/cashFlowChart";
import { CHART_MODE_VIEW } from "../../../../constants/charts/chartModeView";
import { MAX_WELLS_COUNT_ASSET_ECONOMICS } from "../../../../constants/charts/charts";
import * as constants from "../../../../constants/panels/wellPanel/wellEconomics";

import useChartStore from "../../../../store/chart/chartStore";
import useAlertPopupStore from "../../../../store/map/alert/alertStore";
import useMapSelectionStore from "../../../../store/map/selection/mapSelectionStore";
import useModularityStore from "../../../../store/modularity/modularityStore";
import usePanelsStore from "../../../../store/panels/panelsStore";

import useAnalysisChartRefetch from "../../../../customHooks/charts/useAnalysisChartRefetch";
import useWellEconomicsAggregation from "../../../../customHooks/charts/useWellEconomicsAggregation";
import useWellEconomicsAggregationRecalculate from "../../../../customHooks/charts/useWellEconomicsAggregationRecalculate";
import useWellEconomicsOutputData from "../../../../customHooks/panels/wellPanel/useWellEconomicsData";
import useWellEconomicsEvents from "../../../../customHooks/panels/wellPanel/useWellEconomicsEvents";
import useWellEconomicsUoM from "../../../../customHooks/panels/wellPanel/useWellEconomicsUoM";
import usePrevious from "../../../../customHooks/usePrevious";

import { clone } from "../../../../utils/helper";

import EconomicsTargetUomToggle from "../../../common/EconomicsTargetUomToggle";
import LoadingBackDrop from "../../../common/LoadingBackDrop";
import OutlinedInputField from "../../../common/OutlinedInputField";
import DownloadIcon from "../../../common/icons/DownloadIcon";
import WellPanelAccordion from "../../../panels/wellPanel/common/WellPanelAccordion";
import WellEconomicsOutput from "../../../panels/wellPanel/footer/WellEconomicsOutput";
import ChartTypeHeader from "../chartTypeHeader/ChartTypeHeader";
import AssetEconomicsMessageDisplay from "./AssetEconomicsMessageDisplay";

const AssetEconomics: FC<AssetEconomicsChartProps> = ({
  chartMode,
  chartId,
  chartType,
  onChartFullScreenClose,
}) => {
  const chartRef = useRef<any>();

  const [currentEconomicsIChartData, setCurrentEconomicsIChartData] = useState<
    IAssetEconomicsData | undefined
  >();
  const [targetUomValue, setTargetUomValue] = useState<TargetUoMKey>(
    TARGET_UOM_ECONOMICS.boe.key
  );
  const [hasTriggeredRecalculate, setHasTriggeredRecalculate] = useState(false);
  const [hasTriggeredReset, setHasTriggeredReset] = useState(false);
  const [hasTriggeredTargetUom, setHasTriggeredTargetUom] = useState(false);
  const [disableRecalculate, setDisableRecalculate] = useState(true);
  const [disableExport, setDisableExport] = useState(false);
  const [disableReset, setDisableReset] = useState(false);
  const [isEconomicsRecalculateLoading, setIsEconomicsRecalculateLoading] =
    useState(false);

  const wellEconomicsInputInfo = usePanelsStore(
    (state) => state.wellEconomicsInputs?.[chartId]
  );

  const removeWellEconomicsByIds = usePanelsStore(
    (state) => state.removeWellEconomicsByIds
  );

  const modules = useModularityStore((state) => state.modules);
  const updateModuleDataByKey = useModularityStore(
    (state) => state.updateModuleDataByKey
  );

  const chartData = useChartStore((state) => state.chartData);
  const getDataFlag = useChartStore((state) => state.chartDataRefetchFlag);
  const updateGetDataFlag = useChartStore(
    (state) => state.setChartDataRefetchFlag
  );
  const updateChartDataByKey = useChartStore(
    (state) => state.updateChartDataByKey
  );
  const resetChartFilterByAttributeOptions = useChartStore(
    (state) => state.resetChartFilterByAttributeOptions
  );

  const updateAlertState = useAlertPopupStore(
    (state) => state.updateAlertState
  );

  const selectedWellUwis = useMapSelectionStore(
    (state) => state.selectedWellUwis
  );

  const prevSelectedUwis = usePrevious(selectedWellUwis);

  const filteredUwis = useMemo(() => {
    if (!currentEconomicsIChartData) return selectedWellUwis;

    if (
      currentEconomicsIChartData?.displayBy ===
        ECONOMICS_DISPLAY_BY.attribute.key &&
      currentEconomicsIChartData?.filterBy?.uwis
    ) {
      return currentEconomicsIChartData.filterBy.uwis;
    }

    return selectedWellUwis;
  }, [
    currentEconomicsIChartData?.displayBy,
    currentEconomicsIChartData?.filterBy,
    selectedWellUwis,
  ]);

  const { refetch, updateRefetch } = useAnalysisChartRefetch({
    chartMode,
    chartId,
    chartType,
  });

  const {
    data: economicsData,
    loading: isLoading,
    loadingExport,
    getWellEconomicsOutputData,
  } = useWellEconomicsOutputData();

  const {
    warningMessage,
    isExpandedAccordion,
    setTriggerWarning,
    handleOnExpandDetails,
    handleSectionHeightUpdate,
  } = useWellEconomicsAggregation(chartId, chartMode);

  const { handleInputChange, handleOnBlur, validateBeforeRecalculate } =
    useWellEconomicsEvents(currentEconomicsIChartData?.chartId ?? "");

  const {
    originalInputValues,
    checkEquality,
    reCheckAPIValidations,
    parseInputValues,
    setGreyedStateNull,
    formatDefaultInputsToNull,
    addWellEconomicsByWellInput,
    checkDefaultEquality,
  } = useWellEconomicsAggregationRecalculate(currentEconomicsIChartData);

  const { formatWellEconomicsUnits } = useWellEconomicsUoM();

  const showSpinnerLoader = useMemo(
    () =>
      (hasTriggeredRecalculate || hasTriggeredReset || hasTriggeredTargetUom) &&
      isLoading
        ? false
        : isLoading,
    [isLoading, hasTriggeredRecalculate, hasTriggeredReset]
  );

  const updateModuleRefetch = () => {
    const chartModule: Modules | undefined = (clone(modules) as Modules[]).find(
      (module) => module.module === chartId
    );

    if (chartModule) {
      chartModule.refetch = false;

      updateModuleDataByKey(chartId, chartModule);
    }

    updateRefetch(false);
  };

  const handleRecalculate = useCallback(
    (parsedChangedInputValues?: WellEconomicsInputs, isForExport = false) => {
      if (!wellEconomicsInputInfo?.inputs) return;

      if (validateBeforeRecalculate() && reCheckAPIValidations()) {
        const inputs = parsedChangedInputValues
          ? parsedChangedInputValues
          : parseInputValues(wellEconomicsInputInfo?.inputs);
        const newInputs = setGreyedStateNull(inputs);
        const formattedInputs = formatDefaultInputsToNull(newInputs);

        getWellEconomicsOutputData({
          uwi: filteredUwis,
          inputs: formattedInputs,
          auditVerbosity: "FullList",
          summaryOnly: !isForExport,
          ...(currentEconomicsIChartData?.targetUom
            ? { targetUom: currentEconomicsIChartData?.targetUom }
            : {}),
          isForAnalysisExport: isForExport,
        });

        setDisableRecalculate(true);
        setDisableExport(true);
        setHasTriggeredRecalculate(true);
        setDisableReset(true);
        setIsEconomicsRecalculateLoading(true);
      }
    },
    [wellEconomicsInputInfo, filteredUwis]
  );

  const handleReset = () => {
    getWellEconomicsOutputData({
      uwi: filteredUwis,
      inputs: constants.WELL_ECONOMICS_DEFAULT_VALUES,
      includeFcfDetails: true,
    });

    setDisableRecalculate(true);
    setDisableReset(true);
    setDisableExport(true);
    setHasTriggeredReset(true);
    setIsEconomicsRecalculateLoading(true);
  };

  const handleExport = useCallback(() => {
    if (!wellEconomicsInputInfo?.inputs) return;
    const parsedChangedInputValues = parseInputValues(
      wellEconomicsInputInfo.inputs
    );

    if (checkDefaultEquality(parsedChangedInputValues)) {
      getWellEconomicsOutputData({
        uwi: filteredUwis,
        inputs: constants.WELL_ECONOMICS_DEFAULT_VALUES,
        summaryOnly: false,
        ...(currentEconomicsIChartData?.targetUom
          ? { targetUom: currentEconomicsIChartData?.targetUom }
          : {}),
        isForAnalysisExport: true,
      });

      setDisableRecalculate(true);
      setDisableReset(true);
      setDisableExport(true);
    } else {
      //will also recalculate and update changes made by user
      handleRecalculate(parsedChangedInputValues, true);
    }
  }, [wellEconomicsInputInfo?.inputs]);

  const handleTargetUomChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!wellEconomicsInputInfo?.inputs) return;
    if (!currentEconomicsIChartData) return;

    const uom = e.target.value as TargetUoMKey;
    setTargetUomValue(uom);
    const inputs = parseInputValues(wellEconomicsInputInfo.inputs);
    const newInputs = setGreyedStateNull(inputs);
    const formattedInputs = formatDefaultInputsToNull(newInputs);

    getWellEconomicsOutputData({
      uwi: filteredUwis,
      inputs: formattedInputs,
      auditVerbosity: "FullList",
      summaryOnly: true,
      targetUom: uom,
      isForAnalysisExport: false,
    });

    updateChartDataByKey(currentEconomicsIChartData.chartId, {
      ...currentEconomicsIChartData,
      targetUom: uom,
    });

    setHasTriggeredTargetUom(true);
    setDisableExport(true);
    setDisableReset(true);
    setIsEconomicsRecalculateLoading(true);
  };

  useEffect(() => {
    if (!wellEconomicsInputInfo?.inputs || !originalInputValues) {
      setDisableRecalculate(true);
      return;
    }

    if (wellEconomicsInputInfo?.errorState) {
      const parsedChangedInputValues = parseInputValues(
        wellEconomicsInputInfo?.inputs
      );
      if (
        Object.values(wellEconomicsInputInfo?.errorState).some(
          (item) => item
        ) ||
        checkEquality(parsedChangedInputValues)
      ) {
        setDisableRecalculate(true);
      } else {
        setDisableRecalculate(false);
      }
    }
  }, [
    originalInputValues,
    wellEconomicsInputInfo?.inputs,
    wellEconomicsInputInfo?.errorState,
  ]);

  useEffect(() => {
    if (!chartId) return;
    const chart = chartData?.find((data) => data.chartId === chartId) as
      | IAssetEconomicsData
      | undefined;

    if (chart) {
      setCurrentEconomicsIChartData(chart);
    }
  }, [chartData]);

  useEffect(() => {
    if (!selectedWellUwis.length && currentEconomicsIChartData) {
      const removedEconomicsData = {
        ...currentEconomicsIChartData,
        assetEconomicsRawData: undefined,
        displayBy: ECONOMICS_DISPLAY_BY.allWells.key,
        filterBy: undefined,
      };
      setCurrentEconomicsIChartData(removedEconomicsData);
      updateChartDataByKey(chartId, removedEconomicsData);
    } else if (
      !isEqual(selectedWellUwis, prevSelectedUwis) &&
      currentEconomicsIChartData
    ) {
      const removedEconomicsData = {
        ...currentEconomicsIChartData,
        displayBy: ECONOMICS_DISPLAY_BY.allWells.key,
        filterBy: undefined,
      };

      updateChartDataByKey(chartId, removedEconomicsData);
    }
  }, [selectedWellUwis]);

  useEffect(() => {
    if (!currentEconomicsIChartData?.assetEconomicsRawData) {
      const chart = chartData?.find((data) => data.chartId === chartId) as
        | IAssetEconomicsData
        | undefined;

      if (chart?.assetEconomicsRawData) {
        setCurrentEconomicsIChartData(chart);
        setTargetUomValue(chart.targetUom ?? TARGET_UOM_ECONOMICS.boe.key);
      }
    }
  }, [currentEconomicsIChartData, chartData]);

  useEffect(() => {
    if (refetch && filteredUwis.length < MAX_WELLS_COUNT_ASSET_ECONOMICS) {
      getWellEconomicsOutputData({
        uwi: filteredUwis,
        auditVerbosity: "FullList",
        summaryOnly: true,
        ...(currentEconomicsIChartData?.targetUom
          ? { targetUom: currentEconomicsIChartData?.targetUom }
          : {}),
      });

      resetChartFilterByAttributeOptions();
      if (wellEconomicsInputInfo) removeWellEconomicsByIds([chartId]);
    }
  }, [refetch]);

  useEffect(() => {
    if (!economicsData) {
      setTriggerWarning(undefined);
      return;
    }

    if (!filteredUwis.length) {
      updateModuleRefetch();
      return;
    }

    if (!economicsData.Result.Success) {
      updateAlertState({
        severity: "warning",
        message: "No economic data available for selected well(s)",
      });

      setTriggerWarning("No economics data available");
      updateModuleRefetch();
      return;
    }
    if (currentEconomicsIChartData) {
      if (
        hasTriggeredRecalculate &&
        wellEconomicsInputInfo &&
        currentEconomicsIChartData?.assetEconomicsRawData
      ) {
        const parsedChangedInputValues = parseInputValues(
          wellEconomicsInputInfo?.inputs
        );
        const economicsInputRawData = {
          ...currentEconomicsIChartData.assetEconomicsRawData,
          recalculatedValues: economicsData,
          userValuesBeforeRecalculation: parsedChangedInputValues,
        };

        updateChartDataByKey(chartId, {
          ...currentEconomicsIChartData,
          assetEconomicsRawData: economicsInputRawData,
        });

        setHasTriggeredRecalculate(false);
      } else {
        if (!wellEconomicsInputInfo) {
          addWellEconomicsByWellInput(economicsData.Inputs);
        } else if (hasTriggeredReset || hasTriggeredTargetUom) {
          removeWellEconomicsByIds([chartId]);

          addWellEconomicsByWellInput(economicsData.Inputs);
        }

        if (
          !currentEconomicsIChartData.assetEconomicsRawData ||
          (currentEconomicsIChartData.assetEconomicsRawData &&
            hasTriggeredReset) ||
          (currentEconomicsIChartData.assetEconomicsRawData &&
            hasTriggeredTargetUom) ||
          refetch
        ) {
          const updatedIchartData: IAssetEconomicsData = {
            ...currentEconomicsIChartData,
            assetEconomicsRawData: {
              defaultValues: economicsData,
              recalculatedValues: null,
              userValuesBeforeRecalculation: null,
            },
          };

          updateChartDataByKey(chartId, updatedIchartData);
        }
      }

      setTimeout(() => setIsEconomicsRecalculateLoading(false), 500);
      if (economicsData.TargetUom)
        setTargetUomValue(economicsData.TargetUom as TargetUoMKey);
    }
    setDisableExport(false);
    setHasTriggeredReset(false);
    setHasTriggeredTargetUom(false);
    setDisableReset(true);
    updateModuleRefetch();

    if (getDataFlag.refetch) {
      updateGetDataFlag({ chartId: "", refetch: false });
    }
  }, [economicsData]);

  useEffect(() => {
    handleSectionHeightUpdate(showSpinnerLoader);
  }, [showSpinnerLoader, warningMessage]);

  useEffect(() => {
    if (currentEconomicsIChartData?.assetEconomicsRawData?.recalculatedValues) {
      if (
        wellEconomicsInputInfo?.changedState &&
        !Object.values(wellEconomicsInputInfo.changedState).some((item) => item)
      ) {
        setDisableReset(true);
      } else {
        setDisableReset(false);
      }
      setDisableRecalculate(true);
    } else {
      setDisableReset(true);
    }
  }, [
    currentEconomicsIChartData?.assetEconomicsRawData,
    wellEconomicsInputInfo?.changedState,
  ]);

  return (
    <div
      className={classNames({
        "economics-fullScreen-individual":
          chartMode === CHART_MODE_VIEW.FULLSCREEN_INDIVIDUAL_CHART,
      })}
    >
      {!refetch && !showSpinnerLoader ? (
        <>
          <ChartTypeHeader
            chartId={chartId}
            isChartExport={false}
            chartMode={chartMode}
            onChartFullScreenClose={onChartFullScreenClose}
            chartRendered={false}
            chartRef={chartRef}
            onChartExportData={handleExport}
            disableExport={!!warningMessage}
          />
          <div
            className="plot-chart-container asset-economics-container-accordion"
            id={chartId}
            ref={chartRef}
          >
            {warningMessage ? (
              <AssetEconomicsMessageDisplay message={warningMessage} />
            ) : (
              <>
                {currentEconomicsIChartData?.assetEconomicsRawData && (
                  <>
                    <WellPanelAccordion
                      expanded={isExpandedAccordion}
                      header={"Details"}
                      onChange={() =>
                        handleOnExpandDetails(!isExpandedAccordion)
                      }
                      isLoading={false}
                      className="cl-custom-accordion"
                    >
                      <EconomicsTargetUomToggle
                        uomValue={targetUomValue}
                        onChange={handleTargetUomChange}
                      />
                      <div className="well-economics-fields-container">
                        {constants.WELL_ECONOMICS_INPUT_FIELDS.map((field) => (
                          <OutlinedInputField
                            key={field.key}
                            label={field.label}
                            suffix={formatWellEconomicsUnits(
                              field.key,
                              field.unit,
                              currentEconomicsIChartData.assetEconomicsRawData
                                ?.defaultValues.TargetUom
                            )}
                            value={
                              wellEconomicsInputInfo?.inputs?.[field.key] ?? ""
                            }
                            onChange={(e) => handleInputChange(e, field.key)}
                            onBlur={() => handleOnBlur(field.key)}
                            error={
                              wellEconomicsInputInfo?.errorState[field.key]
                            }
                            highlight={
                              !wellEconomicsInputInfo?.errorState[field.key] &&
                              wellEconomicsInputInfo?.changedState?.[field.key]
                            }
                            greyoutfilled={
                              wellEconomicsInputInfo?.greyedState?.[field.key]
                            }
                          />
                        ))}
                      </div>
                      <div className="economics-details-footer">
                        <Button
                          text="Reset"
                          type="secondary"
                          disabled={disableReset || isLoading}
                          onClick={handleReset}
                        />
                        <Button
                          text="Recalculate"
                          type="secondary"
                          disabled={disableRecalculate || isLoading}
                          onClick={() => handleRecalculate()}
                        />
                        <Button
                          text={
                            <>
                              <span>
                                <DownloadIcon />
                              </span>
                              <p>EXPORT</p>
                            </>
                          }
                          disabled={disableExport || loadingExport}
                          onClick={handleExport}
                        />
                      </div>
                    </WellPanelAccordion>
                    <WellEconomicsOutput
                      wellEconomicsData={
                        currentEconomicsIChartData.assetEconomicsRawData
                      }
                      isLoading={isEconomicsRecalculateLoading}
                      useUoMUnit={true}
                    />
                  </>
                )}
              </>
            )}
          </div>
        </>
      ) : (
        <LoadingBackDrop
          className={classNames("plot-chart-area loader")}
          isOpen
        />
      )}
    </div>
  );
};

export default AssetEconomics;
