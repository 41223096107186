import { Attribute } from "../../../types/common/attributes";
import { WellPermitPanelSection } from "../../../types/panels/wellPanel/wellPanel";

import * as attributes from "../../attributes";
import {
  BOTTOM_WELL_SPOTS,
  DYNAMIC_BOTTOM_WELL_SPOTS,
  DYNAMIC_WELL_SPOTS,
  WELL_PATHS,
  WELL_SPOTS,
  WELL_STICKS,
} from "../../constants";

//WELL TYPES
export const WELL_TYPES = {
  SURFACE: "surface",
  BOTTOM: "bottom",
} as const;

//MAP LAYER
export const MAP_LAYER = {
  WELL_SPOTS: WELL_SPOTS,
  DYNAMIC_WELL_SPOTS: DYNAMIC_WELL_SPOTS,
  BOTTOM_WELL_SPOTS: BOTTOM_WELL_SPOTS,
  DYNAMIC_BOTTOM_WELL_SPOTS: DYNAMIC_BOTTOM_WELL_SPOTS,
  WELL_PATHS: WELL_PATHS,
  WELL_STICKS: WELL_STICKS,
} as const;

//WELL PANEL SECTION
export const HEADER_SECTION: WellPermitPanelSection = {
  key: "header",
  displayLabel: "Header",
  urlSlug: "well-card",
};

export const STATISTICS_SECTION: WellPermitPanelSection = {
  key: "statistics",
  displayLabel: "Statistics",
  urlSlug: "well-card",
};

export const CASING_SECTION: WellPermitPanelSection = {
  key: "casing",
  displayLabel: "Casings",
  urlSlug: "casing",
};

export const PERFORATION_SECTION: WellPermitPanelSection = {
  key: "perforation",
  displayLabel: "Perfs",
  urlSlug: "perforations",
};

export const TEST_SECTION: WellPermitPanelSection = {
  key: "test",
  displayLabel: "Tests",
  urlSlug: "tests",
};
export const COMPLETION_SECTION: WellPermitPanelSection = {
  key: "completion",
  displayLabel: "Completion Data",
  urlSlug: "well-card",
};

export const FORMATION_SECTION: WellPermitPanelSection = {
  key: "formation",
  displayLabel: "Formations",
  urlSlug: "formation-top",
};

export const PERMIT_SECTION: WellPermitPanelSection = {
  key: "permit",
  displayLabel: "Permits",
  urlSlug: "permits",
};

export const GUN_BARREL_SECTION: WellPermitPanelSection = {
  key: "gunbarrel",
  displayLabel: "Gun Barrel",
  urlSlug: "gun-barrel",
};

export const PRODUCTION_PLOT_SECTION: WellPermitPanelSection = {
  key: "productionplot",
  displayLabel: "Production Plot",
  urlSlug: "productions",
};

export const WELL_ECONOMICS_SECTION: WellPermitPanelSection = {
  key: "welleconomics",
  displayLabel: "Well Economics",
  urlSlug: "EconomicsReportByUwi",
};

export const DECLINE_CURVE_PARAMETERS_SECTION: WellPermitPanelSection = {
  key: "declineCurveParameters",
  displayLabel: "Decline Curve Parameters",
  urlSlug: "well-card",
};

export const PRESSURE_DATA_SECTION: WellPermitPanelSection = {
  key: "pressureData",
  displayLabel: "Pressure Data",
  urlSlug: "Search",
};

export const CASHFLOW_CHART_SECTION: WellPermitPanelSection = {
  key: "cashFlowChart",
  displayLabel: "Cash Flow Chart",
  urlSlug: "EconomicsReportByUwi",
};

export const CASH_FLOW_CHART_INFO_KEY = `${CASHFLOW_CHART_SECTION.key}-info`;

export const WELL_PANEL_SECTION = {
  HEADER_SECTION: HEADER_SECTION,
  STATISTICS_SECTION: STATISTICS_SECTION,
  CASING_SECTION: CASING_SECTION,
  PERFORATION_SECTION: PERFORATION_SECTION,
  TEST_SECTION: TEST_SECTION,
  COMPLETION_SECTION: COMPLETION_SECTION,
  FORMATION_SECTION: FORMATION_SECTION,
  PERMIT_SECTION: PERMIT_SECTION,
  GUN_BARREL_SECTION: GUN_BARREL_SECTION,
  PRODUCTION_PLOT_SECTION: PRODUCTION_PLOT_SECTION,
  WELL_ECONOMICS_SECTION: WELL_ECONOMICS_SECTION,
  DECLINE_CURVE_PARAMETERS_SECTION: DECLINE_CURVE_PARAMETERS_SECTION,
  PRESSURE_DATA_SECTION: PRESSURE_DATA_SECTION,
  CASHFLOW_CHART_SECTION: CASHFLOW_CHART_SECTION,
} as const;

export const WELL_INFO_SORT_LIST = [
  HEADER_SECTION["key"],
  STATISTICS_SECTION["key"],
  DECLINE_CURVE_PARAMETERS_SECTION["key"],
  PERFORATION_SECTION["key"],
  TEST_SECTION["key"],
  PRESSURE_DATA_SECTION["key"],
  COMPLETION_SECTION["key"],
  CASING_SECTION["key"],
  FORMATION_SECTION["key"],
  PERMIT_SECTION["key"],
  CASH_FLOW_CHART_INFO_KEY,
];

//WELL PANEL TABS
export const WELL_PANEL_TAB = {
  WELL_INFO_TAB: "wellInfo",
  LOGS_AND_DOCS_TAB: "logsAndDocs",
  PLOTS_CHARTS_TAB: "plotCharts",
} as const;

//WELL PANEL ATTRIBUTES
export const UWI: Attribute = {
  key: "uwi",
  label: "UWI",
  group: attributes.HEADER,
  dataType: attributes.TEXT,
};

export const HAS_CASING: Attribute = {
  key: "hasCasing",
  label: "Has Casing",
  group: attributes.WELL_FLAG,
  dataType: attributes.BOOLEAN,
};

export const HAS_COMPLETION: Attribute = {
  key: "hasCompletion",
  label: "Has Casing",
  group: attributes.WELL_FLAG,
  dataType: attributes.BOOLEAN,
};

export const HAS_REPORTED_TOPS: Attribute = {
  key: "hasReportedTops",
  label: "Has Reported Tops",
  group: attributes.WELL_FLAG,
  dataType: attributes.BOOLEAN,
};

export const HAS_PERMIT: Attribute = {
  key: "hasPermit",
  label: "Has Permit",
  group: attributes.WELL_FLAG,
  dataType: attributes.BOOLEAN,
};
export const HAS_FORMATION: Attribute = {
  key: "interpretedProducingFormation",
  label: "Has Formation",
  group: attributes.WELL_FLAG,
  dataType: attributes.BOOLEAN,
};

export const HAS_GUN_BARREL: Attribute = {
  key: "hasGunBarrel",
  label: "Has Gun Barrel",
  group: attributes.WELL_FLAG,
  dataType: attributes.BOOLEAN,
};

export const WELL_PANEL_FLAGS: { [key: string]: Attribute } = {
  HAS_CASING: HAS_CASING,
  PERF: { ...attributes.PERF, key: "hasPerf" },
  TEST: { ...attributes.TEST, key: "hasTest" },
  HAS_COMPLETION: HAS_COMPLETION,
  HAS_PRODUCTION: { ...attributes.HAS_PRODUCTION, key: "hasProduction" },
  HAS_INJECTION: { ...attributes.HAS_INJECTION, key: "hasInjection" },
  HAS_REPORTED_TOPS: HAS_REPORTED_TOPS,
  HAS_PERMIT: HAS_PERMIT,
  HAS_FORMATION: HAS_FORMATION,
  HAS_GUN_BARREL: HAS_GUN_BARREL,
  HAS_WELL_ECONOMICS: {
    ...attributes.HAS_WELL_ECONOMICS,
    key: "hasWellEconomics",
  },
  HAS_FORECAST: { ...attributes.HAS_FORECAST, key: "hasForecast" },
  HAS_LAS: { ...attributes.HAS_LAS, key: "hasLas" },
  HAS_RASTER: { ...attributes.HAS_RASTER, key: "hasRaster" },
  HAS_WELL_FILE: { ...attributes.HAS_WELL_FILE, key: "hasWellFile" },
  HAS_DST: { ...attributes.HAS_DST, key: "hasDst" },
  HAS_WFT: { ...attributes.HAS_WFT, key: "hasWFT" },
};

export const WELL_INFO_WELL_NUMBER: Attribute = {
  ...attributes.WELL_NUMBER,
  key: "wellNumber",
};
export const WELL_INFO_WELL_NAME: Attribute = {
  ...attributes.WELL_NAME,
  key: "wellName",
};
export const WELL_INFO_WELL_STATUS: Attribute = {
  ...attributes.WELL_STATUS,
  key: "wellStatus",
};

export const WELL_INFO_HEADER_ATTRIBUTES: { [key: string]: Attribute } = {
  STATE_NAME: { ...attributes.STATE_NAME, key: "stateName" },
  COUNTY: { ...attributes.COUNTY, key: "county" },
  WELL_TYPE: { ...attributes.WELL_TYPE, key: "wellType" },
  SLANT: {
    ...attributes.SLANT,
    key: "slant",
    label: "Wellbore Profile (Slant)",
  },
  OPERATOR_NAME: { ...attributes.OPERATOR_NAME, key: "operatorName" },
  ULTIMATE_OWNER: { ...attributes.ULTIMATE_OWNER, key: "ultimateOwner" },
  FIELD_NAME: { ...attributes.FIELD_NAME, key: "fieldName" },
  BASIN_NAME: { ...attributes.BASIN_NAME, key: "basinName" },
  DISPLAY_FORMATION: {
    ...attributes.DISPLAY_FORMATION,
    key: "displayFormation",
  },
  INTERPRETED_PRODUCING_FORMATION: {
    ...attributes.INTERPRETED_PRODUCING_FORMATION,
    key: "interpretedProducingFormation",
  },
  MEASURED_DEPTH: { ...attributes.MEASURED_DEPTH, key: "measuredDepth" },
  TOTAL_VERTICAL_DEPTH: {
    ...attributes.TOTAL_VERTICAL_DEPTH,
    key: "totalVerticalDepth",
  },
  LATERAL_LENGTH: { ...attributes.LATERAL_LENGTH, key: "lateralLength" },
  SPUD_DATE: { ...attributes.SPUD_DATE, key: "spudDate" },
  COMPLETION_DATE: { ...attributes.COMPLETION_DATE, key: "completionDate" },
  ELEVATION_GROUND: {
    ...attributes.ELEVATION_GROUND,
    key: "elevationGround",
    label: "Elevation GL",
  },
  HAS_PRODUCTION: { ...attributes.HAS_PRODUCTION, key: "hasProduction" },
  HAS_FORECAST: { ...attributes.HAS_FORECAST, key: "hasForecast" },
  PERF_INTERVAL_TOP: {
    ...attributes.PERF_INTERVAL_TOP,
    key: "perfIntervalTop",
    label: "Perf Interval Top",
  },
  PERF_INTERVAL_BOTTOM: {
    ...attributes.PERF_INTERVAL_BOTTOM,
    key: "perfIntervalBottom",
    label: "Perf Interval Bottom",
  },
  CLOSEST_HORIZONTAL_DISTANCE_IN_ZONE: {
    ...attributes.CLOSEST_HORIZONTAL_DISTANCE_IN_ZONE,
    key: "closestHorizontalDistanceInZone",
  },
  CLOSEST_HORIZONTAL_DISTANCE: {
    ...attributes.CLOSEST_HORIZONTAL_DISTANCE,
    key: "closestHorizontalDistance",
  },
  CLOSEST_VERTICAL_DISTANCE: {
    ...attributes.CLOSEST_VERTICAL_DISTANCE,
    key: "closestVerticalDistance",
  },
  COUNT_OF_PARENT_WELLS: {
    ...attributes.COUNT_OF_PARENT_WELLS,
    key: "countOfParentWells",
  },
  COUNT_OF_CHILD_WELLS: {
    ...attributes.COUNT_OF_CHILD_WELLS,
    key: "countOfChildWells",
  },
  COUNT_OF_SIBLING_WELLS: {
    ...attributes.COUNT_OF_SIBLING_WELLS,
    key: "countOfSiblingWells",
  },
  WELL_NAME: {
    ...attributes.WELL_NAME,
    key: "wellName",
  },
  TGS_OPERATOR: {
    ...attributes.ULTIMATE_OWNER,
    key: "ultimateOwner",
  },
};

export const WELL_INFO_STATISTICS_ATTRIBUTES = {
  FIRST_MONTH: { ...attributes.FIRST_MONTH, key: "firstMonth" },
  LAST_MONTH: { ...attributes.LAST_MONTH, key: "lastMonth" },
  CUM_OIL: { ...attributes.CUM_OIL, key: "cumOil" },
  MAX_OIL: { ...attributes.MAX_OIL, key: "maxOil" },
  MAX_OIL_PLUS_2: { ...attributes.MAX_OIL_PLUS_2, key: "maxOilPlus2" },
  WELL_FORECAST_OIL_ULTIMATE: {
    ...attributes.WELL_FORECAST_OIL_ULTIMATE,
    key: "wellForecastOilUltimate",
    label: "Oil EUR (Well Oil Ultimate)",
  },
  CUM_GAS: { ...attributes.CUM_GAS, key: "cumGas" },
  MAX_GAS: { ...attributes.MAX_GAS, key: "maxGas" },
  MAX_GAS_PLUS_2: { ...attributes.MAX_GAS_PLUS_2, key: "maxGasPlus2" },
  WELL_FORECAST_GAS_ULTIMATE: {
    ...attributes.WELL_FORECAST_GAS_ULTIMATE,
    key: "wellForecastGasUltimate",
    label: "Gas EUR (Well Gas Ultimate)",
  },
  CUM_WATER: { ...attributes.CUM_WATER, key: "cumWater" },
  MAX_WATER: { ...attributes.MAX_WATER, key: "maxWater" },
  MAX_WATER_PLUS_2: { ...attributes.MAX_WATER_PLUS_2, key: "maxWaterPlus2" },
  WELL_FORECAST_WATER_ULTIMATE: {
    ...attributes.WELL_FORECAST_WATER_ULTIMATE,
    key: "wellForecastWaterUltimate",
    label: "Water EUR (Well Water Ultimate)",
  },
  CUM_INJECTION_LIQUID: {
    ...attributes.CUM_INJECTION_LIQUID,
    key: "cumInjectionLiquid",
  },
  CUM_INJECTION_GAS: {
    ...attributes.CUM_INJECTION_GAS,
    key: "cumInjectionGas",
  },
};

export const WELL_INFO_DECLINE_CURVE_ATTRIBUTES = {
  OIL_ALGORITHM: attributes.OIL_ALGORITHM,
  OIL_INITIAL_RATE: attributes.OIL_INITIAL_RATE,
  OIL_DECLINE_RATE: attributes.OIL_DECLINE_RATE,
  OIL_B_FACTOR: attributes.OIL_B_FACTOR,
  OIL_START: attributes.OIL_START,
  GAS_ALGORITHM: attributes.GAS_ALGORITHM,
  GAS_INITIAL_RATE: attributes.GAS_INITIAL_RATE,
  GAS_DECLINE_RATE: attributes.GAS_DECLINE_RATE,
  GAS_B_FACTOR: attributes.GAS_B_FACTOR,
  GAS_START: attributes.GAS_START,
  WATER_ALGORITHM: attributes.WATER_ALGORITHM,
  WATER_INITIAL_RATE: attributes.WATER_INITIAL_RATE,
  WATER_DECLINE_RATE: attributes.WATER_DECLINE_RATE,
  WATER_B_FACTOR: attributes.WATER_B_FACTOR,
  WATER_START: attributes.WATER_START,
};

export const DECLINE_CURVE_DECIMAL_FORMAT = {
  RATES: 3,
  B_FACTOR: 1,
};

export const WELL_INFO_COMPLETION_DATA_ATTRIBUTES = {
  COMPLETION_DATE: { ...attributes.COMPLETION_DATE, key: "completionDate" },
  LATERAL_LENGTH: { ...attributes.LATERAL_LENGTH, key: "lateralLength" },
  DRILLING_DAYS: { ...attributes.DRILLING_DAYS, key: "drillingDays" },
  NUMBER_OF_STAGES: { ...attributes.NUMBER_OF_STAGES, key: "numberOfStages" },
  MAX_TREATMENT_RATE: {
    ...attributes.MAX_TREATMENT_RATE,
    key: "maxTreatmentRate",
  },
  MAX_TREATMENT_PRESSURE: {
    ...attributes.MAX_TREATMENT_PRESSURE,
    key: "maxTreatmentPressure",
  },
  PROPPANT_AMOUNT: {
    ...attributes.PROPPANT_AMOUNT,
    key: "proppantAmount",
    label: "Proppant Amt",
  },
  FLUID_AMOUNT: {
    ...attributes.FRAC_FLUID_AMOUNT,
    key: "fractureFluidAmount",
    label: "Fluid Amt",
  },
  ACID_AMOUNT: {
    ...attributes.ACID_AMOUNT,
    key: "acidAmount",
    label: "Acid Amt",
  },
  TREATMENT_REMARKS: {
    ...attributes.TREATMENT_REMARKS,
    key: "treatmentRemarks",
  },
};

export const WELL_INFO_COMPLETION_DATA_HEADER_ATTRIBUTES = [
  WELL_INFO_COMPLETION_DATA_ATTRIBUTES.COMPLETION_DATE.label,
  WELL_INFO_COMPLETION_DATA_ATTRIBUTES.LATERAL_LENGTH.label,
  WELL_INFO_COMPLETION_DATA_ATTRIBUTES.DRILLING_DAYS.label,
  WELL_INFO_COMPLETION_DATA_ATTRIBUTES.NUMBER_OF_STAGES.label,
  WELL_INFO_COMPLETION_DATA_ATTRIBUTES.MAX_TREATMENT_RATE.label,
  WELL_INFO_COMPLETION_DATA_ATTRIBUTES.MAX_TREATMENT_PRESSURE.label,
  WELL_INFO_COMPLETION_DATA_ATTRIBUTES.PROPPANT_AMOUNT.label,
  WELL_INFO_COMPLETION_DATA_ATTRIBUTES.FLUID_AMOUNT.label,
  WELL_INFO_COMPLETION_DATA_ATTRIBUTES.ACID_AMOUNT.label,
  WELL_INFO_COMPLETION_DATA_ATTRIBUTES.TREATMENT_REMARKS.label,
];

export const WELL_INFO_COMPLETION_DATA_CONTENT_ATTRIBUTES = [
  WELL_INFO_COMPLETION_DATA_ATTRIBUTES.COMPLETION_DATE.key,
  WELL_INFO_COMPLETION_DATA_ATTRIBUTES.LATERAL_LENGTH.key,
  WELL_INFO_COMPLETION_DATA_ATTRIBUTES.DRILLING_DAYS.key,
  WELL_INFO_COMPLETION_DATA_ATTRIBUTES.NUMBER_OF_STAGES.key,
  WELL_INFO_COMPLETION_DATA_ATTRIBUTES.MAX_TREATMENT_RATE.key,
  WELL_INFO_COMPLETION_DATA_ATTRIBUTES.MAX_TREATMENT_PRESSURE.key,
  WELL_INFO_COMPLETION_DATA_ATTRIBUTES.PROPPANT_AMOUNT.key,
  WELL_INFO_COMPLETION_DATA_ATTRIBUTES.FLUID_AMOUNT.key,
  WELL_INFO_COMPLETION_DATA_ATTRIBUTES.ACID_AMOUNT.key,
  WELL_INFO_COMPLETION_DATA_ATTRIBUTES.TREATMENT_REMARKS.key,
];
