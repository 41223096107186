import classNames from "classnames";
import { CircularButton } from "component-library";

import { WftPressureDataWellPanel } from "../../../types/panels/wellPanel/pressureDataType";

import { WFT_PRESSURE_DATA_FIELDS_DISPLAY } from "../../../constants/panels/wellPanel/pressureData";

import WellInfoText from "../../common/WellInfoText";
import TooltipInfoIcon from "../../common/icons/TooltipInfoIcon";
import NoDataSection from "../wellPanel/sections/NoDataSection";
import IncerementalChart from "./charts/IncerementalChart";

interface WftPanelProps {
  panelContent?: WftPressureDataWellPanel;
  handleCloseWftPanel: () => void;
}

const WftPanel = ({ panelContent, handleCloseWftPanel }: WftPanelProps) => {
  return (
    <div className="dst-panel-container">
      {panelContent && (
        <>
          <div className="dst-info-header">
            <div className="dst-panel-controls">
              <CircularButton
                icon={"close"}
                className="dst-panel-close-button"
                onClick={handleCloseWftPanel}
              />
            </div>

            <div className="dst-info-header-text">
              <div className="dst-name">{panelContent.TestName}</div>
              <div className="dst-formation">{panelContent.Formation}</div>
            </div>
          </div>
          <div className="dst-info-container">
            {WFT_PRESSURE_DATA_FIELDS_DISPLAY.map((field, index) => (
              <div
                className={classNames("dst-info-item", {
                  "full-width":
                    index === WFT_PRESSURE_DATA_FIELDS_DISPLAY.length - 1 ||
                    index === WFT_PRESSURE_DATA_FIELDS_DISPLAY.length - 2,
                })}
                key={field.key}
              >
                <WellInfoText
                  label={field.label}
                  value={
                    panelContent[
                      field.key as keyof Omit<
                        WftPressureDataWellPanel,
                        "IncrementalShutInChartTrace"
                      >
                    ]
                  }
                />
              </div>
            ))}
          </div>

          {panelContent.IncrementalShutInChartTrace ? (
            <IncerementalChart
              trace={panelContent.IncrementalShutInChartTrace}
              xAxisTitle={"Seconds"}
            />
          ) : (
            <NoDataSection
              message={
                <>
                  <span>
                    <TooltipInfoIcon />
                  </span>
                  <p>
                    No Incremental Shut-In available for this <br /> test.
                    Export from ‘Export Manager’ for <br /> additional WFT data.
                  </p>
                </>
              }
            />
          )}
        </>
      )}
    </div>
  );
};

export default WftPanel;
