import { StateCreator } from "zustand";

import { Modules } from "../../types/modularity/modules";
import { ModularitySlice, RGLSlice } from "../../types/modularity/store";

import { CHART_TYPES } from "../../constants/charts/charts";

import { clone } from "../../utils/helper";

const rglWindowSlice: StateCreator<RGLSlice> = (set) => ({
  isChartsDraggable: "",
  updateIsChartsDraggable: (isChartsDraggable) =>
    set(() => ({ isChartsDraggable })),

  lockDrag: false,
  updateLockDrag: (lockDrag) => set(() => ({ lockDrag })),
});

const modularSlice: StateCreator<ModularitySlice> = (set, get) => ({
  modules: [],
  addModule: (moduleName, chartType, title, refetch) =>
    set((state) => ({
      modules: [
        ...state.modules,
        {
          module: moduleName,
          refetch: refetch,
          isExpanded: true,
          chartType: chartType,
          title: title,
          ...(chartType === CHART_TYPES.ASSET_ECONOMICS
            ? { isExpandedAccordion: false }
            : {}),
        },
      ],
    })),
  updateModules: (modules) => set(() => ({ modules })),
  updateModuleDataByKey: (chartId, moduleData) =>
    set(() => ({
      modules: get().modules.map((currentModuleData) => {
        const newModuleData =
          currentModuleData?.module === chartId
            ? moduleData
            : currentModuleData;
        return newModuleData;
      }),
    })),
  updateModuleDataRefresh: (chartIds) =>
    set(() => ({
      modules: get().modules.map((currentModuleData) => {
        const newModuleData: Modules = clone(currentModuleData);
        if (chartIds.includes(currentModuleData?.module)) {
          newModuleData.refetch = true;
        }

        return newModuleData;
      }),
    })),

  chartFullScreenLayout: [],
  addChartFullScreenLayout: (chartId, chartType) => {
    set((state) => {
      return {
        chartFullScreenLayout: [
          {
            type: chartType,
            i: chartId,
            x: 0,
            y: 0,
            w: chartType === CHART_TYPES.ASSET_ECONOMICS ? 2 : 8,
            h: chartType === CHART_TYPES.ASSET_ECONOMICS ? 6.3 : 2,
            minW: chartType === CHART_TYPES.ASSET_ECONOMICS ? 2 : 4,
            maxW: 8,
            minH: chartType === CHART_TYPES.ASSET_ECONOMICS ? 6.3 : 2,
            ...(chartType === CHART_TYPES.ASSET_ECONOMICS ? { maxH: 6 } : {}),
          },
          ...state.chartFullScreenLayout,
        ],
      };
    });
  },
  updateChartFullScreenLayout: (chartFullScreenLayout) =>
    set((state) => ({ chartFullScreenLayout })),

  chartDefaultLayout: [],
  addChartDefaultLayout: (chartId, chartType) => {
    set((state) => ({
      chartDefaultLayout: [
        {
          type: chartType,
          i: chartId,
          x: 0,
          y: 0,
          w: 2.88,
          h: 2,
          minW: 2.88,
          maxW: 2.88,
          minH: 2,
        },
        ...state.chartDefaultLayout,
      ],
    }));
  },
  updateChartDefaultLayout: (chartDefaultLayout) =>
    set((state) => ({ chartDefaultLayout })),
  updateChartDefaultLayoutByKey: (chartId, chartDefaultLayout) =>
    set(() => ({
      chartDefaultLayout: get().chartDefaultLayout.map(
        (currentChartDefaultLayout) => {
          const newChartDefaultData =
            currentChartDefaultLayout?.i === chartId
              ? chartDefaultLayout
              : currentChartDefaultLayout;
          return newChartDefaultData;
        }
      ),
    })),
});

export { modularSlice, rglWindowSlice };
