import { FC } from "react";

import { Button } from "@mui/material";

import classNames from "classnames";

import {
  DstPressureDataWellPanel,
  WftPressureDataWellPanel,
} from "../../../../../types/panels/wellPanel/pressureDataType";
import { MapLayer } from "../../../../../types/panels/wellPanel/wellPanel";

import usePanelsStore from "../../../../../store/panels/panelsStore";

import DoubleArrowIcon from "../../../../common/icons/DoubleArrowIcon";
import TooltipInfoIcon from "../../../../common/icons/TooltipInfoIcon";
import NoDataSection from "../../sections/NoDataSection";

interface PressureDataSectionProps {
  pressureDataType: string;
  pressureDataFlag: boolean;
  enableInfoSectionByType: boolean;
  data: DstPressureDataWellPanel[] | WftPressureDataWellPanel[];
  wellId: number;
  layer: MapLayer;
  groupedWellID: number;
}

const PressureDataSection: FC<PressureDataSectionProps> = ({
  pressureDataType,
  pressureDataFlag,
  enableInfoSectionByType,
  data,
  wellId,
  layer,
  groupedWellID,
}) => {
  const updateActiveWellPanelPressureData = usePanelsStore(
    (state) => state.updateActiveWellPanelPressureData
  );
  return (
    <>
      {!!data.length && (
        <>
          {data.map((pressureData) => (
            <Button
              variant="contained"
              className={classNames("dst-data-summary", {
                expanded: pressureData.IsSelected,
              })}
              key={pressureData.TestName}
              onClick={() => {
                updateActiveWellPanelPressureData(
                  wellId,
                  layer,
                  groupedWellID,
                  {
                    TestName: pressureData.TestName,
                  }
                );
              }}
            >
              <div className="dst-summary-info">
                <div className="title">{pressureData.TestName}</div>
                <div className="formation">
                  {pressureData.Formation.toUpperCase()}
                </div>
                <DoubleArrowIcon />
              </div>
            </Button>
          ))}
        </>
      )}
      {!!!data.length && enableInfoSectionByType && pressureDataFlag && (
        <NoDataSection
          message={
            <>
              <span>
                <TooltipInfoIcon />
              </span>
              Subscribe to {pressureDataType}s to view <br /> {pressureDataType}{" "}
              Test Data
            </>
          }
        />
      )}
    </>
  );
};

export default PressureDataSection;
