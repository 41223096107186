/* eslint-disable jsx-a11y/no-autofocus */

/* eslint-disable jsx-a11y/no-static-element-interactions */

/* eslint-disable react/display-name */
import { Ref, useRef, useState } from "react";

import { Menu, MenuItem } from "@mui/material";

import { handleKeyDown } from "../../utils";

interface NestedMenuItemOriginPositions {
  vertical: "top" | "bottom";
  horizontal: "center" | "left" | "right";
}

interface ContainerProps<T> {
  ref?: Ref<T>;
  onMouseEnter?: (event?: React.MouseEvent<T>) => void;
  onMouseLeave?: (event?: React.MouseEvent<T>) => void;
  onClick?: (event?: React.MouseEvent<T>) => void;
}

interface NestedMenuItemProps {
  parentMenuOpen?: boolean;
  item: React.ReactNode;
  children: React.ReactNode;
  className?: string;
  tabIndex?: number;
  ContainerProps?: ContainerProps<HTMLDivElement>;
  nestedMenuAnchorOrigin: NestedMenuItemOriginPositions;
  nestedMenutransformOrigin: NestedMenuItemOriginPositions;
  actionType: "click" | "hover";
  disabled?: boolean;

  // All other props
  [x: string]: any;
}

const NestedMenuItem: React.FC<NestedMenuItemProps> = ({
  parentMenuOpen = false,
  item,
  children,
  className,
  tabIndex: tabIndexProp,
  ContainerProps: ContainerPropsProp = {},

  //for more info regards anchors: https://mui.com/material-ui/react-popover/#anchor-playground
  nestedMenuAnchorOrigin = {
    vertical: "top",
    horizontal: "right",
  },
  nestedMenutransformOrigin = {
    vertical: "top",
    horizontal: "left",
  },
  actionType = "hover",

  disabled = false,
  ...MenuItemProps
}) => {
  const { ref: containerRefProp, ...ContainerProps } = ContainerPropsProp;

  const menuItemRef = useRef(null);

  const containerRef = useRef(null);

  const menuContainerRef = useRef(null);

  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

  const handleMouseEnter = (event?: React.MouseEvent<HTMLDivElement>) => {
    if (actionType === "hover") {
      setIsSubMenuOpen(true);

      if (ContainerProps?.onMouseEnter) {
        ContainerProps.onMouseEnter(event);
      }
    }
  };
  const handleMouseLeave = (event?: React.MouseEvent<HTMLDivElement>) => {
    if (actionType === "hover") {
      setIsSubMenuOpen(false);

      if (ContainerProps?.onMouseLeave) {
        ContainerProps.onMouseLeave(event);
      }
    }
  };

  const handleMouseClick = (event?: React.MouseEvent<HTMLDivElement>) => {
    if (actionType === "click") {
      setIsSubMenuOpen(true);

      if (ContainerProps?.onClick) {
        ContainerProps.onClick(event);
      }
    }
  };

  const open = isSubMenuOpen && parentMenuOpen;

  // Root element must have a `tabIndex` attribute for keyboard navigation
  let tabIndex;
  if (!disabled) {
    tabIndex = tabIndexProp ?? -1;
  }

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div
      {...ContainerProps}
      ref={containerRef}
      tabIndex={tabIndex}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleMouseClick}
      onKeyDown={handleKeyDown}
    >
      <MenuItem
        {...MenuItemProps}
        className={className}
        ref={menuItemRef}
        autoFocus={false}
        disabled={disabled}
      >
        {item}
      </MenuItem>
      <Menu
        // Set pointer events to 'none' to prevent the invisible Popover div
        // from capturing events for clicks and hovers
        style={{ pointerEvents: "none" }}
        anchorEl={menuItemRef.current}
        anchorOrigin={nestedMenuAnchorOrigin}
        transformOrigin={nestedMenutransformOrigin}
        open={open}
        autoFocus={false}
        disableAutoFocus
        disableEnforceFocus
        onClose={() => {
          setIsSubMenuOpen(false);
        }}
      >
        <div ref={menuContainerRef} style={{ pointerEvents: "auto" }}>
          {children}
        </div>
      </Menu>
    </div>
  );
};

export default NestedMenuItem;
