import { RecordType } from "../../types/common/appMode/appMode";
import { Bounds } from "../../types/common/attributes";
import { SearchCriteria } from "../../types/common/search";

import {
  ATTRIBUTES_TAB,
  BOTTOM_PERMIT_SPOTS,
  BOTTOM_WELL_SPOTS,
  PERMIT_PATH_AND_STICKS,
  PERMIT_SPOTS,
  WELL_PATH_AND_STICKS,
  WELL_SPOTS,
} from "../../constants/constants";
import { SIDEBAR_MENU_KEYS } from "../../constants/panels/menu";
import { RECORD_TYPES } from "../../constants/panels/searchPanel/queryBuilder/attributeValues";
import { SEARCH_TYPES } from "../../constants/panels/searchPanel/search";

import useAppModeStore from "../../store/common/appModeStore";
import useExportCardStore from "../../store/exportCard/exportCardStore";
import useDataGridStore from "../../store/grid/dataGridStore";
import useMapDrawStore from "../../store/map/draw/mapDrawStore";
import useExtentInfoStore from "../../store/map/gis/extent/extentStore";
import useMapStore from "../../store/map/mapStore";
import useMapSelectionStore from "../../store/map/selection/mapSelectionStore";
import { resetCarbonStorageSlice } from "../../store/map/settings/carbonStorageSlice";
import { resetMapOverlaySlice } from "../../store/map/settings/mapOverlaySlice";
import useMapSettingsStore from "../../store/map/settings/mapSettingsStore";
import { resetStratModelsSlice } from "../../store/map/settings/stratigraphicModelsSlice";
import usePanelsStore from "../../store/panels/panelsStore";
import useQueryBuilderStore from "../../store/search/queryBulder/queryBuilderStore";
import useUWISearchStore from "../../store/search/uwiSearch/searchUWIStore";
import useStore from "../../store/useStore";

import { getRecordTypeOnBound } from "../../utils/common/boundsData";

import useRecordType from "../common/useRecordType";
import useSagaMap from "../map/useSagaMap";
import usePrevious from "../usePrevious";
import useDrawPolygon from "./useDrawPolygon";
import useSearchPanel from "./useSearchPanel";

const useSearchPanelActions = () => {
  const { resetMapViewBaseMap } = useSagaMap();
  const { removeAllPolygonsFromSource } = useDrawPolygon();

  const polygons = useMapDrawStore((state) => state.polygons);
  const resetAllPolygonStates = useMapDrawStore(
    (state) => state.resetAllPolygonStates
  );

  const resetAllMapSelections = useMapSelectionStore(
    (state) => state.resetAllMapSelections
  );

  const updateLastSearchedBy = useStore((state) => state.updateLastSearchedBy);
  const updateCurrentBounds = useQueryBuilderStore(
    (state) => state.updateCurrentBounds
  );

  const resetWellCard = useStore((state) => state.resetWellCard);
  const resetSelectedSavedSearch = useStore(
    (state) => state.resetSelectedSavedSearch
  );

  const resetUWIStates = useUWISearchStore((state) => state.resetUWIStates);

  const activeSearchPanelTab = usePanelsStore(
    (state) => state.activeSearchPanelTab
  );
  const resetWellList = usePanelsStore((state) => state.resetWellList);
  const resetPermitList = usePanelsStore((state) => state.resetPermitList);
  const updateActivePanel = usePanelsStore((state) => state.updateActivePanel);
  const toggleIsOpenQBEditorPanel = usePanelsStore(
    (state) => state.toggleIsOpenQBEditorPanel
  );

  const updateExtentInfo = useExtentInfoStore(
    (state) => state.updateExtentInfo
  );

  const updateIsSearchCriteriaChange = useExportCardStore(
    (state) => state.updateIsSearchCriteriaChange
  );
  const resetExportStates = useExportCardStore(
    (state) => state.resetExportStates
  );

  const toggleLayer = useMapStore((state) => state.toggleLayer);
  const drawLayerSource = useMapStore((state) => state.drawLayerSource);
  const resetMapStates = useMapStore((state) => state.resetMapStates);
  const setDVTUpdated = useMapStore((state) => state.setDVTUpdated);
  const toggleIsHighlightSelectedSpots = useMapSettingsStore(
    (state) => state.toggleIsHighlightSelectedSpots
  );
  const resetLayerStylingStates = useMapSettingsStore(
    (state) => state.resetLayerStylingStates
  );

  const updateGridWellDataTrigger = useDataGridStore(
    (state) => state.updateGridWellDataTrigger
  );
  const updateInitialGridSearchMade = useDataGridStore(
    (state) => state.updateInitialGridSearchMade
  );
  const updateSearchCriteria = useDataGridStore(
    (state) => state.updateSearchCriteria
  );
  const updateSortByAnalysis = useDataGridStore(
    (state) => state.updateSortByAnalysis
  );
  const toggleGrid = useDataGridStore((state) => state.toggleGrid);
  const toggleGridHeader = useDataGridStore((state) => state.toggleGridHeader);
  const resetSearchCriteria = useDataGridStore(
    (state) => state.resetSearchCriteria
  );
  const resetSortFilterModels = useDataGridStore(
    (state) => state.resetSortFilterModels
  );
  const resetDefaultGridModels = useDataGridStore(
    (state) => state.resetDefaultGridModels
  );
  const resetGridStates = useDataGridStore((state) => state.resetGridStates);

  const clearQBValues = useQueryBuilderStore((state) => state.clearQBValues);

  const updateIsQBUpdated = useQueryBuilderStore(
    (state) => state.updateIsQBUpdated
  );

  const UoMValueOnSelection = useAppModeStore(
    (state) => state.UoMValueOnSelection
  );
  const updateUoMValueOnSearch = useAppModeStore(
    (state) => state.updateUoMValueOnSearch
  );
  const updateUoMAlertState = useAppModeStore(
    (state) => state.updateUoMAlertState
  );

  const { searchedRecordType, isPermitRecord } = useRecordType();
  const prevRecordType = usePrevious(searchedRecordType);
  const { hasLoadedAttribSavedSearch, hasLoadedUWISavedSearch, uwiSearchType } =
    useSearchPanel();

  const resetPolygons = () => {
    if (polygons.length) {
      resetAllPolygonStates();
      removeAllPolygonsFromSource(polygons, drawLayerSource);
    }
  };

  const resetAttribsPolygons = () => {
    clearQBValues();
    updateCurrentBounds([]);
    resetPolygons();
  };

  const resetDefaultLayersByRecordType = (recordType: RecordType | null) => {
    if (recordType === RECORD_TYPES.PERMIT) {
      toggleLayer(PERMIT_SPOTS, true);
      toggleLayer(BOTTOM_PERMIT_SPOTS, false);
      toggleLayer(WELL_SPOTS, false);
      toggleLayer(BOTTOM_WELL_SPOTS, false);
      toggleLayer(WELL_PATH_AND_STICKS, false);
      toggleLayer(PERMIT_PATH_AND_STICKS, true);
    } else if (recordType === RECORD_TYPES.WELLS_AND_PERMIT) {
      toggleLayer(PERMIT_SPOTS, true);
      toggleLayer(BOTTOM_PERMIT_SPOTS, false);
      toggleLayer(WELL_SPOTS, true);
      toggleLayer(BOTTOM_WELL_SPOTS, false);
      toggleLayer(WELL_PATH_AND_STICKS, true);
      toggleLayer(PERMIT_PATH_AND_STICKS, true);
    } else if (recordType === RECORD_TYPES.WELL) {
      toggleLayer(PERMIT_SPOTS, false);
      toggleLayer(BOTTOM_PERMIT_SPOTS, false);
      toggleLayer(WELL_SPOTS, true);
      toggleLayer(BOTTOM_WELL_SPOTS, false);
      toggleLayer(WELL_PATH_AND_STICKS, true);
      toggleLayer(PERMIT_PATH_AND_STICKS, false);
    }
  };

  const searchCallback = (
    searchCriteria: Partial<SearchCriteria>,
    hasNoLoadedSavedSearch: boolean
  ) => {
    const newRecordType = getRecordTypeOnBound(
      searchCriteria.currentBounds as Bounds
    );

    if (prevRecordType !== newRecordType) {
      resetLayerStylingStates();
      resetDefaultLayersByRecordType(newRecordType);
    }

    updateUoMValueOnSearch(UoMValueOnSelection);
    updateUoMAlertState(undefined);

    updateExtentInfo({
      isLoading: true,
      data: [],
      error: "",
    });

    if (newRecordType && !isPermitRecord(newRecordType)) {
      updateActivePanel(SIDEBAR_MENU_KEYS.DEFAULT_CHARTS);
    }

    updateInitialGridSearchMade(true);
    const newSearchCriteria = {
      ...searchCriteria,
      offset: 0,
      filters: [],
    };

    resetSortFilterModels();
    toggleGrid(true);
    toggleGridHeader(true);
    toggleIsHighlightSelectedSpots(true);
    updateSearchCriteria(newSearchCriteria);
    resetAllMapSelections();
    updateIsSearchCriteriaChange(false);
    updateSortByAnalysis(false);
    updateGridWellDataTrigger("searchPanel");
    resetWellCard();
    resetWellList();
    resetPermitList();

    if (hasNoLoadedSavedSearch) return;
    resetSelectedSavedSearch();
  };

  // This is triggered by the save dialog
  const saveCallback = () => {
    let hasLoadedSavedSearch = false;
    if (activeSearchPanelTab === ATTRIBUTES_TAB) {
      updateLastSearchedBy(SEARCH_TYPES.ATTRIBUTE_SEARCH);
      resetUWIStates();
      hasLoadedSavedSearch = hasLoadedUWISavedSearch;
    } else {
      updateLastSearchedBy(uwiSearchType);
      resetAttribsPolygons();
      hasLoadedSavedSearch = hasLoadedAttribSavedSearch;
    }

    // if saving with a loaded saved search and has a different search type
    // Do partial reset
    if (!hasLoadedSavedSearch) return;

    setDVTUpdated(false);
    resetSelectedSavedSearch();
    resetAllMapSelections();
    resetSortFilterModels();
    resetSearchCriteria();
    resetGridStates();
    resetExportStates();
  };

  const resetCallback = (hasLoadedSavedSearch: boolean) => {
    if (hasLoadedSavedSearch) {
      resetSelectedSavedSearch();
    }
    updateGridWellDataTrigger("");
    toggleIsOpenQBEditorPanel(false);
    updateLastSearchedBy("");
    resetSearchCriteria();
    resetDefaultGridModels();
    resetMapViewBaseMap();
    resetMapOverlaySlice();
    resetLayerStylingStates();
    resetCarbonStorageSlice();
    resetStratModelsSlice();
    resetMapStates();
    resetAllMapSelections();
    resetExportStates();
    resetGridStates();
    resetWellCard();
    updateIsQBUpdated(false);
    resetWellList();
  };

  return {
    searchCallback,
    saveCallback,
    resetCallback,
    resetPolygons,
    resetAttribsPolygons,
    hasLoadedAttribSavedSearch,
    hasLoadedUWISavedSearch,
    uwiSearchType,
  };
};

export default useSearchPanelActions;
