import { PropsWithChildren, useState } from "react";

import {
  FormControl,
  InputLabel,
  Select,
  SelectChangeEvent,
  Tooltip,
} from "@mui/material";

interface CustomDropdownWithTooltipProps {
  label: string;
  tooltipLabel?: string;
  defaultValue?: string;
  value: string;
  onChange: (event: SelectChangeEvent<string>, child: React.ReactNode) => void;
}

const CustomDropdownWithTooltip = ({
  label,
  tooltipLabel,
  defaultValue,
  value,
  onChange,
  children,
}: PropsWithChildren<CustomDropdownWithTooltipProps>) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  return (
    <Tooltip title={tooltipLabel ?? value} open={tooltipOpen}>
      <FormControl className="custom-select">
        <InputLabel>{label}</InputLabel>
        <Select
          label={label}
          defaultValue={defaultValue}
          value={value}
          onChange={onChange}
          onMouseEnter={() => setTooltipOpen(true)}
          onMouseLeave={() => setTooltipOpen(false)}
          onOpen={() => setTooltipOpen(false)}
        >
          {children}
        </Select>
      </FormControl>
    </Tooltip>
  );
};

export default CustomDropdownWithTooltip;
