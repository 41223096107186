import { FC, SyntheticEvent } from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";

import {
  ReleaseVersionsProps,
  ReleaseVersionsTypes,
} from "../../../../types/information/information";

import {
  AccordionContent,
  AccordionHeadingText,
  AccordionImage,
  AccordionSubContent,
  CustomTypography,
} from "../StyledAccordions";

import CashflowEnhancement from "../../../../assets/images/release-notes/CashflowEnhancement.png";

const Version20250130: FC<ReleaseVersionsProps> = ({ expanded, onChange }) => {
  const handleChange =
    (panel: ReleaseVersionsTypes) =>
    (_: SyntheticEvent, isExpanded: boolean) => {
      onChange(isExpanded ? panel : "");
    };

  return (
    <Accordion
      expanded={expanded === "Version20250130"}
      onChange={handleChange("Version20250130")}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <AccordionHeadingText sx={{ width: "80%", flexShrink: 0 }}>
          Version 2025.01.30
        </AccordionHeadingText>
        <AccordionHeadingText sx={{ color: "rgba(255, 255, 255, 0.70)" }}>
          01/30/2025
        </AccordionHeadingText>
      </AccordionSummary>
      <AccordionDetails>
        <CustomTypography>
          This release introduces Cashflow chart export options
        </CustomTypography>
      </AccordionDetails>
      <AccordionContent>• Cash Flow Chart Enhancements</AccordionContent>
      <AccordionSubContent>
        • Users can now view Cash Flow charts for a single well, a selected
        group of wells, or an entire asset through the CHARTS section in the
        Well Panel or under ANALYZE {`>`} CASH FLOW CHART.
      </AccordionSubContent>
      <AccordionSubContent>
        • Charts can be exported as either an image or an Excel file for easy
        sharing and analysis.
      </AccordionSubContent>
      <AccordionSubContent>
        • The chart displays key financial data, including:
      </AccordionSubContent>
      <AccordionSubContent>
        <AccordionSubContent>
          ▪ Monthly Total Revenue (Unhedged), $ <br />
          ▪ Monthly Total Operating Costs, $ <br />
          ▪ Monthly Asset-Level FCF, $ <br />
        </AccordionSubContent>
      </AccordionSubContent>
      <AccordionImage
        src={CashflowEnhancement}
        width="100%"
        draggable={false}
      />
    </Accordion>
  );
};

export default Version20250130;
