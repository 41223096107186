import { getDefaultAttributeInfo } from "../../../../utils/search/store";

import * as constants from "../../../attributes";
import { EQ, IN } from "./opTypes";

// FOR STORE USE ONLY
export const defaultAvailableQBAttributes = {
  [constants.HEADER]: [
    { ...constants.COUNTRY, isSelected: true },
    { ...constants.STATE_NAME, isSelected: true },
    { ...constants.COUNTY, isSelected: true },
    { ...constants.WELL_NAME, isSelected: false },
    { ...constants.WELL_NUMBER, isSelected: false },
    { ...constants.FIELD_NAME, isSelected: true },
    { ...constants.BASIN_NAME, isSelected: true },
    { ...constants.DISPLAY_FORMATION, isSelected: true },
    { ...constants.INTERPRETED_PRODUCING_FORMATION, isSelected: false },
    { ...constants.OPERATOR_NAME, isSelected: false },
    { ...constants.WELL_STATUS, isSelected: false },
    { ...constants.WELL_TYPE, isSelected: false },
    { ...constants.MEASURED_DEPTH, isSelected: false },
    { ...constants.TOTAL_VERTICAL_DEPTH, isSelected: false },
    { ...constants.SLANT, isSelected: false },
    { ...constants.LAST_INJECTION_TYPE, isSelected: false },
    { ...constants.LAST_INJECTION_FORMATION, isSelected: false },
    { ...constants.ULTIMATE_OWNER, isSelected: true },
    { ...constants.LEASE_NAME, isSelected: false },
    { ...constants.STATE_LEASE_ID, isSelected: false },
    { ...constants.LEASE_NAME_AND_ID, isSelected: false },
  ],
  [constants.WELL_SPACING]: [
    { ...constants.CLOSEST_HORIZONTAL_DISTANCE_IN_ZONE, isSelected: false },
    { ...constants.CLOSEST_HORIZONTAL_DISTANCE, isSelected: false },
    { ...constants.CLOSEST_VERTICAL_DISTANCE, isSelected: false },
    { ...constants.COUNT_OF_CHILD_WELLS, isSelected: false },
    { ...constants.COUNT_OF_PARENT_WELLS, isSelected: false },
    { ...constants.COUNT_OF_SIBLING_WELLS, isSelected: false },
  ],
  [constants.WELL_FLAG]: [
    { ...constants.HAS_PRODUCTION, isSelected: false },
    { ...constants.HAS_FORECAST, isSelected: false },
    { ...constants.TEST, isSelected: false },
    { ...constants.HAS_LAS, isSelected: false },
    { ...constants.HAS_RASTER, isSelected: false },
    { ...constants.HAS_WELL_FILE, isSelected: false },
    { ...constants.PERF, isSelected: false },
    { ...constants.HAS_INJECTION, isSelected: false },
    { ...constants.HAS_VENT_FLARE, isSelected: false },
    { ...constants.OFFSHORE_FLG, isSelected: false },
    { ...constants.HAS_WELL_ECONOMICS, isSelected: false },
    { ...constants.HAS_DST, isSelected: false },
    { ...constants.HAS_WFT, isSelected: false },
  ],
  [constants.DATES]: [
    { ...constants.SPUD_DATE, isSelected: false },
    { ...constants.TD_DATE, isSelected: false },
    { ...constants.COMPLETION_DATE, isSelected: false },
    { ...constants.FIRST_MONTH, isSelected: true },
    { ...constants.LAST_MONTH, isSelected: false },
    { ...constants.PLUG_DATE, isSelected: false },
  ],
  [constants.SURFACE_LOCATION]: [
    { ...constants.SECTION, isSelected: false },
    { ...constants.TOWNSHIP, isSelected: false },
    { ...constants.TOWNSHIP_DIRECTION, isSelected: false },
    { ...constants.RANGE, isSelected: false },
    { ...constants.RANGE_DIRECTION, isSelected: false },
    { ...constants.DISTRICT, isSelected: false },
    { ...constants.AREA, isSelected: false },
    { ...constants.OFFSHORE_BLOCK, isSelected: false },
  ],
  [constants.COMPLETION_DATA]: [
    { ...constants.PERF_INTERVAL_TOP, isSelected: false },
    { ...constants.PERF_INTERVAL_BOTTOM, isSelected: false },
    { ...constants.LATERAL_LENGTH, isSelected: false },
    { ...constants.NUMBER_OF_STAGES, isSelected: false },
    { ...constants.FRAC_FLUID_AMOUNT, isSelected: false },
    { ...constants.PROPPANT_AMOUNT, isSelected: false },
    { ...constants.MAX_TREATMENT_RATE, isSelected: false },
    { ...constants.MAX_TREATMENT_PRESSURE, isSelected: false },
    { ...constants.ACID_AMOUNT, isSelected: false },
    { ...constants.PROPPANT_AMOUNT_PER_FT, isSelected: false },
    { ...constants.FRAC_FLUID_AMOUNT_PER_FT, isSelected: false },
  ],
  [constants.WELL_ECONOMICS]: [
    { ...constants.LOE_COST, isSelected: false },
    { ...constants.GPT_COST, isSelected: false },
    { ...constants.WATER_COST, isSelected: false },
    { ...constants.FIXED_COST, isSelected: false },
    { ...constants.DRILLING_COMPLETION_CAP_EX, isSelected: false },
    { ...constants.INFRA_CAP_EX, isSelected: false },
    { ...constants.WORKING_INTEREST, isSelected: false },
    { ...constants.ROYALTY, isSelected: false },
    { ...constants.INTERNAL_RATE_OF_RETURN, isSelected: false },
    { ...constants.BREAK_EVEN_PRICE, isSelected: false },
    { ...constants.NET_PRESENT_VALUE, isSelected: false },
    { ...constants.PAYOUT, isSelected: false },
  ],
  [constants.PERMITS]: [
    { ...constants.PERMIT_NUMBER, isSelected: false },
    { ...constants.PERMIT_APPROVAL_DATE, isSelected: false },
    { ...constants.PERMIT_POSTED_DATE, isSelected: false },
    { ...constants.PERMIT_TOTAL_DEPTH, isSelected: false },
    { ...constants.PERMIT_FORMATION_DEPTH, isSelected: false },
    { ...constants.PERMIT_HAS_H2S, isSelected: false },
  ],
  [constants.STATISTICS]: [
    { ...constants.CUM_BOE, isSelected: false },
    { ...constants.CUM_GAS, isSelected: false },
    { ...constants.CUM_OIL, isSelected: false },
    { ...constants.CUM_WATER, isSelected: false },
    { ...constants.CUM_INJECTION_LIQUID, isSelected: false },
    { ...constants.CUM_INJECTION_GAS, isSelected: false },
    { ...constants.WELL_FORECAST_BOE_ULTIMATE, isSelected: false },
    { ...constants.EUR_PER_FT, isSelected: false },
    { ...constants.WELL_FORECAST_GAS_ULTIMATE, isSelected: false },
    { ...constants.WELL_FORECAST_OIL_ULTIMATE, isSelected: false },
    { ...constants.WELL_FORECAST_WATER_ULTIMATE, isSelected: false },
    { ...constants.MAX_BOE, isSelected: false },
    { ...constants.BOE_IP90, isSelected: false },
    { ...constants.MAX_GAS, isSelected: false },
    { ...constants.MAX_GAS_PLUS_2, isSelected: false },
    { ...constants.MAX_OIL, isSelected: false },
    { ...constants.MAX_OIL_PLUS_2, isSelected: false },
    { ...constants.MAX_WATER, isSelected: false },
    { ...constants.MAX_WATER_PLUS_2, isSelected: false },
    { ...constants.WATER_CUT_TOTAL_CUM, isSelected: false },
    { ...constants.WATER_OIL_RATIO_TOTAL_CUM, isSelected: false },
    { ...constants.CUM_GOR, isSelected: false },
    { ...constants.OIL_EUR_PER_FT, isSelected: false },
    { ...constants.GAS_EUR_PER_FT, isSelected: false },
    { ...constants.OIL_CUM_PER_FT, isSelected: false },
    { ...constants.GAS_CUM_PER_FT, isSelected: false },
    { ...constants.WATER_CUM_PER_FT, isSelected: false },
    { ...constants.BOE_CUM_PER_FT, isSelected: false },
    { ...constants.RECENT_1MO_OIL, isSelected: false },
    { ...constants.RECENT_1MO_GAS, isSelected: false },
    { ...constants.RECENT_1MO_WATER, isSelected: false },
    { ...constants.RECENT_6MO_OIL, isSelected: false },
    { ...constants.RECENT_6MO_GAS, isSelected: false },
    { ...constants.RECENT_6MO_WATER, isSelected: false },
    { ...constants.RECENT_1YR_OIL, isSelected: false },
    { ...constants.RECENT_1YR_GAS, isSelected: false },
    { ...constants.RECENT_1YR_WATER, isSelected: false },
  ],
  [constants.ID]: [],
} as const;

export const defaultQBList = [
  constants.RECORD_TYPE,
  constants.COUNTRY,
  constants.STATE_NAME,
  constants.COUNTY,
  constants.BASIN_NAME,
  constants.FIELD_NAME,
  constants.ULTIMATE_OWNER,
  constants.DISPLAY_FORMATION,
  constants.FIRST_MONTH,
] as const;

const defaultVarcharInfo = {
  options: [],
  operationType: IN.key,
  values: [],
  input: "",
  hasError: false,
  isUpdated: false,
} as const;

const defaultDateInfo = {
  ...defaultVarcharInfo,
  operationType: "",
} as const;

export const initialOperationType = {
  [constants.TEXT]: IN.key,
  [constants.TEXT_SELECT]: IN.key,
  [constants.FLOAT]: "",
  [constants.INTEGER]: "",
  [constants.BOOLEAN]: EQ.key,
  [constants.DATE]: "",
  [constants.SPECIAL]: IN.key,
} as const;

export const defaultQBInfo = {
  [constants.RECORD_TYPE.key]: getDefaultAttributeInfo(constants.RECORD_TYPE),
  [constants.COUNTRY.key]: defaultVarcharInfo,
  [constants.STATE_NAME.key]: defaultVarcharInfo,
  [constants.COUNTY.key]: defaultVarcharInfo,
  [constants.BASIN_NAME.key]: defaultVarcharInfo,
  [constants.FIELD_NAME.key]: defaultVarcharInfo,
  [constants.ULTIMATE_OWNER.key]: defaultVarcharInfo,
  [constants.DISPLAY_FORMATION.key]: defaultVarcharInfo,
  [constants.FIRST_MONTH.key]: defaultDateInfo,
} as const;

export const QB_REQUIRED_ATTRIBUTES = [constants.RECORD_TYPE.key];
