import * as attribute from "../attributes";

export const WELL_ECONOMICS_FILTER_BY_ATTRIBUTE = [
  { name: attribute.COUNTRY.key, displayName: attribute.COUNTRY.label },
  { name: attribute.STATE_NAME.key, displayName: attribute.STATE_NAME.label },
  { name: attribute.COUNTY.key, displayName: attribute.COUNTY.label },
  { name: attribute.FIELD_NAME.key, displayName: attribute.FIELD_NAME.label },
  { name: attribute.BASIN_NAME.key, displayName: attribute.BASIN_NAME.label },
  {
    name: attribute.DISPLAY_FORMATION.key,
    displayName: attribute.DISPLAY_FORMATION.label,
  },
  {
    name: attribute.INTERPRETED_PRODUCING_FORMATION.key,
    displayName: attribute.INTERPRETED_PRODUCING_FORMATION.label,
  },
  {
    name: attribute.ULTIMATE_OWNER.key,
    displayName: attribute.ULTIMATE_OWNER.label,
  },
  { name: attribute.LEASE_NAME.key, displayName: attribute.LEASE_NAME.label },
  {
    name: attribute.LEASE_NAME_AND_ID.key,
    displayName: attribute.LEASE_NAME_AND_ID.label,
  },
  { name: attribute.WELL_TYPE.key, displayName: attribute.WELL_TYPE.label },
  { name: attribute.SLANT.key, displayName: attribute.SLANT.label },
];
