import {
  AxisRange,
  ChartRange,
  ITraceData,
} from "../../types/charts/chartType/chartType";

import { getMinMaxTicks } from "./layouts/CashFlowLayout";

export const getChartRangeProperties = (
  layout: { [key: string]: number },
  currentChartRange?: ChartRange
): ChartRange => {
  const xAxisLower = layout["xaxis.range[0]"];
  const xAxisUpper = layout["xaxis.range[1]"];
  const yAxisLower = layout["yaxis.range[0]"];
  const yAxisUpper = layout["yaxis.range[1]"];
  const yAxis2Lower = layout["yaxis2.range[0]"];
  const yAxis2Upper = layout["yaxis2.range[1]"];
  const xLegend = layout["legend.x"];
  const yLegend = layout["legend.y"];

  let mappedXRange: AxisRange = [undefined, undefined];
  if (xAxisLower && xAxisUpper) {
    mappedXRange = [xAxisLower, xAxisUpper];
  } else if (currentChartRange?.xRange) {
    mappedXRange = currentChartRange?.xRange;
  }

  let mappedYRange: AxisRange = [undefined, undefined];
  if (yAxisLower && yAxisUpper) {
    mappedYRange = [yAxisLower, yAxisUpper];
  } else if (currentChartRange?.xRange) {
    mappedYRange = currentChartRange?.yRange;
  }

  let mappedY2Range: AxisRange | undefined = [undefined, undefined];
  if (yAxis2Lower && yAxis2Upper) {
    mappedY2Range = [yAxis2Lower, yAxis2Upper];
  } else if (currentChartRange?.xRange) {
    mappedY2Range = currentChartRange?.yRange2;
  }

  let mappedXLegend = undefined;
  if (xLegend) {
    mappedXLegend = xLegend;
  } else if (currentChartRange?.xLegend) {
    mappedXLegend = currentChartRange?.xLegend;
  }

  let mappedYLegend = undefined;
  if (yLegend) {
    mappedYLegend = yLegend;
  } else if (currentChartRange?.yLegend) {
    mappedYLegend = currentChartRange?.yLegend;
  }

  return {
    xRange: mappedXRange,
    yRange: mappedYRange,
    autorange: false,
    ...(mappedY2Range && { yRange2: mappedY2Range }),
    ...(mappedXLegend && { xLegend: mappedXLegend }),
    ...(mappedYLegend && { yLegend: mappedYLegend }),
  };
};

// Currently used for setting a default chart range
// Used by Cash Flow Chart
export const getMinMaxChartRangeProperties = (
  chartData: ITraceData[],
  chartRange?: ChartRange
) => {
  const { min, max } = getMinMaxTicks(chartData);
  const layout = { "yaxis.range[0]": min, "yaxis.range[1]": max };
  return getChartRangeProperties(layout, chartRange);
};

export const isResettingChartRange = (layout: { [key: string]: number }) => {
  return layout["xaxis.autorange"] && layout["yaxis.autorange"];
};
