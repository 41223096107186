import { Data } from "plotly.js";

import { ITraceData } from "../../types/charts/chartType/chartType";

import { MONTHS_NUMERIC_TO_LABEL } from "../../constants/charts/charts";

export const formatCashflowExport = (data: Data[]) => {
  const trace = data as ITraceData[];
  const formattedChartData: { [key: string]: string }[] = [];
  const monthsHeader: { [key: string]: string } = { "": "" };

  trace.forEach((val, traceIndex) => {
    const row: { [key: string]: string } = {
      "": val.name,
    };
    const total = val.x.length;
    let monthCount = -2;

    val.x.forEach((col, index) => {
      const date = new Date(col);
      const month = date.getMonth() + 1;

      if (!traceIndex) {
        [...Array(total).keys()].forEach((num) => {
          monthsHeader[`Month ${monthCount}`] = `${
            MONTHS_NUMERIC_TO_LABEL[month.toString()]
          }-${date.getFullYear()}`;
        });
      }

      row[`Month ${monthCount}`] = val.y[index] ?? "";

      monthCount++;
    });

    formattedChartData.push(row);
  });

  formattedChartData.unshift(monthsHeader);

  return formattedChartData;
};
