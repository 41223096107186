import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";

import {
  GridFilterItem,
  GridFilterModel,
  GridLogicOperator,
  gridColumnMenuSelector,
  useGridSelector,
} from "@mui/x-data-grid-premium";
import { GridApiPremium } from "@mui/x-data-grid-premium/models/gridApiPremium";

import { isEqual, sortBy } from "lodash";

import { FilterCheckboxAttribute, FilterCheckboxList } from "../../types/grid";

import { OIL_B_FACTOR } from "../../constants/attributes";
import {
  FILTER_BLANK_TEXT_DISPLAY,
  FILTER_CHECKBOX_OPERATORS,
  FILTER_IS_EMPTY_OPERATOR,
  FILTER_TYPE_CHECKLIST,
  IS_ANY_OF,
  IS_EMPTY,
} from "../../constants/grid";

import useDataGridStore from "../../store/grid/dataGridStore";

import { changeKey, fieldFormattedDisplay } from "../../utils/datagrid";
import {
  formatAttributeByKey,
  formatBfactor,
} from "../../utils/formatters/attributeFormatter";

import { clone, generate5DigitUniqueRandomNumber } from "../../utils";
import useAttributeCountData from "../common/useAttributeCountData";
import useUnitOfMeasure from "../common/useUnitOfMeasure";
import usePrevious from "../usePrevious";

const useFilterCheckbox = (apiRef: React.MutableRefObject<GridApiPremium>) => {
  const [searchText, setSearchText] = useState("");
  const defaultFilterItem = {
    field: "",
    operator: "",
  };
  const [allList, setAllList] = useState<FilterCheckboxList[]>([]);
  const [checkboxLists, setCheckboxList] = useState<FilterCheckboxList[]>([]);
  const [checked, setChecked] = useState<FilterCheckboxList[]>([]);
  const [disableFilterButton, setDisableFilterButton] = useState<boolean>(true);
  const [disableClearButton, setDisableClearButton] = useState<boolean>(true);
  const [allSelected, setAllSelected] = useState<boolean>(false);

  const { isLoading, success, getAttributeCount } = useAttributeCountData();
  const { isMetricOnSearch } = useUnitOfMeasure();

  const [filterItem, setFilterItem] =
    useState<GridFilterItem>(defaultFilterItem);
  const filterModel = useDataGridStore((state) => state.filterModel);
  const allSelectedFilterAttributes = useDataGridStore(
    (state) => state.allSelectedFilterAttributes
  );
  const updateAllSelectedFilterAttributes = useDataGridStore(
    (state) => state.updateAllSelectedFilterAttributes
  );
  const searchCriteria = useDataGridStore((state) => state.searchCriteria);
  const columnFilterAttributes = useDataGridStore(
    (state) => state.columnFilterAttributes
  );
  const resetFilterAttribute = useDataGridStore(
    (state) => state.resetFilterAttribute
  );
  const updateColumnsFilterType = useDataGridStore(
    (state) => state.updateColumnsFilterType
  );
  const columnsFilterType = useDataGridStore(
    (state) => state.columnsFilterType
  );
  const openColumnMenuInfo = useGridSelector(apiRef, gridColumnMenuSelector);
  const openColumnFieldPrevious = usePrevious(openColumnMenuInfo.field);

  const bFactorFormatting = useMemo(() => {
    return openColumnMenuInfo?.field?.toLowerCase() ===
      OIL_B_FACTOR.key.toLowerCase()
      ? formatBfactor
      : undefined;
  }, [openColumnMenuInfo.field]);

  const filterLists = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value: keyword } = event.target as
      | HTMLInputElement
      | HTMLTextAreaElement;

    if (keyword.toString() !== "") {
      const filtered =
        allList?.filter((col) => {
          //for rounded displays, will round off raw values before searching from raw list
          //key value list are using raw values, not formatted displays
          if (
            openColumnMenuInfo.field &&
            fieldFormattedDisplay.includes(openColumnMenuInfo.field) &&
            !isNaN(parseFloat(col.raw)) //check if string is convertable to number
          ) {
            return formatAttributeByKey(
              openColumnMenuInfo.field,
              col.raw,
              true,
              false,
              false,
              bFactorFormatting
            )
              ?.toString()
              .toLowerCase()
              .includes(keyword.toString().toLowerCase());
          }

          return col.raw
            .toLowerCase()
            .includes(keyword.toString().toLowerCase());
        }) ?? [];

      setCheckboxList(filtered);
      setSearchText(keyword.toString());
    } else {
      setCheckboxList(allList);
      setSearchText("");
    }
  };

  const handleSelectAll = (action = "select-all") => {
    if (action === "select-all") {
      const mappedList = checkboxLists.map((check) =>
        check.raw === FILTER_BLANK_TEXT_DISPLAY
          ? {
              raw: FILTER_BLANK_TEXT_DISPLAY,
              displayValue: FILTER_IS_EMPTY_OPERATOR.label,
            }
          : {
              raw: check.raw,
              displayValue: check.raw,
            }
      );
      setChecked(mappedList);
      setAllSelected(true);

      if (allList.length === mappedList.length) {
        //check if field exists in array, to avoid duplicate
        if (
          openColumnMenuInfo.field &&
          !allSelectedFilterAttributes.includes(openColumnMenuInfo.field)
        ) {
          const fields = allSelectedFilterAttributes;

          fields.push(openColumnMenuInfo.field);
          updateAllSelectedFilterAttributes(fields);
        }
      }
    } else if (action === "deselect-all") {
      setChecked([]);
      setAllSelected(false);

      const newSetOfAllSelectedAttributes = allSelectedFilterAttributes.filter(
        (att) => att !== openColumnMenuInfo.field
      );
      updateAllSelectedFilterAttributes(newSetOfAllSelectedAttributes);
    }
  };

  const handleCheckBoxToggle = (value: FilterCheckboxList[] = []) => {
    if (value.length) {
      const newChecked = [...checked];
      value.forEach((val) => {
        const currentIndex = newChecked.findIndex(
          (checked) => checked.raw === val.raw
        );

        if (currentIndex === -1 && openColumnMenuInfo.field) {
          newChecked.push(val);

          if (newChecked.length === checkboxLists.length) {
            setAllSelected(true);

            //check if:
            //(1) field exists in array, to avoid duplicate
            //(2) value is not checked on search filter
            if (
              !allSelectedFilterAttributes.includes(openColumnMenuInfo.field) &&
              searchText === ""
            ) {
              const fields = allSelectedFilterAttributes;

              fields.push(openColumnMenuInfo.field);
              updateAllSelectedFilterAttributes(fields);
            }
          } else {
            if (checked.length === checkboxLists.length) {
              setAllSelected(false);

              if (searchText === "") {
                const newSetOfAllSelectedAttributes =
                  allSelectedFilterAttributes.filter(
                    (att) => att !== openColumnMenuInfo.field
                  );
                updateAllSelectedFilterAttributes(
                  newSetOfAllSelectedAttributes
                );
              }
            }
          }
        } else {
          if (checked.length === checkboxLists.length) {
            setAllSelected(false);

            const newSetOfAllSelectedAttributes =
              allSelectedFilterAttributes.filter(
                (att) => att !== openColumnMenuInfo.field
              );
            updateAllSelectedFilterAttributes(newSetOfAllSelectedAttributes);
          }

          newChecked.splice(currentIndex, 1);
        }
      });
      setChecked(newChecked);
    }
  };

  const handleClearFilter = () => {
    if (filterModel.items.length) {
      const newFilterItems: GridFilterItem[] = filterModel.items.filter(
        (item) => item.field !== openColumnMenuInfo.field
      );

      if (openColumnMenuInfo.field)
        updateColumnsFilterType(
          openColumnMenuInfo.field,
          FILTER_TYPE_CHECKLIST
        );
      apiRef.current.setFilterModel({
        items: newFilterItems,
        logicOperator: filterModel.logicOperator,
      });

      setChecked([]);
      setAllSelected(false);
      setFilterItem(defaultFilterItem);
      setDisableClearButton(true);
      setDisableFilterButton(true);

      const newSetOfAllSelectedAttributes = allSelectedFilterAttributes.filter(
        (att) => att !== openColumnMenuInfo.field
      );
      updateAllSelectedFilterAttributes(newSetOfAllSelectedAttributes);

      if (openColumnMenuInfo.field)
        resetFilterAttribute(openColumnMenuInfo.field);
    }

    apiRef.current.hideColumnMenu();
  };

  const getAttributeNamesByOpenField = (
    attributes: FilterCheckboxAttribute,
    field: string
  ): FilterCheckboxList[] => {
    return attributes[field]?.map((val) => {
      if (val) {
        if (
          fieldFormattedDisplay.includes(field) &&
          !isNaN(parseFloat(val.attributeValue))
        ) {
          return {
            raw: val.attributeValue,
            displayValue:
              formatAttributeByKey(
                field,
                parseFloat(val.attributeValue),
                true,
                false,
                false,
                bFactorFormatting
              )
                ?.toString()
                .toLowerCase() ?? "",
          };
        } else {
          return {
            raw: val.attributeValue,
            displayValue: val.attributeValue,
          };
        }
      } else {
        return {
          raw: "",
          displayValue: "",
        };
      }
    });
  };

  const handleResetSearchValue = useCallback(() => {
    setSearchText("");
    setCheckboxList(allList);
  }, [setSearchText, setCheckboxList, allList]);

  const applyFilter = () => {
    const hasEmptyFilterChecked = Boolean(
      checked.find((val) => val.displayValue === FILTER_IS_EMPTY_OPERATOR.label)
    );
    //remove all filters associated with the current field
    const newFilterItems = filterModel.items.filter(
      (item) => item.field !== openColumnMenuInfo.field
    );

    const flatChecked = checked.map((c) => c.raw);

    if (flatChecked.length === 0 && filterItem) {
      //delete filter item/s in filterModel
      //this only updates the filter model in grid, will not trigger api call
      if (filterItem.value.includes(FILTER_IS_EMPTY_OPERATOR.label)) {
        apiRef.current.setFilterModel({
          items: newFilterItems,
          logicOperator: GridLogicOperator.And,
        });
      } else {
        if (filterItem.field && filterItem.id) {
          apiRef.current.deleteFilterItem(filterItem);
        }
      }

      setFilterItem(defaultFilterItem);
    } else if (flatChecked.length) {
      const notNullfilterChecked = flatChecked.filter(
        (check) => check !== FILTER_BLANK_TEXT_DISPLAY
      );
      const newFilterItem: GridFilterItem = defaultFilterItem;
      let filterIds = [];

      //Note: filter ID is important, Datagrid Premium uses this as an identifier
      //whether to add a new filter item or update a current filter item
      if (filterItem.id && filterItem.value) {
        newFilterItem.id = filterItem.id;
        newFilterItem.field = filterItem.field;
        newFilterItem.operator = notNullfilterChecked.length
          ? IS_ANY_OF
          : IS_EMPTY;

        if (newFilterItem.operator === IS_ANY_OF) {
          newFilterItem.value = notNullfilterChecked.length
            ? notNullfilterChecked
            : checked;
        }

        setFilterItem((prevState) => ({
          ...prevState,
          value: flatChecked,
        }));
      } else {
        filterIds = filterModel.items.map((value) => value.id);
        const newFilterId = generate5DigitUniqueRandomNumber(filterIds);
        newFilterItem.id = newFilterId;
        newFilterItem.field = openColumnMenuInfo.field ?? "";
        newFilterItem.operator = notNullfilterChecked.length
          ? IS_ANY_OF
          : IS_EMPTY;

        if (newFilterItem.operator === IS_ANY_OF) {
          newFilterItem.value = notNullfilterChecked.length
            ? notNullfilterChecked
            : flatChecked;
        }

        setFilterItem({
          id: newFilterId,
          field: openColumnMenuInfo.field ?? "",
          operator: IS_ANY_OF,
          value: flatChecked,
        });
      }

      newFilterItems.push(newFilterItem);

      //add is empty operator in the new filterModel
      if (hasEmptyFilterChecked && notNullfilterChecked.length) {
        const isEmptyFilterItem = filterModel.items.filter(
          (item) =>
            item.field === openColumnMenuInfo.field &&
            item.operator === FILTER_IS_EMPTY_OPERATOR.key
        );

        //we only have one isEmpty filter item for checkbox
        if (isEmptyFilterItem[0]?.id) {
          newFilterItems.push({
            id: isEmptyFilterItem[0]?.id,
            field: openColumnMenuInfo.field ?? "",
            operator: FILTER_IS_EMPTY_OPERATOR.key,
          });
        } else {
          newFilterItems.push({
            field: openColumnMenuInfo.field ?? "",
            operator: FILTER_IS_EMPTY_OPERATOR.key,
          });
        }

        apiRef.current.upsertFilterItems(newFilterItems);
      } else {
        apiRef.current.upsertFilterItems(newFilterItems);
      }
    }

    //reset the filter attributes
    if (openColumnMenuInfo.field)
      resetFilterAttribute(openColumnMenuInfo.field);
    if (openColumnMenuInfo.field)
      updateColumnsFilterType(openColumnMenuInfo.field, FILTER_TYPE_CHECKLIST);

    apiRef.current.hideColumnMenu();
  };

  const autoSelectFilters = useCallback(
    (attributes: FilterCheckboxList[]) => {
      const copiedFilterModel = clone(filterModel) as GridFilterModel;
      const filterItemModel = copiedFilterModel.items.find(
        (item) =>
          item.field === openColumnMenuInfo.field &&
          FILTER_CHECKBOX_OPERATORS.includes(item.operator)
      );

      const isEmptyItem = copiedFilterModel.items.find(
        (item) =>
          item.field === openColumnMenuInfo.field && item.operator === IS_EMPTY
      );

      if (filterItemModel?.value) {
        setFilterItem({
          ...filterItemModel,
          value: filterItemModel.value.map((filter: string) =>
            filter === FILTER_BLANK_TEXT_DISPLAY
              ? FILTER_IS_EMPTY_OPERATOR.label
              : filter
          ),
        });
        setChecked([
          ...filterItemModel.value.map((val: string) => ({
            displayValue: val,
            raw: val,
          })),
          ...(isEmptyItem
            ? [
                {
                  displayValue: FILTER_IS_EMPTY_OPERATOR.label,
                  raw: FILTER_BLANK_TEXT_DISPLAY,
                },
              ]
            : []),
        ]);
        setDisableClearButton(false);

        const filterItemTotalCount = isEmptyItem
          ? filterItemModel.value.length + 1
          : filterItemModel.value.length;

        if (filterItemTotalCount === attributes.length) setAllSelected(true);
      } else if (isEmptyItem) {
        setFilterItem({
          ...isEmptyItem,
          value: [FILTER_IS_EMPTY_OPERATOR.label],
        });

        setChecked([
          {
            displayValue: FILTER_IS_EMPTY_OPERATOR.label,
            raw: FILTER_BLANK_TEXT_DISPLAY,
          },
        ]);

        setDisableClearButton(false);
        if (attributes.length === 1 && checkboxLists.length === 1)
          setAllSelected(true);
      }
    },
    [filterModel, openColumnMenuInfo, setAllSelected, setFilterItem, setChecked]
  );

  useEffect(() => {
    if (!isLoading && checked.length) {
      //compare if filter item/s in grid and checked item/s are equal
      if (
        filterItem?.value &&
        isEqual(sortBy(filterItem.value), sortBy(checked))
      ) {
        setDisableFilterButton(true);
      } else {
        setDisableFilterButton(false);
      }
    } else {
      if (filterItem?.value && checked.length !== filterItem.value.length) {
        setDisableFilterButton(false);
      } else {
        setDisableFilterButton(true);
      }
    }
  }, [isLoading, checked, filterItem]);

  useEffect(() => {
    if (
      openColumnMenuInfo.open &&
      openColumnMenuInfo.field !== openColumnFieldPrevious
    ) {
      //Check if attributes already exists in the state
      //and if there are existing filter applied
      //call api if not yet in state
      if (columnFilterAttributes[openColumnMenuInfo.field]?.length) {
        const attributes = getAttributeNamesByOpenField(
          columnFilterAttributes,
          openColumnMenuInfo.field
        );
        setAllList(attributes);
        setCheckboxList(attributes);

        //set selected items from filterModel, if exists
        autoSelectFilters(attributes);
      } else {
        const bounds = searchCriteria.currentBounds;
        const filters = searchCriteria.filters?.length
          ? searchCriteria.filters.filter(
              (item) => item.filter !== openColumnMenuInfo.field
            )
          : [];
        const searchedUWIs = searchCriteria.searchedUWIs ?? [];
        const searchType = changeKey(openColumnMenuInfo.field);
        const { drawnPolygons, fileId, shapeId } = searchCriteria;

        //checks if logicalOperator exists in the first index
        //if exists, remove the logicalOperator without mutating the searchCriteria.filters
        if (filters[0]?.operations[0]?.logicalOperator) {
          //sanitizedOperations should contain filter operations without the logicalOperator
          const { logicalOperator, ...sanitizedOperations } =
            filters[0].operations[0];
          const firstIndexFilterItem = {
            filter: filters[0].filter,
            operations: [sanitizedOperations],
          };
          let newFilters = [];

          if (filters.length > 1) {
            //clone searchCriteria.filters
            newFilters = [...filters];

            //remove the first index with logicalOperator
            newFilters.shift();

            //add the new item at the beginning of array
            newFilters.unshift(firstIndexFilterItem);
          } else {
            newFilters.push(firstIndexFilterItem);
          }

          getAttributeCount({
            bounds,
            searchType,
            drawnPolygons,
            forGridColumnFilter: true,
            filters: newFilters,
            searchedUWIs,
            fileId,
            shapeId,
            isMetric: isMetricOnSearch,
          });
        } else {
          getAttributeCount({
            bounds,
            searchType,
            drawnPolygons,
            forGridColumnFilter: true,
            filters,
            searchedUWIs,
            fileId,
            shapeId,
            isMetric: isMetricOnSearch,
          });
        }
      }
    }
    // Note: removed openColumnMenuInfo as dependency
    // openColumnMenuInfo context renders twice, thus results in two api calls
  }, []);

  useEffect(() => {
    if (filterModel.items?.length) {
      const filterExists = filterModel.items.some(
        (item) => item.field === openColumnMenuInfo.field
      );

      if (filterExists) {
        setDisableClearButton(false);
      } else {
        setDisableClearButton(true);
      }
    } else {
      setDisableClearButton(true);
    }
  }, [filterModel]);

  useEffect(() => {
    if (success) {
      const attributes = getAttributeNamesByOpenField(
        columnFilterAttributes,
        openColumnMenuInfo.field ?? ""
      );
      setAllList(attributes);
      setCheckboxList(attributes);

      //set selected items from filterModel, if exists
      autoSelectFilters(attributes);
    }
  }, [success]);

  useEffect(() => {
    //reset checked items
    if (
      openColumnMenuInfo.field &&
      columnsFilterType[openColumnMenuInfo.field] !== FILTER_TYPE_CHECKLIST
    ) {
      setChecked([]);

      const newSetOfAllSelectedAttributes = allSelectedFilterAttributes.filter(
        (att) => att !== openColumnMenuInfo.field
      );
      updateAllSelectedFilterAttributes(newSetOfAllSelectedAttributes);
    }
  }, [columnsFilterType]);

  return {
    isLoading,
    disableFilterButton,
    disableClearButton,
    allSelected,
    searchText,
    checkboxLists,
    checked,
    filterLists,
    handleSelectAll,
    handleCheckBoxToggle,
    handleClearFilter,
    handleResetSearchValue,
    applyFilter,
  };
};

export default useFilterCheckbox;
