import { useCallback } from "react";

import { MenuItem } from "@mui/material";

import { LAYER_NAME_TO_LABEL_MAPPER } from "../../constants/constants";
import {
  CARBON_STORAGE_FORMATIONS_BY_BASINS,
  CARBON_STORAGE_LABEL,
  ORDERED_CARBON_LAYERS,
} from "../../constants/map/carbonStorage";

import useMapStore from "../../store/map/mapStore";
import useMapSettingsStore from "../../store/map/settings/mapSettingsStore";

import AccordionSection from "../common/AccordionSection";
import CustomDropdownWithTooltip from "./CustomDropdownWithTooltip";

interface CarbonStorageAccordion {
  layerLegendCallback: (layerName: string, toAdd: boolean) => void;
}

const CarbonStorageAccordion = ({
  layerLegendCallback,
}: CarbonStorageAccordion) => {
  const layers = useMapStore((state) => state.layers);
  const toggleLayer = useMapStore((state) => state.toggleLayer);
  const carbonStorageInfo = useMapSettingsStore(
    (state) => state.carbonStorageInfo
  );
  const toggleCarbonStorageSelected = useMapSettingsStore(
    (state) => state.toggleCarbonStorageSelected
  );
  const updateCarbonStorageBasinStatus = useMapSettingsStore(
    (state) => state.updateCarbonStorageBasinStatus
  );
  const updateCarbonStorageBasinFormation = useMapSettingsStore(
    (state) => state.updateCarbonStorageBasinFormation
  );

  const getLayerDisplayLabel = useCallback((layerValue: string) => {
    return LAYER_NAME_TO_LABEL_MAPPER[layerValue];
  }, []);

  const getFormationLayers = useCallback(
    (basinName: string) =>
      layers.filter((layer) =>
        CARBON_STORAGE_FORMATIONS_BY_BASINS[basinName].includes(layer.name)
      ),
    [layers]
  );

  const handleBulkToggleLayer = useCallback(
    (newState: boolean) => {
      for (const [key, value] of Object.entries(carbonStorageInfo.basins)) {
        toggleLayer(value.formationName, value.isSelected && newState);
      }
    },
    [carbonStorageInfo]
  );

  const handleToggleLayer = (layerName: string, boolOverride?: boolean) => {
    toggleLayer(layerName, boolOverride);
  };

  const handleChange = (newFormationName: string, basinName: string) => {
    if (
      carbonStorageInfo.isSelected &&
      carbonStorageInfo.basins[basinName].isSelected
    ) {
      // remove previous formation and add new formation
      handleToggleLayer(
        carbonStorageInfo.basins[basinName].formationName,
        false
      );
      handleToggleLayer(newFormationName, true);
    }
    updateCarbonStorageBasinFormation(basinName, newFormationName);
  };

  const toggleLegend = useCallback(
    (newState: boolean, basinName?: string) => {
      let carbonStorageBasinList = carbonStorageInfo.basins;

      //if basin name is not empty then the basin itself was toggled on/off
      if (basinName) {
        carbonStorageBasinList = Object.fromEntries(
          Object.entries(carbonStorageInfo.basins).filter(
            ([key]) => key !== basinName
          )
        );
      }

      const otherBasinsEnabled = Object.values(carbonStorageBasinList).some(
        (val) => val.isSelected
      );

      const enableLegend = Boolean(
        (!basinName && newState && otherBasinsEnabled) ||
          (basinName && (otherBasinsEnabled || newState))
      );

      layerLegendCallback(CARBON_STORAGE_LABEL, enableLegend);
    },
    [carbonStorageInfo]
  );

  return (
    <AccordionSection
      title={CARBON_STORAGE_LABEL}
      className="carbon-layers-accordion"
      hasCheckbox
      hasTooltip
      expandIconEnd
      isSelected={carbonStorageInfo.isSelected}
      onSelect={() => {
        const { isSelected } = carbonStorageInfo;
        const newState = !isSelected;
        toggleCarbonStorageSelected(newState);
        handleBulkToggleLayer(newState);
        toggleLegend(newState);
      }}
    >
      {ORDERED_CARBON_LAYERS.map((basinName) => (
        <AccordionSection
          key={basinName.key}
          title={basinName.label}
          className="nested-sub-accordion"
          hasCheckbox
          hasTooltip
          expandIconEnd
          isSelected={carbonStorageInfo.basins[basinName.key].isSelected}
          onSelect={() => {
            const newState =
              !carbonStorageInfo.basins[basinName.key].isSelected;
            if (newState && !carbonStorageInfo.isSelected)
              toggleCarbonStorageSelected(true);

            handleToggleLayer(
              carbonStorageInfo.basins[basinName.key].formationName,
              newState
            );
            updateCarbonStorageBasinStatus(basinName.key, newState);
            toggleLegend(newState, basinName.key);
          }}
        >
          <CustomDropdownWithTooltip
            label="Formation Name"
            tooltipLabel={getLayerDisplayLabel(
              carbonStorageInfo.basins[basinName.key].formationName
            )}
            defaultValue={CARBON_STORAGE_FORMATIONS_BY_BASINS[basinName.key][0]}
            value={carbonStorageInfo.basins[basinName.key].formationName}
            onChange={(e) => handleChange(e.target.value, basinName.key)}
          >
            {getFormationLayers(basinName.key).map((layer) => (
              <MenuItem key={layer.name} value={layer.name}>
                {getLayerDisplayLabel(layer.name)}
              </MenuItem>
            ))}
          </CustomDropdownWithTooltip>
        </AccordionSection>
      ))}
    </AccordionSection>
  );
};

export default CarbonStorageAccordion;
