import classNames from "classnames";
import { CircularButton } from "component-library";

import { DstPressureDataWellPanel } from "../../../types/panels/wellPanel/pressureDataType";

import { DST_PRESSURE_DATA_FIELDS_DISPLAY } from "../../../constants/panels/wellPanel/pressureData";

import WellInfoText from "../../common/WellInfoText";
import HornerPlotChart from "./charts/HornerPlotChart";
import IncerementalChart from "./charts/IncerementalChart";
import InfoBannerSection from "./sections/InfoBannerSection";

interface DstPanelProps {
  panelContent?: DstPressureDataWellPanel;
  handleCloseDstPanel: () => void;
}

const DstPanel = ({ panelContent, handleCloseDstPanel }: DstPanelProps) => {
  return (
    <div className="dst-panel-container">
      {panelContent && (
        <>
          <div className="dst-info-header">
            <div className="dst-panel-controls">
              <CircularButton
                icon={"close"}
                className="dst-panel-close-button"
                onClick={handleCloseDstPanel}
              />
            </div>

            <div className="dst-info-header-text">
              <div className="dst-name">{panelContent.TestName}</div>
              <div className="dst-formation">{panelContent.Formation}</div>
            </div>
          </div>
          <div className="dst-info-container">
            {DST_PRESSURE_DATA_FIELDS_DISPLAY.map((field, index) => (
              <div
                className={classNames("dst-info-item", {
                  "full-width":
                    index === DST_PRESSURE_DATA_FIELDS_DISPLAY.length - 1,
                })}
                key={field.propertyKey}
              >
                <WellInfoText
                  label={field.label}
                  value={panelContent[field.propertyKey]}
                />
              </div>
            ))}
          </div>

          {panelContent.IncrementalChartTrace && (
            <IncerementalChart
              trace={panelContent.IncrementalChartTrace}
              xAxisTitle={"Minutes"}
            />
          )}
          {panelContent.HornerPlotChartTrace && (
            <HornerPlotChart trace={panelContent.HornerPlotChartTrace} />
          )}
          {!panelContent.IncrementalChartTrace &&
            !panelContent.HornerPlotChartTrace && (
              <InfoBannerSection panelContent={panelContent} />
            )}
        </>
      )}
    </div>
  );
};

export default DstPanel;
