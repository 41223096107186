import { Attribute } from "../../../types/common/attributes";
import { PressureDataFieldsDisplay } from "../../../types/panels/wellPanel/pressureDataType";

import * as attributes from "../../attributes";

//Pressure Data Attributes
export const DOC_TYPE: Attribute = {
  key: "DocType",
  label: "Test Type",
  dataType: attributes.TEXT,
  group: attributes.NO_GROUP,
};

export const DST_NO: Attribute = {
  key: "DstNumber",
  label: "DST_No",
  dataType: attributes.TEXT,
  group: attributes.NO_GROUP,
};

export const REPORTED_FORMATION: Attribute = {
  key: "ReportedFormation",
  label: "Reported Formation",
  dataType: attributes.TEXT,
  group: attributes.NO_GROUP,
};

export const TGS_LANDING_ZONE: Attribute = {
  ...attributes.INTERPRETED_PRODUCING_FORMATION,
  key: "TgsLandingZone",
};

export const DST_TEMP: Attribute = {
  key: "DstTemp",
  label: "Temperature",
  dataType: attributes.INTEGER,
  group: attributes.NO_GROUP,
};

export const INT_F: Attribute = {
  key: "IntF",
  label: "Top",
  dataType: attributes.INTEGER,
  group: attributes.NO_GROUP,
};

export const INT_T: Attribute = {
  key: "IntT",
  label: "Bottom",
  dataType: attributes.INTEGER,
  group: attributes.NO_GROUP,
};

export const FIRST_FLOW_DURATION: Attribute = {
  key: "FirstFlowDuration",
  label: "First Flow Duration",
  dataType: attributes.FLOAT,
  group: attributes.NO_GROUP,
};

export const FIRST_FLOW_END: Attribute = {
  key: "FirstFlowEnd",
  label: "First Flow End",
  dataType: attributes.INTEGER,
  group: attributes.NO_GROUP,
};

export const FIRST_SHUTIN_DURATION: Attribute = {
  key: "FirstShutinDuration",
  label: "First Shutin Duration",
  dataType: attributes.FLOAT,
  group: attributes.NO_GROUP,
};

export const FIRST_SHUTIN_END: Attribute = {
  key: "FirstShutinEnd",
  label: "First Shutin End",
  dataType: attributes.INTEGER,
  group: attributes.NO_GROUP,
};

export const SECOND_FLOW_DURATION: Attribute = {
  key: "SecondFlowDuration",
  label: "Second Flow Duration",
  dataType: attributes.FLOAT,
  group: attributes.NO_GROUP,
};

export const SECOND_FLOW_END: Attribute = {
  key: "SecondFlowEnd",
  label: "Second Flow End",
  dataType: attributes.INTEGER,
  group: attributes.NO_GROUP,
};

export const SECOND_SHUTIN_DURATION: Attribute = {
  key: "SecondShutinDuration",
  label: "Second Shutin Duration",
  dataType: attributes.FLOAT,
  group: attributes.NO_GROUP,
};

export const SECOND_SHUTIN_END: Attribute = {
  key: "SecondShutinEnd",
  label: "Second Shutin End",
  dataType: attributes.INTEGER,
  group: attributes.NO_GROUP,
};

export const FIRST_RECOVERY_AMT: Attribute = {
  key: "FirstRecoveryAmt",
  label: "First Recovery Amount",
  dataType: attributes.FLOAT,
  group: attributes.NO_GROUP,
};

export const FIRST_RECOVERY_AMT_UNITS: Attribute = {
  key: "FirstRecoveryAmtUnits",
  label: "First Recovery Amount Units",
  dataType: attributes.TEXT,
  group: attributes.NO_GROUP,
};

export const FIRST_RECOVERY_DESC: Attribute = {
  key: "FirstRecoveryDesc",
  label: "First Recovery Description",
  dataType: attributes.TEXT,
  group: attributes.NO_GROUP,
};

export const SECOND_RECOVERY_AMT: Attribute = {
  key: "SecondRecoveryAmt",
  label: "Second Recovery Amount",
  dataType: attributes.FLOAT,
  group: attributes.NO_GROUP,
};

export const SECOND_RECOVERY_AMT_UNITS: Attribute = {
  key: "SecondRecoveryAmtUnits",
  label: "Second Recovery Amount Units",
  dataType: attributes.TEXT,
  group: attributes.NO_GROUP,
};

export const SECOND_RECOVERY_DESC: Attribute = {
  key: "SecondRecoveryDesc",
  label: "Second Recovery Description",
  dataType: attributes.TEXT,
  group: attributes.NO_GROUP,
};

export const THIRD_RECOVERY_AMT: Attribute = {
  key: "ThirdRecoveryAmt",
  label: "Third Recovery Amount",
  dataType: attributes.FLOAT,
  group: attributes.NO_GROUP,
};

export const THIRD_RECOVERY_AMT_UNITS: Attribute = {
  key: "ThirdRecoveryAmtUnits",
  label: "Third Recovery Amount Units",
  dataType: attributes.TEXT,
  group: attributes.NO_GROUP,
};

export const THIRD_RECOVERY_DESC: Attribute = {
  key: "ThirdRecoveryDesc",
  label: "Third Recovery Description",
  dataType: attributes.TEXT,
  group: attributes.NO_GROUP,
};

export const FOURTH_RECOVERY_AMT: Attribute = {
  key: "FourthRecoveryAmt",
  label: "Fourth Recovery Amount",
  dataType: attributes.FLOAT,
  group: attributes.NO_GROUP,
};

export const FOURTH_RECOVERY_AMT_UNITS: Attribute = {
  key: "FourthRecoveryAmtUnits",
  label: "Fourth Recovery Amount Units",
  dataType: attributes.TEXT,
  group: attributes.NO_GROUP,
};

export const FOURTH_RECOVERY_DESC: Attribute = {
  key: "FourthRecoveryDesc",
  label: "Fourth Recovery Description",
  dataType: attributes.TEXT,
  group: attributes.NO_GROUP,
};

export const FIFTH_RECOVERY_AMT: Attribute = {
  key: "FifthRecoveryAmt",
  label: "Fifth Recovery Amount",
  dataType: attributes.FLOAT,
  group: attributes.NO_GROUP,
};

export const FIFTH_RECOVERY_AMT_UNITS: Attribute = {
  key: "FifthRecoveryAmtUnits",
  label: "Fifth Recovery Amount Units",
  dataType: attributes.TEXT,
  group: attributes.NO_GROUP,
};

export const FIFTH_RECOVERY_DESC: Attribute = {
  key: "FifthRecoveryDesc",
  label: "Fifth Recovery Description",
  dataType: attributes.TEXT,
  group: attributes.NO_GROUP,
};

export const SIXTH_RECOVERY_AMT: Attribute = {
  key: "SixthRecoveryAmt",
  label: "Sixth Recovery Amount",
  dataType: attributes.FLOAT,
  group: attributes.NO_GROUP,
};

export const SIXTH_RECOVERY_AMT_UNITS: Attribute = {
  key: "SixthRecoveryAmtUnits",
  label: "Sixth Recovery Amount Units",
  dataType: attributes.TEXT,
  group: attributes.NO_GROUP,
};

export const SIXTH_RECOVERY_DESC: Attribute = {
  key: "SixthRecoveryDesc",
  label: "Sixth Recovery Description",
  dataType: attributes.TEXT,
  group: attributes.NO_GROUP,
};

export const TEST_NUMBER: Attribute = {
  key: "TestNumber",
  label: "Test Number",
  dataType: attributes.TEXT,
  group: attributes.NO_GROUP,
};

export const TEST_TYPE: Attribute = {
  key: "TestType",
  label: "Test Type",
  dataType: attributes.TEXT,
  group: attributes.NO_GROUP,
};

export const INITIAL_SHUT_IN_END_TGS: Attribute = {
  key: "InitialShutInEndTgs",
  label: "Pretest Shut In",
  dataType: attributes.TEXT,
  group: attributes.NO_GROUP,
};

export const FINAL_SHUT_IN_END_TGS: Attribute = {
  key: "FinalShutInEndTgs",
  label: "Sample Shut In",
  dataType: attributes.TEXT,
  group: attributes.NO_GROUP,
};

export const TEST_DEPTH: Attribute = {
  key: "TestDepth",
  label: "Depth",
  dataType: attributes.TEXT,
  group: attributes.NO_GROUP,
};

export const SUMMARY_TEST_REMARKS: Attribute = {
  key: "SummaryTestRemarks",
  label: "Test Remarks",
  dataType: attributes.TEXT,
  group: attributes.NO_GROUP,
};

export const TOTAL_GAS_AMT: Attribute = {
  key: "TotalGasAmount",
  label: "Gas",
  dataType: attributes.TEXT,
  group: attributes.NO_GROUP,
};

export const CONDENSATE_AMT: Attribute = {
  key: "CondensateAmount",
  label: "Condensate",
  dataType: attributes.TEXT,
  group: attributes.NO_GROUP,
};

export const OIL_AMT: Attribute = {
  key: "OilAmount",
  label: "Oil",
  dataType: attributes.TEXT,
  group: attributes.NO_GROUP,
};

export const WATER_AMT: Attribute = {
  key: "WaterAmount",
  label: "Water",
  dataType: attributes.TEXT,
  group: attributes.NO_GROUP,
};

export const FORMATION: Attribute = {
  key: "Formation",
  label: "Formation",
  dataType: attributes.TEXT,
  group: attributes.NO_GROUP,
};

export const POROSITY: Attribute = {
  key: "Porosity",
  label: "Porosity",
  dataType: attributes.TEXT,
  group: attributes.NO_GROUP,
};

export const FORMATION_WATER_RESISTIVITY: Attribute = {
  key: "FormationWaterResistivity",
  label: "Rw",
  dataType: attributes.TEXT,
  group: attributes.NO_GROUP,
};

export const WATER_SATURATION: Attribute = {
  key: "WaterSaturation",
  label: "Sw",
  dataType: attributes.TEXT,
  group: attributes.NO_GROUP,
};

export const FORMATION_PRESSURE: Attribute = {
  key: "FormationPressure",
  label: "Formation Pressure",
  dataType: attributes.TEXT,
  group: attributes.NO_GROUP,
};

export const PERMEABILITY: Attribute = {
  key: "Permeability",
  label: "Permeability",
  dataType: attributes.TEXT,
  group: attributes.NO_GROUP,
};

export const SAMPLE_REMARKS: Attribute = {
  key: "SampleRemarks",
  label: "Recovery Remarks",
  dataType: attributes.TEXT,
  group: attributes.NO_GROUP,
};

export const PRESSURE_DATA_SORT_FIELDS = [DOC_TYPE.key];

export const DST_PRESSURE_DATA_FIELDS = [
  "Uwi",
  DOC_TYPE.key,
  DST_NO.key,
  REPORTED_FORMATION.key,
  TGS_LANDING_ZONE.key,
  DST_TEMP.key,
  INT_F.key,
  INT_T.key,
  FIRST_FLOW_DURATION.key,
  FIRST_FLOW_END.key,
  FIRST_SHUTIN_DURATION.key,
  FIRST_SHUTIN_END.key,
  SECOND_FLOW_DURATION.key,
  SECOND_FLOW_END.key,
  SECOND_SHUTIN_DURATION.key,
  SECOND_SHUTIN_END.key,
  FIRST_RECOVERY_AMT.key,
  FIRST_RECOVERY_AMT_UNITS.key,
  FIRST_RECOVERY_DESC.key,
  SECOND_RECOVERY_AMT.key,
  SECOND_RECOVERY_AMT_UNITS.key,
  SECOND_RECOVERY_DESC.key,
  THIRD_RECOVERY_AMT.key,
  THIRD_RECOVERY_AMT_UNITS.key,
  THIRD_RECOVERY_DESC.key,
  FOURTH_RECOVERY_AMT.key,
  FOURTH_RECOVERY_AMT_UNITS.key,
  FOURTH_RECOVERY_DESC.key,
  FIFTH_RECOVERY_AMT.key,
  FIFTH_RECOVERY_AMT_UNITS.key,
  FIFTH_RECOVERY_DESC.key,
  SIXTH_RECOVERY_AMT.key,
  SIXTH_RECOVERY_AMT_UNITS.key,
  SIXTH_RECOVERY_DESC.key,
  "Incremental",
  "HornerPlot",
];

export const DST_PRESSURE_DATA_TEST_TYPES = {
  DST: "Dst",
  DST_PLUS: "DstPlus",
} as const;

export const DST_PRESSURE_DATA_SEARCHTYPES = [
  DST_PRESSURE_DATA_TEST_TYPES.DST,
  DST_PRESSURE_DATA_TEST_TYPES.DST_PLUS,
] as const;

export const DST_PRESSURE_DATA_FIELDS_DISPLAY: PressureDataFieldsDisplay[] = [
  { label: TGS_LANDING_ZONE.label, propertyKey: "TgsLandingZone" },
  { label: DST_TEMP.label, propertyKey: "Temperature" },
  { label: INT_F.label, propertyKey: "Top" },
  { label: INT_T.label, propertyKey: "Bottom" },
  { label: "1st Flow", propertyKey: "FirstFlow" },
  { label: "1st Shut-In", propertyKey: "FirstFlowShutIn" },
  { label: "2nd Flow", propertyKey: "SecondFlow" },
  { label: "2nd Shut-In", propertyKey: "SecondFlowShutIn" },
  { label: "Recovery Remarks", propertyKey: "RecoveryRemarks" },
];

export const DST_TEST_TYPE_LABEL = "DST";

export const WFT_TEST_TYPE_LABEL = "WFT";

export const WFT_PRESSURE_DATA_FIELDS = [
  "Uwi",
  TEST_NUMBER.key,
  TEST_TYPE.key,
  INITIAL_SHUT_IN_END_TGS.key,
  FINAL_SHUT_IN_END_TGS.key,
  TEST_DEPTH.key,
  SUMMARY_TEST_REMARKS.key,
  TOTAL_GAS_AMT.key,
  CONDENSATE_AMT.key,
  OIL_AMT.key,
  WATER_AMT.key,
  FORMATION.key,
  POROSITY.key,
  FORMATION_WATER_RESISTIVITY.key,
  WATER_SATURATION.key,
  FORMATION_PRESSURE.key,
  PERMEABILITY.key,
  SAMPLE_REMARKS.key,
  "IncrementalShutIn",
];

export const WFT_PRESSURE_DATA_FIELDS_DISPLAY = [
  TGS_LANDING_ZONE,
  FORMATION_PRESSURE,
  TEST_DEPTH,
  TEST_TYPE,
  INITIAL_SHUT_IN_END_TGS,
  FINAL_SHUT_IN_END_TGS,
  FORMATION_WATER_RESISTIVITY,
  WATER_SATURATION,
  POROSITY,
  PERMEABILITY,
  OIL_AMT,
  TOTAL_GAS_AMT,
  CONDENSATE_AMT,
  WATER_AMT,
  SUMMARY_TEST_REMARKS,
  SAMPLE_REMARKS,
];
export const WFT_PRESSURE_DATA_TEST_TYPES = {
  WFT_PLUS: "WftPlus",
} as const;

export const WFT_PRESSURE_DATA_SEARCHTYPES = [
  WFT_PRESSURE_DATA_TEST_TYPES.WFT_PLUS,
] as const;
