import { Data, Datum } from "plotly.js";

import {
  DatalakeIncrementalShutInData,
  WftPressureDataResponseBody,
} from "../../../types/panels/wellPanel/dataLakeType";
import { WftPressureDataWellPanel } from "../../../types/panels/wellPanel/pressureDataType";

import { WFT_TEST_TYPE_LABEL } from "../../../constants/panels/wellPanel/pressureData";

import { parseNumberData } from "../../../utils/formatters/numberFormatter";
import { sortPanelData } from "../../../utils/panel/wellPanel/sorting";

import { clone } from "../../../utils";
import { useFieldFormatter } from "../../common/useFieldFormatter";

const useWftPressureData = () => {
  const { stringValidation } = useFieldFormatter();

  const formatNumberData = (val: number | null, unit: string) => {
    return stringValidation(
      val !== null && val !== undefined ? `${val.toString()} ${unit}` : ""
    );
  };

  const formatIncrementalShutInTrace = (
    data?: DatalakeIncrementalShutInData[] | null
  ) => {
    if (data === undefined || data === null || !data.length) {
      return null;
    }

    const mappedData = data.sort((a, b) => {
      return (a.ChartNumber ?? 0) - (b.ChartNumber ?? 0);
    });

    const incrementalDataTrace: Data[] = [];
    const trace: Data = {
      type: "scatter",
      mode: "lines+markers",
      marker: { color: "rgba(0, 176, 255, 1)" },
      line: { color: "rgba(255, 255, 255, 0.7)" },
      x: [],
      y: [],
      hoverlabel: {
        align: "left",
      },
      hovertemplate: `(%{y}, %{x}) PSI, Seconds <extra></extra>`,
    };
    mappedData.forEach((incrementalData) => {
      (trace.x as Datum[]).push(parseNumberData(incrementalData.Time ?? 0));
      (trace.y as Datum[]).push(incrementalData.Pressure ?? 0);
    });

    incrementalDataTrace.push(trace);
    return incrementalDataTrace;
  };

  const dataMapper = (data: WftPressureDataResponseBody) => {
    const mappedData: WftPressureDataWellPanel = {
      DocType: data.DocType,
      TestNumber: data.TestNumber,
      TestName: `${WFT_TEST_TYPE_LABEL} - ${data.TestNumber ?? ""}`,
      TestType: stringValidation(data.TestType ?? ""),
      InitialShutInEndTgs: formatNumberData(data.InitialShutInEndTgs, "psi"),
      FinalShutInEndTgs: formatNumberData(data.FinalShutInEndTgs, "psi"),
      TestDepth: formatNumberData(data.TestDepth, "ft"),
      TotalGasAmount: formatNumberData(data.TotalGasAmount, "g/cm\u00B3"),
      CondensateAmount: formatNumberData(data.CondensateAmount, "cc"),
      OilAmount: formatNumberData(data.OilAmount, "cc"),
      WaterAmount: formatNumberData(data.WaterAmount, "cc"),
      Formation: stringValidation(data.Formation ?? ""),
      Porosity: formatNumberData(data.Porosity, "%"),
      FormationWaterResistivity: formatNumberData(
        data.FormationWaterResistivity,
        "Ωm"
      ),
      WaterSaturation: formatNumberData(data.WaterSaturation, "%"),
      FormationPressure: formatNumberData(data.FormationPressure, "psi"),
      Permeability: formatNumberData(data.Permeability, "mD"),
      SampleRemarks: stringValidation(data.SampleRemarks ?? ""),
      TgsLandingZone: stringValidation(""),
      SummaryTestRemarks: stringValidation(data.SummaryTestRemarks ?? ""),
      ...("IncrementalShutIn" in data && {
        IncrementalShutInChartTrace: formatIncrementalShutInTrace(
          data.IncrementalShutIn
        ),
      }),
    };
    return mappedData;
  };

  const buildWftPressureData = (rawData: WftPressureDataResponseBody[]) => {
    let parsedData: WftPressureDataWellPanel[] = [];

    //map data
    parsedData = (clone(rawData) as WftPressureDataResponseBody[]).map(
      (wftData) => {
        return dataMapper(wftData);
      }
    );

    return sortPanelData(parsedData, "TestNumber");
  };

  return {
    buildWftPressureData,
  };
};

export default useWftPressureData;
