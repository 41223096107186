import { FC } from "react";

import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";

import { TargetUoMKey } from "../../types/charts/chartTypeSettings/exonomicsTypeSettings";

import { TARGET_UOM_ECONOMICS } from "../../constants/charts/cashFlowChart";

interface EconomicsTargetUomToggleProps {
  uomValue: TargetUoMKey;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const EconomicsTargetUomToggle: FC<EconomicsTargetUomToggleProps> = ({
  uomValue,
  onChange,
}) => {
  return (
    <div className="euom-toggle-container">
      <FormControl fullWidth>
        <FormLabel className="unit-label">Unit</FormLabel>
        <RadioGroup
          value={uomValue}
          name="targetUom-buttons-group"
          onChange={onChange}
          className={"targetUom-container"}
          row
        >
          <FormControlLabel
            className="radio-label"
            value={TARGET_UOM_ECONOMICS.boe.key}
            control={
              <Radio size="small" className="custom-primary-icon-color" />
            }
            label={TARGET_UOM_ECONOMICS.boe.label}
            checked={uomValue === TARGET_UOM_ECONOMICS.boe.key}
          />

          <FormControlLabel
            className="radio-label"
            value={TARGET_UOM_ECONOMICS.mcfe.key}
            control={
              <Radio size="small" className="custom-primary-icon-color" />
            }
            label={TARGET_UOM_ECONOMICS.mcfe.label}
            checked={uomValue === TARGET_UOM_ECONOMICS.mcfe.key}
          />
        </RadioGroup>
      </FormControl>
    </div>
  );
};

export default EconomicsTargetUomToggle;
