import { LayerDefinition } from "../../types/map";

import {
  ANADARKO_01_STONE_CORRAL_STRUCTURE,
  ANADARKO_02_TOP_HUTCHINSON_STRUCTURE,
  ANADARKO_03_BASE_HUTCHINSON_STRUCTURE,
  ANADARKO_04_CHESTER_STRUCTURE,
  ANADARKO_05_WABAUNSEE_STRUCTURE,
  ANADARKO_06_HEEBNER_SHALE_STRUCTURE,
  ANADARKO_07_LANSING_STRUCTURE,
  ANADARKO_08_HOXBAR_KANSAS_CITY_STRUCTURE,
  ANADARKO_09_MARMATON_STRUCTURE,
  ANADARKO_10_CHEROKEE_STRUCTURE,
  ANADARKO_11_ATOKA_STRUCTURE,
  ANADARKO_12_TOP_EROSION_STRUCTURE,
  ANADARKO_13_SPRINGER_STRUCTURE,
  ANADARKO_14_CHESTER_LIME_STRUCTURE,
  ANADARKO_15_MISSISSIPPI_LIME_STRUCTURE,
  ANADARKO_16_WOODFORD_STRUCTURE,
  ANADARKO_17_HOSSTON_STRUCTURE,
  ANADARKO_18_SYLVAN_SHALE_STRUCTURE,
  ANADARKO_19_VIOLA_STRUCTURE,
  ANADARKO_20_SIMPSON_STRUCTURE,
  ANADARKO_21_ARBUCKLE_STRUCTURE,
  APPALACHIA_STORAGE_UNIT_LAYERS,
  BOTTOM_GEO_POINT,
  BOTTOM_PERMIT_SPOTS,
  BOTTOM_WELL_SPOTS,
  BOUGUER_ALY,
  BUBBLE_MAP, //CANADA_LAND_GRIDS,
  CARTO_MAX_ZOOM,
  CARTO_SHALLOW_MIN_ZOOM,
  DYNAMIC_BOTTOM_PERMIT_SPOTS,
  DYNAMIC_BOTTOM_WELL_SPOTS,
  DYNAMIC_PERMIT_SPOTS,
  DYNAMIC_VECTOR_TILES,
  DYNAMIC_WELL_SPOTS,
  EAGLE_FORD_02_VICKSBURG_GROUP_GRID,
  EAGLE_FORD_03B_LOWER_CLAIBORNE_GRID,
  EAGLE_FORD_03_CLAIBORNE_GROUP_GRID,
  EAGLE_FORD_04A_MIDDLE_WILCOX_GRID,
  EAGLE_FORD_04B_LOWER_WILCOX_GRID,
  EAGLE_FORD_04_UPPER_WILCOX_GRID,
  EAGLE_FORD_05_MIDWAY_GROUP_GRID,
  EAGLE_FORD_06_NAVARRO_GROUP_GRID,
  EAGLE_FORD_07A_ANACACHO_GRID,
  EAGLE_FORD_07_TAYLOR_GROUP_GRID,
  EAGLE_FORD_08_AUSTIN_CHALK_GRID,
  EAGLE_FORD_09_UPPER_EAGLE_FORD_GRID,
  EAGLE_FORD_10A_WOODBINE_GRID,
  EAGLE_FORD_10B_MANESS_GRID,
  EAGLE_FORD_10_LOWER_EAGLE_FORD_GRID,
  EAGLE_FORD_11A_DEL_RIO_GRID,
  EAGLE_FORD_11B_GEORGETOWN_GRID,
  EAGLE_FORD_11C_EDWARDS_GRID,
  EAGLE_FORD_11_BUDA_GRID,
  EAGLE_FORD_12_GLEN_ROSE_GRID,
  EAGLE_FORD_13_PEARSAL_GRID,
  EAGLE_FORD_14_SLIGO_PETTET_GRID,
  EAST_GULF_COAST_BASIN_STORAGE_UNIT_LAYERS,
  FIRST_ST_BONE_SPRINGS_ISOPACH,
  GPB_01_RUSTLER,
  GPB_02_SALADO,
  GPB_03A_CHERRY_CANYON_QUEEN,
  GPB_03_BELL_CANYON_TANSIL,
  GPB_04A_GLORIETA,
  GPB_04_BRUSHY_CANYON_SAN_ANDRES,
  GPB_05_TOP_BS_CLEARFORK,
  GPB_06A1_MIDDLE_SPRABERRY,
  GPB_06A2_2ND_BONE_SPRING_LIME,
  GPB_06B1_LOWER_SPRABERRY,
  GPB_06B2_2ND_BONE_SPRING_SAND,
  GPB_06C0_LOWER_SPRABERRY_SHALE,
  GPB_06C1_3RD_BONE_SPRING_LIME,
  GPB_06C2_3RD_BONE_SPRING_LIME_SANDSTONE_TOP,
  GPB_06C3_3RD_BONE_SPRING_LIME_SANDSTONE_BASE,
  GPB_06D_3RD_BONE_SPRING_SAND,
  GPB_06D_DEAN_SAND,
  GPB_06_1ST_BS_SAND_WICHITA_SPRABERRY,
  GPB_07A1_WOLFCAMP_X,
  GPB_07A2_WOLFCAMP_Y,
  GPB_07A3_WOLFCAMP_SHALE,
  GPB_07B1_MID_WOLFCAMP_B,
  GPB_07B2_DEL_WOLFCAMP_B,
  GPB_07C_INTRA_WOLFCAMP_C,
  GPB_07_TOP_WOLFCAMP,
  GPB_08_CISCO_WOLFCAMP_D,
  GPB_09_STRAWN,
  GPB_10_BARNETT,
  GPB_11_MISSISSIPPIAN_LIMESTONE,
  GPB_12_WOODFORD_SHALE,
  GPB_13_DEVONIAN_CARBONATE,
  GPB_14_SILURIAN_SHALE,
  GPB_15_FUSSLEMAN,
  GPB_16_SIMPSON,
  GPB_17_ELLENBURGER,
  GPB_18_BASEMENT,
  GULF_COAST_STORAGE_UNIT_LAYERS,
  ILLINOIS_BASIN_STORAGE_UNIT_LAYERS,
  ISOSTATIC_ALY,
  LAND_GRIDS,
  LOWERSPRABERRY_ISOPACH,
  MAGNETIC_ALY,
  MAJOR_BASINS,
  MAP_SEARCH_PIPELINES,
  MICHIGAN_STORAGE_UNIT_LAYERS,
  MIDDLESPRABERRY_ISOPACH,
  MID_15A_MONTOYA,
  OFFSHORE_BLOCKS,
  PERMIT_PATH_AND_STICKS,
  PERMIT_SPOTS,
  PERMIT_STICKS,
  PERMIT_STICKS_GEO_POINT,
  SANANDRES_ISOPACH,
  SCOOP_STACK_PLAY_AREAS,
  SECOND_BONE_SPRINGS_ISOPACH,
  SHADED_ALY,
  STRATIGRAPHIC_MODELS_AREA,
  STRUCTURAL_ELEMENTS,
  SURFACE_GEO_POINT,
  TGS_SURVEYS,
  THIRD_BONE_SPRINGS_ISOPACH,
  TRENDS,
  TRENDS_AND_BASINS,
  TRENDS_OUTLINE,
  UPPERSPRABERRY_ISOPACH,
  WELL_PATHS,
  WELL_PATHS_GEO_POINT,
  WELL_PATH_AND_STICKS,
  WELL_SPOTS,
  WELL_STICKS,
  WELL_STICKS_GEO_POINT,
  WMS_FETCH_TYPE,
  WMTS_FETCH_TYPE,
  WOLFCAMPX_ISOPACH,
  WOLFCAMPY_ISOPACH,
  WOODFORD_FIELDS,
  WOODFORD_ISOPACH,
  WOODFORD_LIMIT,
  WOODFORD_MATURITY,
  WOODFORD_STRUCTURE,
} from "../constants";
import { MEDIUM } from "../map/mapSettings";

export const layerDefinitions = (
  GIS_URL: string,
  CARTO_CONNECTION: string,
  LAYER_PREFIX: string,
  BQ_TABLE: string
): LayerDefinition => {
  return {
    [WELL_SPOTS]: {
      layerGroupZIndex: 110,
      layers: [
        {
          url: `${GIS_URL}/geoserver/gwc/service/wmts?FORMAT=image/png&LAYER=${LAYER_PREFIX}:well_surface_locations_raster&SERVICE=WMTS`,
          fetchType: WMTS_FETCH_TYPE,
          maxZoom: 11,
          projection: "EPSG:900913",
          zIndex: 110,
        },
        {
          title: DYNAMIC_WELL_SPOTS,
          connection: CARTO_CONNECTION,
          geoColumn: SURFACE_GEO_POINT,
          fetchType: DYNAMIC_VECTOR_TILES,
          maxZoom: CARTO_MAX_ZOOM,
          defaultColor: [0, 88, 161],
          size: MEDIUM,
          zIndex: 110,
        },
      ],
    },
    [BOTTOM_WELL_SPOTS]: {
      layerGroupZIndex: 100,
      layers: [
        {
          title: DYNAMIC_BOTTOM_WELL_SPOTS,
          connection: CARTO_CONNECTION,
          geoColumn: BOTTOM_GEO_POINT,
          fetchType: DYNAMIC_VECTOR_TILES,
          maxZoom: CARTO_MAX_ZOOM,
          minZoom: CARTO_SHALLOW_MIN_ZOOM,
          defaultColor: [0, 88, 161],
          size: MEDIUM,
          zIndex: 100,
        },
      ],
    },
    [WELL_PATH_AND_STICKS]: {
      layerGroupZIndex: 95,
      layers: [
        {
          title: WELL_PATHS,
          connection: CARTO_CONNECTION,
          geoColumn: WELL_PATHS_GEO_POINT,
          fetchType: DYNAMIC_VECTOR_TILES,
          maxZoom: CARTO_MAX_ZOOM,
          minZoom: CARTO_SHALLOW_MIN_ZOOM,
          defaultColor: [0, 88, 161],
          defaultLineColor: [0, 88, 161],
          size: MEDIUM,
          zIndex: 95,
        },
        {
          title: WELL_STICKS,
          connection: CARTO_CONNECTION,
          geoColumn: WELL_STICKS_GEO_POINT,
          fetchType: DYNAMIC_VECTOR_TILES,
          maxZoom: CARTO_MAX_ZOOM,
          minZoom: CARTO_SHALLOW_MIN_ZOOM,
          defaultColor: [0, 88, 161],
          defaultLineColor: [0, 88, 161],
          size: MEDIUM,
          zIndex: 95,
        },
      ],
    },
    [PERMIT_SPOTS]: {
      layerGroupZIndex: 130,
      layers: [
        {
          title: DYNAMIC_PERMIT_SPOTS,
          connection: CARTO_CONNECTION,
          geoColumn: SURFACE_GEO_POINT,
          fetchType: DYNAMIC_VECTOR_TILES,
          maxZoom: CARTO_MAX_ZOOM,
          // minZoom: CARTO_SHALLOW_MIN_ZOOM,
          defaultColor: [0, 184, 212, 0],
          defaultLineColor: [0, 184, 212],
          size: { key: MEDIUM.key, label: "default" },
          zIndex: 130,
        },
      ],
    },
    [BOTTOM_PERMIT_SPOTS]: {
      layerGroupZIndex: 120,
      layers: [
        {
          title: DYNAMIC_BOTTOM_PERMIT_SPOTS,
          connection: CARTO_CONNECTION,
          geoColumn: BOTTOM_GEO_POINT,
          fetchType: DYNAMIC_VECTOR_TILES,
          maxZoom: CARTO_MAX_ZOOM,
          minZoom: CARTO_SHALLOW_MIN_ZOOM,
          defaultColor: [0, 184, 212, 0],
          defaultLineColor: [0, 184, 212],
          size: { key: MEDIUM.key, label: "default" },
          zIndex: 120,
        },
      ],
    },
    [PERMIT_PATH_AND_STICKS]: {
      layerGroupZIndex: 115,
      layers: [
        {
          title: PERMIT_STICKS,
          connection: CARTO_CONNECTION,
          geoColumn: PERMIT_STICKS_GEO_POINT,
          fetchType: DYNAMIC_VECTOR_TILES,
          maxZoom: CARTO_MAX_ZOOM,
          minZoom: CARTO_SHALLOW_MIN_ZOOM,
          defaultColor: [0, 184, 212, 0],
          defaultLineColor: [0, 184, 212],
          size: MEDIUM,
          zIndex: 115,
        },
      ],
    },
    [BUBBLE_MAP]: {
      layerGroupZIndex: 80,
      layers: [
        {
          title: BUBBLE_MAP,
          connection: CARTO_CONNECTION,
          geoColumn: BOTTOM_GEO_POINT,
          fetchType: DYNAMIC_VECTOR_TILES,
          maxZoom: CARTO_MAX_ZOOM,
          minZoom: CARTO_SHALLOW_MIN_ZOOM,
          defaultColor: [144, 202, 249],
          defaultLineColor: [238, 238, 238],
          defaultSize: 0,
          size: MEDIUM,
          zIndex: 80,
          clickDisabled: true,
        },
      ],
    },
    [TGS_SURVEYS]: {
      layerGroupZIndex: 37,
      layers: [
        {
          url: `${GIS_URL}/arcgis/services/web/Map_NoWells_Dynamic/MapServer/WMSServer`,
          fetchType: WMS_FETCH_TYPE,
          layerOpacity: 1,
          tileSupport: false,
          params: {
            LAYERS: "1,2,3,5,6,7,8",
            FORMAT: "image/png32",
          },
          zIndex: 37,
          minZoom: 4,
        },
      ],
    },
    [STRATIGRAPHIC_MODELS_AREA]: {
      layerGroupZIndex: 37,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          layerOpacity: 0.5,
          params: {
            LAYERS: `${LAYER_PREFIX}:stratigraphic_models_area`,
            TILED: true,
          },
          zIndex: 36,
          minZoom: 2,
        },
      ],
    },
    [FIRST_ST_BONE_SPRINGS_ISOPACH]: {
      layerGroupZIndex: 36,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `${LAYER_PREFIX}:1st_Bone_Springs_Isopach`,
            TILED: true,
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [SECOND_BONE_SPRINGS_ISOPACH]: {
      layerGroupZIndex: 36,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `${LAYER_PREFIX}:2nd_Bone_Springs_Isopach`,
            TILED: true,
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [THIRD_BONE_SPRINGS_ISOPACH]: {
      layerGroupZIndex: 36,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `${LAYER_PREFIX}:3rd_Bone_Springs_Isopach`,
            TILED: true,
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [WOLFCAMPX_ISOPACH]: {
      layerGroupZIndex: 36,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `${LAYER_PREFIX}:WolfcampX_Isopach`,
            TILED: true,
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [UPPERSPRABERRY_ISOPACH]: {
      layerGroupZIndex: 36,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `${LAYER_PREFIX}:UpperSpraberry_Isopach`,
            TILED: true,
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [MIDDLESPRABERRY_ISOPACH]: {
      layerGroupZIndex: 36,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `${LAYER_PREFIX}:MiddleSpraberry_Isopach`,
            TILED: true,
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [LOWERSPRABERRY_ISOPACH]: {
      layerGroupZIndex: 36,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `${LAYER_PREFIX}:LowerSpraberry_Isopach`,
            TILED: true,
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [WOLFCAMPY_ISOPACH]: {
      layerGroupZIndex: 36,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `${LAYER_PREFIX}:WolfcampY_Isopach`,
            TILED: true,
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [SANANDRES_ISOPACH]: {
      layerGroupZIndex: 36,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `${LAYER_PREFIX}:SanAndres_Isopach`,
            TILED: true,
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [GPB_01_RUSTLER]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `${LAYER_PREFIX}:GPB_01_Rustler`,
            TILED: true,
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [GPB_02_SALADO]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `${LAYER_PREFIX}:GPB_02_Salado`,
            TILED: true,
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [GPB_03_BELL_CANYON_TANSIL]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `${LAYER_PREFIX}:GPB_03_Bell_Canyon_Tansil`,
            TILED: true,
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [GPB_03A_CHERRY_CANYON_QUEEN]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `${LAYER_PREFIX}:GPB_03a_Cherry_Canyon_Queen`,
            TILED: true,
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [GPB_04_BRUSHY_CANYON_SAN_ANDRES]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `${LAYER_PREFIX}:GPB_04_Brushy_Canyon_San_Andres`,
            TILED: true,
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [GPB_04A_GLORIETA]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `${LAYER_PREFIX}:GPB_04a_Glorieta`,
            TILED: true,
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [GPB_05_TOP_BS_CLEARFORK]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `${LAYER_PREFIX}:GPB_05_Top_BS_Clearfork`,
            TILED: true,
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [GPB_06_1ST_BS_SAND_WICHITA_SPRABERRY]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `${LAYER_PREFIX}:GPB_06_1st_BS_Sand_Wichita_Spraberry`,
            TILED: true,
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [GPB_06A1_MIDDLE_SPRABERRY]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `${LAYER_PREFIX}:GPB_06a1_Middle_Spraberry`,
            TILED: true,
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [GPB_06A2_2ND_BONE_SPRING_LIME]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `${LAYER_PREFIX}:GPB_06a2_2nd_Bone_Spring_Lime`,
            TILED: true,
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [GPB_06B1_LOWER_SPRABERRY]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `${LAYER_PREFIX}:GPB_06b1_Lower_Spraberry`,
            TILED: true,
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [GPB_06B2_2ND_BONE_SPRING_SAND]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `${LAYER_PREFIX}:GPB_06b2_2nd_Bone_Spring_Sand`,
            TILED: true,
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [GPB_06C0_LOWER_SPRABERRY_SHALE]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `${LAYER_PREFIX}:GPB_06c0_Lower_Spraberry_Shale`,
            TILED: true,
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [GPB_06C1_3RD_BONE_SPRING_LIME]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `${LAYER_PREFIX}:GPB_06c1_3rd_Bone_Spring_Lime`,
            TILED: true,
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [GPB_06C2_3RD_BONE_SPRING_LIME_SANDSTONE_TOP]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `${LAYER_PREFIX}:GPB_06c2_3rd_Bone_Spring_Lime_Sandstone_Top`,
            TILED: true,
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [GPB_06C3_3RD_BONE_SPRING_LIME_SANDSTONE_BASE]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `${LAYER_PREFIX}:GPB_06c3_3rd_Bone_Spring_Lime_Sandstone_Base`,
            TILED: true,
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [GPB_06D_3RD_BONE_SPRING_SAND]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `${LAYER_PREFIX}:GPB_06d_3rd_Bone_Spring_Sand`,
            TILED: true,
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [GPB_06D_DEAN_SAND]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `${LAYER_PREFIX}:GPB_06d_Dean_Sand`,
            TILED: true,
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [GPB_07_TOP_WOLFCAMP]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `${LAYER_PREFIX}:GPB_07_Top_Wolfcamp`,
            TILED: true,
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [GPB_07A1_WOLFCAMP_X]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `${LAYER_PREFIX}:GPB_07a1_Wolfcamp_X`,
            TILED: true,
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [GPB_07A2_WOLFCAMP_Y]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `${LAYER_PREFIX}:GPB_07a2_Wolfcamp_Y`,
            TILED: true,
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [GPB_07A3_WOLFCAMP_SHALE]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `${LAYER_PREFIX}:GPB_07a3_Wolfcamp_Shale`,
            TILED: true,
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [GPB_07B1_MID_WOLFCAMP_B]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `${LAYER_PREFIX}:GPB_07b1_Mid_Wolfcamp_B`,
            TILED: true,
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [GPB_07B2_DEL_WOLFCAMP_B]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `${LAYER_PREFIX}:GPB_07b2_Del_Wolfcamp_B`,
            TILED: true,
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [GPB_07C_INTRA_WOLFCAMP_C]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `${LAYER_PREFIX}:GPB_07c_Intra_Wolfcamp_C`,
            TILED: true,
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [GPB_08_CISCO_WOLFCAMP_D]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `${LAYER_PREFIX}:GPB_08_Cisco_Wolfcamp_D`,
            TILED: true,
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [GPB_09_STRAWN]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `${LAYER_PREFIX}:GPB_09_Strawn`,
            TILED: true,
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [GPB_10_BARNETT]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `${LAYER_PREFIX}:GPB_10_Barnett`,
            TILED: true,
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [GPB_11_MISSISSIPPIAN_LIMESTONE]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `${LAYER_PREFIX}:GPB_11_Mississippian_Limestone`,
            TILED: true,
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [GPB_12_WOODFORD_SHALE]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `${LAYER_PREFIX}:GPB_12_Woodford_Shale`,
            TILED: true,
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [GPB_13_DEVONIAN_CARBONATE]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `${LAYER_PREFIX}:GPB_13_Devonian_Carbonate`,
            TILED: true,
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [GPB_14_SILURIAN_SHALE]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `${LAYER_PREFIX}:GPB_14_Silurian_Shale`,
            TILED: true,
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [GPB_15_FUSSLEMAN]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `${LAYER_PREFIX}:GPB_15_Fussleman`,
            TILED: true,
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [GPB_16_SIMPSON]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `${LAYER_PREFIX}:GPB_16_Simpson`,
            TILED: true,
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [GPB_17_ELLENBURGER]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `${LAYER_PREFIX}:GPB_17_Ellenburger`,
            TILED: true,
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [GPB_18_BASEMENT]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `${LAYER_PREFIX}:GPB_18_Basement`,
            TILED: true,
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [MID_15A_MONTOYA]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `${LAYER_PREFIX}:MID_15A_MONTOYA`,
            TILED: true,
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [TRENDS_OUTLINE]: {
      layerGroupZIndex: 34,
      layers: [
        {
          url: `${GIS_URL}/geoserver/gwc/service/wmts`,
          fetchType: "MVT",
          layerOpacity: 0.5,
          projection: "EPSG:900913",
          format: "application/vnd.mapbox-vector-tile",
          params: {
            LAYERS: `${LAYER_PREFIX}:trend_outlines`,
          },
          zIndex: 34,
          minZoom: 4,
          layerStyle: {
            stroke: {
              color: "#00734c",
              width: 3,
              lineJoin: "bevel",
              lineCap: undefined,
            },
            fill: {
              color: "rgb(240, 240, 240,0.5)",
            },
          },
        },
      ],
    },
    [TRENDS_AND_BASINS]: {
      layerGroupZIndex: 32,
      layers: [
        {
          url: `${GIS_URL}/arcgis/services/R360Premium/TGS_Formations/MapServer/WMSServer`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: "0,1,2",
          },
          zIndex: 32,
          minZoom: 4,
        },
      ],
    },
    [MAJOR_BASINS]: {
      layerGroupZIndex: 30,
      layers: [
        {
          url: `${GIS_URL}/geoserver/gwc/service/wmts`,
          fetchType: "MVT",
          layerOpacity: 0.5,
          projection: "EPSG:900913",
          format: "application/vnd.mapbox-vector-tile",
          params: {
            LAYERS: `${LAYER_PREFIX}:major_basins`,
          },
          zIndex: 36,
          minZoom: 4,
          layerStyle: {
            stroke: {
              color: "#b2b2b2",
              width: 0.5,
              lineJoin: "round",
              lineCap: "round",
            },
            fill: {
              color: "rgb(0,77,168,0.5)",
            },
          },
        },
      ],
    },
    [TRENDS]: {
      layerGroupZIndex: 32,
      layers: [
        {
          url: `${GIS_URL}/geoserver/gwc/service/wmts`,
          fetchType: "MVT",
          layerOpacity: 0.5,
          projection: "EPSG:900913",
          format: "application/vnd.mapbox-vector-tile",
          params: {
            LAYERS: `${LAYER_PREFIX}:trends`,
          },
          zIndex: 36,
          minZoom: 4,
          layerStyle: {
            stroke: {
              color: "#9c9c9c",
              width: 0.5,
              lineJoin: "bevel",
              lineCap: undefined,
            },
            fill: {
              color: "rgb(38,115,0,0.5)",
            },
          },
        },
      ],
    },
    [OFFSHORE_BLOCKS]: {
      layerGroupZIndex: 30,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          layerOpacity: 0.5,
          params: {
            LAYERS: `${LAYER_PREFIX}:ggx_offshore_blocks`,
            TILED: true,
          },
          zIndex: 30,
          minZoom: 4,
        },
      ],
    },
    [MAP_SEARCH_PIPELINES]: {
      layerGroupZIndex: 29,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          layerOpacity: 0.5,
          params: {
            LAYERS: `${LAYER_PREFIX}:ms_pipelines`,
            TILED: true,
          },
          zIndex: 29,
          minZoom: 11,
        },
      ],
    },
    [LAND_GRIDS]: {
      layerGroupZIndex: 28,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          tileSupport: false,
          params: {
            LAYERS: `${LAYER_PREFIX}:ggx_townships`,
            TILED: true,
          },
          zIndex: 28,
          minZoom: 11,
        },
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          tileSupport: false,
          params: {
            LAYERS: `${LAYER_PREFIX}:ggx_canada_townships`,
            TILED: true,
          },
          zIndex: 27.5,
          minZoom: 11,
        },
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          tileSupport: false,
          params: {
            LAYERS: `${LAYER_PREFIX}:ggx_sections_group`,
            TILED: true,
          },
          zIndex: 27,
          minZoom: 11,
        },
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          tileSupport: false,
          params: {
            LAYERS: `${LAYER_PREFIX}:ggx_canada_sections`,
            TILED: true,
          },
          zIndex: 26.5,
          minZoom: 11,
        },
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          tileSupport: false,
          params: {
            LAYERS: `${LAYER_PREFIX}:ggx_texas_blocks`,
            TILED: true,
          },
          zIndex: 26,
          minZoom: 11,
        },
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          tileSupport: false,
          params: {
            LAYERS: `${LAYER_PREFIX}:ggx_texas_surveys`,
            TILED: true,
          },
          zIndex: 25,
          minZoom: 11,
        },
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          tileSupport: false,
          params: {
            LAYERS: `${LAYER_PREFIX}:ggx_texas_subsurveys`,
            TILED: true,
          },
          zIndex: 24,
          minZoom: 11,
        },
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          tileSupport: false,
          params: {
            LAYERS: `${LAYER_PREFIX}:ggx_texas_abstracts`,
            TILED: true,
          },
          zIndex: 23,
          minZoom: 11,
        },
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          tileSupport: false,
          params: {
            LAYERS: `${LAYER_PREFIX}:ggx_texas_subdivisions`,
            TILED: true,
          },
          zIndex: 22,
          minZoom: 11,
        },
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          tileSupport: false,
          params: {
            LAYERS: `${LAYER_PREFIX}:ggx_texas_lots`,
            TILED: true,
          },
          zIndex: 21,
          minZoom: 11,
        },
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          tileSupport: false,
          params: {
            LAYERS: `${LAYER_PREFIX}:ggx_texas_tracts`,
            TILED: true,
          },
          zIndex: 20,
          minZoom: 11,
        },
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          tileSupport: false,
          params: {
            LAYERS: `${LAYER_PREFIX}:ggx_texas_sections`,
            TILED: true,
          },
          zIndex: 19,
          minZoom: 11,
        },
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          tileSupport: false,
          params: {
            LAYERS: `${LAYER_PREFIX}:ggx_texas_districts`,
            TILED: true,
          },
          zIndex: 18,
          minZoom: 11,
        },
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          tileSupport: false,
          params: {
            LAYERS: `${LAYER_PREFIX}:ggx_texas_townships`,
            TILED: true,
          },
          zIndex: 17,
          minZoom: 11,
        },
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          tileSupport: false,
          params: {
            LAYERS: `${LAYER_PREFIX}:ggx_texas_quarters`,
            TILED: true,
          },
          zIndex: 16,
          minZoom: 11,
        },
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          tileSupport: false,
          params: {
            LAYERS: `${LAYER_PREFIX}:ggx_ohiotracts`,
            TILED: true,
          },
          zIndex: 15,
          minZoom: 11,
        },
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          tileSupport: false,
          params: {
            LAYERS: `${LAYER_PREFIX}:ggx_ohiotownsites`,
            TILED: true,
          },
          zIndex: 14,
          minZoom: 11,
        },
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          tileSupport: false,
          params: {
            LAYERS: `${LAYER_PREFIX}:ggx_ohiosurveys`,
            TILED: true,
          },
          zIndex: 13,
          minZoom: 11,
        },
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          tileSupport: false,
          params: {
            LAYERS: `${LAYER_PREFIX}:ggx_ohiosubsurveys`,
            TILED: true,
          },
          zIndex: 12,
          minZoom: 11,
        },
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          tileSupport: false,
          params: {
            LAYERS: `${LAYER_PREFIX}:ggx_ohiolots`,
            TILED: true,
          },
          zIndex: 11,
          minZoom: 11,
        },
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          tileSupport: false,
          params: {
            LAYERS: `${LAYER_PREFIX}:ggx_pennsylvania_municipalities`,
            TILED: true,
          },
          zIndex: 10,
          minZoom: 11,
        },
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          tileSupport: false,
          params: {
            LAYERS: `${LAYER_PREFIX}:ggx_west_virginia_tax_districts`,
            TILED: true,
          },
          zIndex: 9,
          minZoom: 11,
        },
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          tileSupport: false,
          params: {
            LAYERS: `${LAYER_PREFIX}:ggx_tracts`,
            TILED: true,
          },
          zIndex: 8,
          minZoom: 11,
        },
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          tileSupport: false,
          params: {
            LAYERS: `${LAYER_PREFIX}:ggx_townsites`,
            TILED: true,
          },
          zIndex: 7,
          minZoom: 11,
        },
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          tileSupport: false,
          params: {
            LAYERS: `${LAYER_PREFIX}:ggx_lots`,
            TILED: true,
          },
          zIndex: 6,
          minZoom: 11,
        },
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          tileSupport: false,
          params: {
            LAYERS: `${LAYER_PREFIX}:ggx_claims`,
            TILED: true,
          },
          zIndex: 5,
          minZoom: 11,
        },
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          tileSupport: false,
          params: {
            LAYERS: `${LAYER_PREFIX}:ggx_qtrqtrs`,
            TILED: true,
          },
          zIndex: 4,
          minZoom: 11,
        },
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          tileSupport: false,
          params: {
            LAYERS: `${LAYER_PREFIX}:ggx_quad_carters`,
            TILED: true,
          },
          zIndex: 3,
          minZoom: 11,
        },
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          tileSupport: false,
          params: {
            LAYERS: `${LAYER_PREFIX}:ggx_quarters`,
            TILED: true,
          },
          zIndex: 2,
          minZoom: 11,
        },
      ],
    },
    [BOUGUER_ALY]: {
      layerGroupZIndex: 0.5,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          layerOpacity: 0.5,
          params: {
            LAYERS: `${LAYER_PREFIX}:bouguer_anomaly`,
            TILED: true,
          },
          zIndex: 0.5,
          minZoom: 2,
        },
      ],
    },
    [ISOSTATIC_ALY]: {
      layerGroupZIndex: 0.5,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          layerOpacity: 0.5,
          params: {
            LAYERS: `${LAYER_PREFIX}:isostatic_anomaly`,
            TILED: true,
          },
          zIndex: 0.5,
          minZoom: 2,
        },
      ],
    },
    [SHADED_ALY]: {
      layerGroupZIndex: 0.5,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          layerOpacity: 0.5,
          params: {
            LAYERS: `${LAYER_PREFIX}:shaded_anamoly`,
            TILED: true,
          },
          zIndex: 0.5,
          minZoom: 2,
        },
      ],
    },
    [MAGNETIC_ALY]: {
      layerGroupZIndex: 0.5,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          layerOpacity: 0.5,
          params: {
            LAYERS: `${LAYER_PREFIX}:na_magnetic_anomaly`,
            TILED: true,
          },
          zIndex: 0.5,
          minZoom: 2,
        },
      ],
    },
    [EAGLE_FORD_02_VICKSBURG_GROUP_GRID]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `${LAYER_PREFIX}:eagle_ford_02_vicksburg_group_grid`,
            TILED: true,
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [EAGLE_FORD_03_CLAIBORNE_GROUP_GRID]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `${LAYER_PREFIX}:eagle_ford_03_claiborne_group_grid`,
            TILED: true,
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [EAGLE_FORD_03B_LOWER_CLAIBORNE_GRID]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `${LAYER_PREFIX}:eagle_ford_03b_lower_claiborne_grid`,
            TILED: true,
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [EAGLE_FORD_04_UPPER_WILCOX_GRID]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `${LAYER_PREFIX}:eagle_ford_04_upper_wilcox_grid`,
            TILED: true,
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [EAGLE_FORD_04A_MIDDLE_WILCOX_GRID]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `${LAYER_PREFIX}:eagle_ford_04a_middle_wilcox_grid`,
            TILED: true,
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [EAGLE_FORD_04B_LOWER_WILCOX_GRID]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `${LAYER_PREFIX}:eagle_ford_04b_lower_wilcox_grid`,
            TILED: true,
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [EAGLE_FORD_05_MIDWAY_GROUP_GRID]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `${LAYER_PREFIX}:eagle_ford_05_midway_group_grid`,
            TILED: true,
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [EAGLE_FORD_06_NAVARRO_GROUP_GRID]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `${LAYER_PREFIX}:eagle_ford_06_navarro_group_grid`,
            TILED: true,
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [EAGLE_FORD_07_TAYLOR_GROUP_GRID]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `${LAYER_PREFIX}:eagle_ford_07_taylor_group_grid`,
            TILED: true,
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [EAGLE_FORD_07A_ANACACHO_GRID]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `${LAYER_PREFIX}:eagle_ford_07a_anacacho_grid`,
            TILED: true,
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [EAGLE_FORD_08_AUSTIN_CHALK_GRID]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `${LAYER_PREFIX}:eagle_ford_08_austin_chalk_grid`,
            TILED: true,
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [EAGLE_FORD_09_UPPER_EAGLE_FORD_GRID]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `${LAYER_PREFIX}:eagle_ford_09_upper_eagle_ford_grid`,
            TILED: true,
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [EAGLE_FORD_10_LOWER_EAGLE_FORD_GRID]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `${LAYER_PREFIX}:eagle_ford_10_lower_eagle_ford_grid`,
            TILED: true,
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [EAGLE_FORD_10A_WOODBINE_GRID]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `${LAYER_PREFIX}:eagle_ford_10a_woodbine_grid`,
            TILED: true,
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [EAGLE_FORD_10B_MANESS_GRID]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `${LAYER_PREFIX}:eagle_ford_10b_maness_grid`,
            TILED: true,
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [EAGLE_FORD_11_BUDA_GRID]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `${LAYER_PREFIX}:eagle_ford_11_buda_grid`,
            TILED: true,
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [EAGLE_FORD_11A_DEL_RIO_GRID]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `${LAYER_PREFIX}:eagle_ford_11a_del_rio_grid`,
            TILED: true,
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [EAGLE_FORD_11B_GEORGETOWN_GRID]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `${LAYER_PREFIX}:eagle_ford_11b_georgetown_grid`,
            TILED: true,
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [EAGLE_FORD_11C_EDWARDS_GRID]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `${LAYER_PREFIX}:eagle_ford_11c_edwards_grid`,
            TILED: true,
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [EAGLE_FORD_12_GLEN_ROSE_GRID]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `${LAYER_PREFIX}:eagle_ford_12_glen_rose_grid`,
            TILED: true,
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [EAGLE_FORD_13_PEARSAL_GRID]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `${LAYER_PREFIX}:eagle_ford_13_pearsal_grid`,
            TILED: true,
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [EAGLE_FORD_14_SLIGO_PETTET_GRID]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `${LAYER_PREFIX}:eagle_ford_14_sligo-pettet_grid`,
            TILED: true,
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [ANADARKO_01_STONE_CORRAL_STRUCTURE]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `${LAYER_PREFIX}:anadarko_01_stone_corral_structure`,
            TILED: true,
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [ANADARKO_02_TOP_HUTCHINSON_STRUCTURE]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `${LAYER_PREFIX}:anadarko_02_top_hutchinson_structure`,
            TILED: true,
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [ANADARKO_03_BASE_HUTCHINSON_STRUCTURE]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `${LAYER_PREFIX}:anadarko_03_base_hutchinson_structure`,
            TILED: true,
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [ANADARKO_04_CHESTER_STRUCTURE]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `${LAYER_PREFIX}:anadarko_04_chester_structure`,
            TILED: true,
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [ANADARKO_05_WABAUNSEE_STRUCTURE]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `${LAYER_PREFIX}:anadarko_05_wabaunsee_structure`,
            TILED: true,
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [ANADARKO_06_HEEBNER_SHALE_STRUCTURE]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `${LAYER_PREFIX}:anadarko_06_heebner_shale_structure`,
            TILED: true,
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [ANADARKO_07_LANSING_STRUCTURE]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `${LAYER_PREFIX}:anadarko_07_lansing_structure`,
            TILED: true,
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [ANADARKO_08_HOXBAR_KANSAS_CITY_STRUCTURE]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `${LAYER_PREFIX}:anadarko_08_hoxbar_kansas_city_structure`,
            TILED: true,
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [ANADARKO_09_MARMATON_STRUCTURE]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `${LAYER_PREFIX}:anadarko_09_marmaton_structure`,
            TILED: true,
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [ANADARKO_10_CHEROKEE_STRUCTURE]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `${LAYER_PREFIX}:anadarko_10_cherokee_structure`,
            TILED: true,
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [ANADARKO_11_ATOKA_STRUCTURE]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `${LAYER_PREFIX}:anadarko_11_atoka_structure`,
            TILED: true,
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [ANADARKO_12_TOP_EROSION_STRUCTURE]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `${LAYER_PREFIX}:anadarko_12_top_erosion_structure`,
            TILED: true,
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [ANADARKO_13_SPRINGER_STRUCTURE]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `${LAYER_PREFIX}:anadarko_13_springer_structure`,
            TILED: true,
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [ANADARKO_14_CHESTER_LIME_STRUCTURE]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `${LAYER_PREFIX}:anadarko_14_chester_lime_structure`,
            TILED: true,
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [ANADARKO_15_MISSISSIPPI_LIME_STRUCTURE]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `${LAYER_PREFIX}:anadarko_15_mississippi_lime_structure`,
            TILED: true,
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [ANADARKO_16_WOODFORD_STRUCTURE]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `${LAYER_PREFIX}:anadarko_16_woodford_structure`,
            TILED: true,
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [ANADARKO_17_HOSSTON_STRUCTURE]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `${LAYER_PREFIX}:anadarko_17_hosston_structure`,
            TILED: true,
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [ANADARKO_18_SYLVAN_SHALE_STRUCTURE]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `${LAYER_PREFIX}:anadarko_18_sylvan_shale_structure`,
            TILED: true,
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [ANADARKO_19_VIOLA_STRUCTURE]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `${LAYER_PREFIX}:anadarko_19_viola_structure`,
            TILED: true,
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [ANADARKO_20_SIMPSON_STRUCTURE]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `${LAYER_PREFIX}:anadarko_20_simpson_structure`,
            TILED: true,
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [ANADARKO_21_ARBUCKLE_STRUCTURE]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `${LAYER_PREFIX}:anadarko_21_arbuckle_structure`,
            TILED: true,
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [WOODFORD_LIMIT]: {
      layerGroupZIndex: 36,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `${LAYER_PREFIX}:Woodford Limit`,
            TILED: true,
          },
          zIndex: 31,
          minZoom: 2,
        },
      ],
    },
    [STRUCTURAL_ELEMENTS]: {
      layerGroupZIndex: 36,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `${LAYER_PREFIX}:Structural Elements`,
            TILED: true,
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [SCOOP_STACK_PLAY_AREAS]: {
      layerGroupZIndex: 36,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `${LAYER_PREFIX}:SCOOP STACK Play Area`,
            TILED: true,
          },
          zIndex: 36,
          minZoom: 2,
        },
      ],
    },
    [WOODFORD_ISOPACH]: {
      layerGroupZIndex: 36,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `${LAYER_PREFIX}:woodford_isopach`,
            TILED: true,
          },
          zIndex: 35,
          minZoom: 2,
        },
      ],
    },
    [WOODFORD_STRUCTURE]: {
      layerGroupZIndex: 36,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `${LAYER_PREFIX}:woodford_structure`,
            TILED: true,
          },
          zIndex: 34,
          minZoom: 2,
        },
      ],
    },
    [WOODFORD_FIELDS]: {
      layerGroupZIndex: 36,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `${LAYER_PREFIX}:woodford_fields`,
            TILED: true,
          },
          zIndex: 33,
          minZoom: 2,
        },
      ],
    },
    [WOODFORD_MATURITY]: {
      layerGroupZIndex: 36,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `${LAYER_PREFIX}:woodford_maturity`,
            TILED: true,
          },
          zIndex: 32,
          minZoom: 2,
        },
      ],
    },

    [EAST_GULF_COAST_BASIN_STORAGE_UNIT_LAYERS.AUSTIN]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga_ccs/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `storage_units_group`,
            CQL_FILTER: "formation_name='Austin'",
            STYLES: "sa_storage",
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [EAST_GULF_COAST_BASIN_STORAGE_UNIT_LAYERS.TAYLOR]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga_ccs/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `storage_units_group`,
            CQL_FILTER: "formation_name='Taylor'",
            STYLES: "sa_storage",
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [EAST_GULF_COAST_BASIN_STORAGE_UNIT_LAYERS.SMACKOVER]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga_ccs/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `storage_units_group`,
            CQL_FILTER: "formation_name='Smackover'",
            STYLES: "sa_storage",
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [EAST_GULF_COAST_BASIN_STORAGE_UNIT_LAYERS.VICKSBURG]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga_ccs/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `storage_units_group`,
            CQL_FILTER: "formation_name='Vicksburg'",
            STYLES: "sa_storage",
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [EAST_GULF_COAST_BASIN_STORAGE_UNIT_LAYERS.BOSSIER]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga_ccs/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `storage_units_group`,
            CQL_FILTER: "formation_name='Bossier'",
            STYLES: "sa_storage",
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [EAST_GULF_COAST_BASIN_STORAGE_UNIT_LAYERS.COTTONVALLEY]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga_ccs/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `storage_units_group`,
            CQL_FILTER: "formation_name='CottonValley'",
            STYLES: "sa_storage",
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [EAST_GULF_COAST_BASIN_STORAGE_UNIT_LAYERS.TRINITY]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga_ccs/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `storage_units_group`,
            CQL_FILTER: "formation_name='Trinity'",
            STYLES: "sa_storage",
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [EAST_GULF_COAST_BASIN_STORAGE_UNIT_LAYERS.EAGLEMILLS]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga_ccs/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `storage_units_group`,
            CQL_FILTER: "formation_name='EagleMills'",
            STYLES: "sa_storage",
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [EAST_GULF_COAST_BASIN_STORAGE_UNIT_LAYERS.CLAIBORNE]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga_ccs/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `storage_units_group`,
            CQL_FILTER: "formation_name='Claiborne'",
            STYLES: "sa_storage",
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [EAST_GULF_COAST_BASIN_STORAGE_UNIT_LAYERS.PALUXY]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga_ccs/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `storage_units_group`,
            CQL_FILTER: "formation_name='Paluxy'",
            STYLES: "sa_storage",
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [EAST_GULF_COAST_BASIN_STORAGE_UNIT_LAYERS.NORPHLET]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga_ccs/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `storage_units_group`,
            CQL_FILTER: "formation_name='Norphlet'",
            STYLES: "sa_storage",
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [EAST_GULF_COAST_BASIN_STORAGE_UNIT_LAYERS.LR_CRETA]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga_ccs/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `storage_units_group`,
            CQL_FILTER: "formation_name='Lr_Creta'",
            STYLES: "sa_storage",
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [EAST_GULF_COAST_BASIN_STORAGE_UNIT_LAYERS.PETTET]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga_ccs/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `storage_units_group`,
            CQL_FILTER: "formation_name='Pettet'",
            STYLES: "sa_storage",
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [EAST_GULF_COAST_BASIN_STORAGE_UNIT_LAYERS.LRCOTTONVALLEY]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga_ccs/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `storage_units_group`,
            CQL_FILTER: "formation_name='LrCottonValley'",
            STYLES: "sa_storage",
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [EAST_GULF_COAST_BASIN_STORAGE_UNIT_LAYERS.LR_TUSCA]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga_ccs/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `storage_units_group`,
            CQL_FILTER: "formation_name='Lr_Tusca'",
            STYLES: "sa_storage",
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [EAST_GULF_COAST_BASIN_STORAGE_UNIT_LAYERS.HAYNESVILLE]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga_ccs/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `storage_units_group`,
            CQL_FILTER: "formation_name='Haynesville'",
            STYLES: "sa_storage",
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [EAST_GULF_COAST_BASIN_STORAGE_UNIT_LAYERS.UP_WILCOX]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga_ccs/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `storage_units_group`,
            CQL_FILTER: "formation_name='Up_Wilcox'",
            STYLES: "sa_storage",
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [EAST_GULF_COAST_BASIN_STORAGE_UNIT_LAYERS.SLIGO]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga_ccs/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `storage_units_group`,
            CQL_FILTER: "formation_name='Sligo'",
            STYLES: "sa_storage",
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [EAST_GULF_COAST_BASIN_STORAGE_UNIT_LAYERS.LRSMACKOVER]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga_ccs/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `storage_units_group`,
            CQL_FILTER: "formation_name='LrSmackover'",
            STYLES: "sa_storage",
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [EAST_GULF_COAST_BASIN_STORAGE_UNIT_LAYERS.HOSSTON_TRAVIS_PEAK]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga_ccs/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `storage_units_group`,
            CQL_FILTER: "formation_name='Hosston_Travis_Peak'",
            STYLES: "sa_storage",
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [EAST_GULF_COAST_BASIN_STORAGE_UNIT_LAYERS.RODESSA]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga_ccs/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `storage_units_group`,
            CQL_FILTER: "formation_name='Rodessa'",
            STYLES: "sa_storage",
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [EAST_GULF_COAST_BASIN_STORAGE_UNIT_LAYERS.LR_WILCOX]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga_ccs/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `storage_units_group`,
            CQL_FILTER: "formation_name='Lr_Wilcox'",
            STYLES: "sa_storage",
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [ILLINOIS_BASIN_STORAGE_UNIT_LAYERS.LOWER_MOUNT_SIMON]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `illinois_carbon_storage_units`,
            CQL_FILTER: "zone='Lower Mount Simon'",
            STYLES: "co2_storage_avg_illinois",
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [ILLINOIS_BASIN_STORAGE_UNIT_LAYERS.IRONTON]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `illinois_carbon_storage_units`,
            CQL_FILTER: "zone='Ironton'",
            STYLES: "co2_storage_avg_illinois",
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [ILLINOIS_BASIN_STORAGE_UNIT_LAYERS.KNOX]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `illinois_carbon_storage_units`,
            CQL_FILTER: "zone='Knox'",
            STYLES: "co2_storage_avg_illinois",
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [ILLINOIS_BASIN_STORAGE_UNIT_LAYERS.INTRA_PENNSYLVANIAN]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `illinois_carbon_storage_units`,
            CQL_FILTER: "zone='Intra Pennsylvanian'",
            STYLES: "co2_storage_avg_illinois",
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [ILLINOIS_BASIN_STORAGE_UNIT_LAYERS.LOWER_PENNSYLVANIAN]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `illinois_carbon_storage_units`,
            CQL_FILTER: "zone='Lower Pennsylvanian'",
            STYLES: "co2_storage_avg_illinois",
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [ILLINOIS_BASIN_STORAGE_UNIT_LAYERS.TOP_MISSISSIPPIAN]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `illinois_carbon_storage_units`,
            CQL_FILTER: "zone='Top Mississippian'",
            STYLES: "co2_storage_avg_illinois",
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [ILLINOIS_BASIN_STORAGE_UNIT_LAYERS.MAQUOKETA]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `illinois_carbon_storage_units`,
            CQL_FILTER: "zone='Maquoketa'",
            STYLES: "co2_storage_avg_illinois",
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [ILLINOIS_BASIN_STORAGE_UNIT_LAYERS.MISSISSIPPIAN_STE_GENEVIEVE]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `illinois_carbon_storage_units`,
            CQL_FILTER: "zone='Mississipian Ste Genevieve'",
            STYLES: "co2_storage_avg_illinois",
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [ILLINOIS_BASIN_STORAGE_UNIT_LAYERS.MOUNT_SIMON_SANDSTONE]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `illinois_carbon_storage_units`,
            CQL_FILTER: "zone='Mount Simon Sandstone'",
            STYLES: "co2_storage_avg_illinois",
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [ILLINOIS_BASIN_STORAGE_UNIT_LAYERS.ARGENTA]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `illinois_carbon_storage_units`,
            CQL_FILTER: "zone='Argenta'",
            STYLES: "co2_storage_avg_illinois",
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [ILLINOIS_BASIN_STORAGE_UNIT_LAYERS.EAU_CLAIRE]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `illinois_carbon_storage_units`,
            CQL_FILTER: "zone='Eau Claire'",
            STYLES: "co2_storage_avg_illinois",
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [ILLINOIS_BASIN_STORAGE_UNIT_LAYERS.PENNSYLVANIAN]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `illinois_carbon_storage_units`,
            CQL_FILTER: "zone='Pennsylvanian'",
            STYLES: "co2_storage_avg_illinois",
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [ILLINOIS_BASIN_STORAGE_UNIT_LAYERS.MIDDLE_MOUNT_SIMON]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `illinois_carbon_storage_units`,
            CQL_FILTER: "zone='Middle Mount Simon'",
            STYLES: "co2_storage_avg_illinois",
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [ILLINOIS_BASIN_STORAGE_UNIT_LAYERS.MOCCASIN_SPRINGS]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `illinois_carbon_storage_units`,
            CQL_FILTER: "zone='Moccasin Springs'",
            STYLES: "co2_storage_avg_illinois",
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [ILLINOIS_BASIN_STORAGE_UNIT_LAYERS.INTRA_DEVONIAN]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `illinois_carbon_storage_units`,
            CQL_FILTER: "zone='Intra Devonian'",
            STYLES: "co2_storage_avg_illinois",
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [ILLINOIS_BASIN_STORAGE_UNIT_LAYERS.CAMBRIAN]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `illinois_carbon_storage_units`,
            CQL_FILTER: "zone='Cambrian'",
            STYLES: "co2_storage_avg_illinois",
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [ILLINOIS_BASIN_STORAGE_UNIT_LAYERS.TRENTON]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `illinois_carbon_storage_units`,
            CQL_FILTER: "zone='Trenton'",
            STYLES: "co2_storage_avg_illinois",
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [ILLINOIS_BASIN_STORAGE_UNIT_LAYERS.ULIN]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `illinois_carbon_storage_units`,
            CQL_FILTER: "zone='Ulin'",
            STYLES: "co2_storage_avg_illinois",
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [ILLINOIS_BASIN_STORAGE_UNIT_LAYERS.NEW_ALBANY_SHALE]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `illinois_carbon_storage_units`,
            CQL_FILTER: "zone='New Albany Shale'",
            STYLES: "co2_storage_avg_illinois",
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [ILLINOIS_BASIN_STORAGE_UNIT_LAYERS.ST_PETER_SANDSTONE]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `illinois_carbon_storage_units`,
            CQL_FILTER: "zone='St Peter Sandstone'",
            STYLES: "co2_storage_avg_illinois",
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [ILLINOIS_BASIN_STORAGE_UNIT_LAYERS.DEVONIAN_CARBONATE]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `illinois_carbon_storage_units`,
            CQL_FILTER: "zone='Devonian Carbonate'",
            STYLES: "co2_storage_avg_illinois",
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [ILLINOIS_BASIN_STORAGE_UNIT_LAYERS.BORDEN]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `illinois_carbon_storage_units`,
            CQL_FILTER: "zone='Borden'",
            STYLES: "co2_storage_avg_illinois",
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [ILLINOIS_BASIN_STORAGE_UNIT_LAYERS.INTRA_KNOX]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `illinois_carbon_storage_units`,
            CQL_FILTER: "zone='Intra Knox'",
            STYLES: "co2_storage_avg_illinois",
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [GULF_COAST_STORAGE_UNIT_LAYERS.AMPH_B]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `gulf_coast_carbon_storage_units`,
            CQL_FILTER: "zone='Amph B'",
            STYLES: "co2_storage_avg_style",
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [GULF_COAST_STORAGE_UNIT_LAYERS.ANAHUAC]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `gulf_coast_carbon_storage_units`,
            CQL_FILTER: "zone='Anahuac'",
            STYLES: "co2_storage_avg_style",
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [GULF_COAST_STORAGE_UNIT_LAYERS.FRIO]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `gulf_coast_carbon_storage_units`,
            CQL_FILTER: "zone='Frio'",
            STYLES: "co2_storage_avg_style",
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [GULF_COAST_STORAGE_UNIT_LAYERS.LOWER_CLAIBORNE]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `gulf_coast_carbon_storage_units`,
            CQL_FILTER: "zone='Lower Claiborne'",
            STYLES: "co2_storage_avg_style",
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [GULF_COAST_STORAGE_UNIT_LAYERS.LOWER_MIOCENE]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `gulf_coast_carbon_storage_units`,
            CQL_FILTER: "zone='Lower Miocene'",
            STYLES: "co2_storage_avg_style",
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [GULF_COAST_STORAGE_UNIT_LAYERS.LOWER_PLIOCENE]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `gulf_coast_carbon_storage_units`,
            CQL_FILTER: "zone='Lower Pliocene'",
            STYLES: "co2_storage_avg_style",
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [GULF_COAST_STORAGE_UNIT_LAYERS.LOWER_WILCOX]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `gulf_coast_carbon_storage_units`,
            CQL_FILTER: "zone='Lower Wilcox'",
            STYLES: "co2_storage_avg_style",
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [GULF_COAST_STORAGE_UNIT_LAYERS.MIDDLE_MIOCENE]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `gulf_coast_carbon_storage_units`,
            CQL_FILTER: "zone='Middle Miocene'",
            STYLES: "co2_storage_avg_style",
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [GULF_COAST_STORAGE_UNIT_LAYERS.MIDDLE_WILCOX]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `gulf_coast_carbon_storage_units`,
            CQL_FILTER: "zone='Middle Wilcox'",
            STYLES: "co2_storage_avg_style",
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [GULF_COAST_STORAGE_UNIT_LAYERS.MIDWAY]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `gulf_coast_carbon_storage_units`,
            CQL_FILTER: "zone='Midway'",
            STYLES: "co2_storage_avg_style",
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [GULF_COAST_STORAGE_UNIT_LAYERS.UPPER_CLAIBORNE]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `gulf_coast_carbon_storage_units`,
            CQL_FILTER: "zone='Upper Claiborne'",
            STYLES: "co2_storage_avg_style",
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [GULF_COAST_STORAGE_UNIT_LAYERS.UPPER_MIOCENE]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `gulf_coast_carbon_storage_units`,
            CQL_FILTER: "zone='Upper Miocene'",
            STYLES: "co2_storage_avg_style",
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [GULF_COAST_STORAGE_UNIT_LAYERS.UPPER_PLIOCENE]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `gulf_coast_carbon_storage_units`,
            CQL_FILTER: "zone='Upper Pliocene'",
            STYLES: "co2_storage_avg_style",
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [GULF_COAST_STORAGE_UNIT_LAYERS.UPPER_WILCOX]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `gulf_coast_carbon_storage_units`,
            CQL_FILTER: "zone='Upper Wilcox'",
            STYLES: "co2_storage_avg_style",
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [GULF_COAST_STORAGE_UNIT_LAYERS.GULF_COAST_BASIN_VICKSBURG]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `gulf_coast_carbon_storage_units`,
            CQL_FILTER: "zone='Vicksburg'",
            STYLES: "co2_storage_avg_style",
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [MICHIGAN_STORAGE_UNIT_LAYERS.MICHIGAN_BASIN_BASAL_MOUNT_SIMON_SANDSTONE]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `michigan_carbon_storage_units`,
            CQL_FILTER: "zone='Basal Mount Simon Sandstone'",
            STYLES: "co2_storage_avg_michigan",
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [MICHIGAN_STORAGE_UNIT_LAYERS.MICHIGAN_BASIN_BASS_ISLAND_GROUP]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `michigan_carbon_storage_units`,
            CQL_FILTER: "zone='Bass Island Group'",
            STYLES: "co2_storage_avg_michigan",
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [MICHIGAN_STORAGE_UNIT_LAYERS.MICHIGAN_BASIN_BELL_SHALE]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `michigan_carbon_storage_units`,
            CQL_FILTER: "zone='Bell Shale'",
            STYLES: "co2_storage_avg_michigan",
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [MICHIGAN_STORAGE_UNIT_LAYERS.MICHIGAN_BASIN_BLACK_RIVER]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `michigan_carbon_storage_units`,
            CQL_FILTER: "zone='Black River'",
            STYLES: "co2_storage_avg_michigan",
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [MICHIGAN_STORAGE_UNIT_LAYERS.MICHIGAN_BASIN_BOIS_BLANC_GARDEN_ISLAND]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `michigan_carbon_storage_units`,
            CQL_FILTER: "zone='Bois Blanc-Garden Island'",
            STYLES: "co2_storage_avg_michigan",
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [MICHIGAN_STORAGE_UNIT_LAYERS.MICHIGAN_BASIN_CABOT_HEAD_SHALE]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `michigan_carbon_storage_units`,
            CQL_FILTER: "zone='Cabot Head Shale'",
            STYLES: "co2_storage_avg_michigan",
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [MICHIGAN_STORAGE_UNIT_LAYERS.MICHIGAN_BASIN_DETROIT_RIVER_GROUP]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `michigan_carbon_storage_units`,
            CQL_FILTER: "zone='Detroit River Group'",
            STYLES: "co2_storage_avg_michigan",
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [MICHIGAN_STORAGE_UNIT_LAYERS.MICHIGAN_BASIN_DUNDEE]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `michigan_carbon_storage_units`,
            CQL_FILTER: "zone='Dundee'",
            STYLES: "co2_storage_avg_michigan",
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [MICHIGAN_STORAGE_UNIT_LAYERS.MICHIGAN_BASIN_EAU_CLAIRE]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `michigan_carbon_storage_units`,
            CQL_FILTER: "zone='Eau Claire'",
            STYLES: "co2_storage_avg_michigan",
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [MICHIGAN_STORAGE_UNIT_LAYERS.MICHIGAN_BASIN_GALESVILLE_SANDSTONE]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `michigan_carbon_storage_units`,
            CQL_FILTER: "zone='Galesville Sandstone'",
            STYLES: "co2_storage_avg_michigan",
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [MICHIGAN_STORAGE_UNIT_LAYERS.MICHIGAN_BASIN_GLENWOOD]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `michigan_carbon_storage_units`,
            CQL_FILTER: "zone='Glenwood'",
            STYLES: "co2_storage_avg_michigan",
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [MICHIGAN_STORAGE_UNIT_LAYERS.MICHIGAN_BASIN_HOT_ANTRIM_SHALE]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `michigan_carbon_storage_units`,
            CQL_FILTER: "zone='Hot Antrim Shale'",
            STYLES: "co2_storage_avg_michigan",
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [MICHIGAN_STORAGE_UNIT_LAYERS.MICHIGAN_BASIN_INTRA_EAU_CLAIRE]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `michigan_carbon_storage_units`,
            CQL_FILTER: "zone='Intra Eau Claire'",
            STYLES: "co2_storage_avg_michigan",
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [MICHIGAN_STORAGE_UNIT_LAYERS.MICHIGAN_BASIN_KINDERHOOKIAN]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `michigan_carbon_storage_units`,
            CQL_FILTER: "zone='Kinderhookian'",
            STYLES: "co2_storage_avg_michigan",
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [MICHIGAN_STORAGE_UNIT_LAYERS.MICHIGAN_BASIN_LOWER_MOUNT_SIMON_SANDSTONE]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `michigan_carbon_storage_units`,
            CQL_FILTER: "zone='Lower Mount Simon Sandstone'",
            STYLES: "co2_storage_avg_michigan",
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [MICHIGAN_STORAGE_UNIT_LAYERS.MICHIGAN_BASIN_LWR_DETROIT_RIVER_GROUP]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `michigan_carbon_storage_units`,
            CQL_FILTER: "zone='Lwr Detroit River Group'",
            STYLES: "co2_storage_avg_michigan",
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [MICHIGAN_STORAGE_UNIT_LAYERS.MICHIGAN_BASIN_MIDDLE_MOUNT_SIMON_SANDSTONE]:
      {
        layerGroupZIndex: 35,
        layers: [
          {
            url: `${GIS_URL}/geoserver/saga/wms`,
            fetchType: WMS_FETCH_TYPE,
            params: {
              LAYERS: `michigan_carbon_storage_units`,
              CQL_FILTER: "zone='Middle Mount Simon Sandstone'",
              STYLES: "co2_storage_avg_michigan",
            },
            zIndex: 37,
            minZoom: 2,
          },
        ],
      },
    [MICHIGAN_STORAGE_UNIT_LAYERS.MICHIGAN_BASIN_MID_SALINA]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `michigan_carbon_storage_units`,
            CQL_FILTER: "zone='Mid Salina'",
            STYLES: "co2_storage_avg_michigan",
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [MICHIGAN_STORAGE_UNIT_LAYERS.MICHIGAN_BASIN_MOUNT_SIMON_SANDSTONE]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `michigan_carbon_storage_units`,
            CQL_FILTER: "zone='Mount Simon Sandstone'",
            STYLES: "co2_storage_avg_michigan",
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [MICHIGAN_STORAGE_UNIT_LAYERS.MICHIGAN_BASIN_MUNISING_GROUP]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `michigan_carbon_storage_units`,
            CQL_FILTER: "zone='Munising Group'",
            STYLES: "co2_storage_avg_michigan",
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [MICHIGAN_STORAGE_UNIT_LAYERS.MICHIGAN_BASIN_NIAGRAN_BASE_SALINA]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `michigan_carbon_storage_units`,
            CQL_FILTER: "zone='Niagran-Base Salina'",
            STYLES: "co2_storage_avg_michigan",
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [MICHIGAN_STORAGE_UNIT_LAYERS.MICHIGAN_BASIN_PRAIRIE_DU_CHIEN]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `michigan_carbon_storage_units`,
            CQL_FILTER: "zone='Prarie du Chien'",
            STYLES: "co2_storage_avg_michigan",
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [MICHIGAN_STORAGE_UNIT_LAYERS.MICHIGAN_BASIN_QUEENSTON_SHALE]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `michigan_carbon_storage_units`,
            CQL_FILTER: "zone='Queenston Shale'",
            STYLES: "co2_storage_avg_michigan",
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [MICHIGAN_STORAGE_UNIT_LAYERS.MICHIGAN_BASIN_SALINA_C]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `michigan_carbon_storage_units`,
            CQL_FILTER: "zone='Salina C'",
            STYLES: "co2_storage_avg_michigan",
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [MICHIGAN_STORAGE_UNIT_LAYERS.MICHIGAN_BASIN_ST_PETER_SANDSTONE]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `michigan_carbon_storage_units`,
            CQL_FILTER: "zone='St Peter Sandstone'",
            STYLES: "co2_storage_avg_michigan",
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [MICHIGAN_STORAGE_UNIT_LAYERS.MICHIGAN_BASIN_TOP_SALINA_GROUP]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `michigan_carbon_storage_units`,
            CQL_FILTER: "zone='Top Salina Group'",
            STYLES: "co2_storage_avg_michigan",
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [MICHIGAN_STORAGE_UNIT_LAYERS.MICHIGAN_BASIN_TRAVERSE]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `michigan_carbon_storage_units`,
            CQL_FILTER: "zone='Traverse'",
            STYLES: "co2_storage_avg_michigan",
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [MICHIGAN_STORAGE_UNIT_LAYERS.MICHIGAN_BASIN_TREMPEALEAU]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `michigan_carbon_storage_units`,
            CQL_FILTER: "zone='Trempealeau'",
            STYLES: "co2_storage_avg_michigan",
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [MICHIGAN_STORAGE_UNIT_LAYERS.MICHIGAN_BASIN_TRENTON]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `michigan_carbon_storage_units`,
            CQL_FILTER: "zone='Trenton'",
            STYLES: "co2_storage_avg_michigan",
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [MICHIGAN_STORAGE_UNIT_LAYERS.MICHIGAN_BASIN_UTICA_SHALE]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `michigan_carbon_storage_units`,
            CQL_FILTER: "zone='Utica Shale'",
            STYLES: "co2_storage_avg_michigan",
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },

    [APPALACHIA_STORAGE_UNIT_LAYERS.APPALACHIA_BASIN_BASEMENT]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `appalachia_carbon_storage_units`,
            CQL_FILTER: "zone='Basement'",
            STYLES: "co2_storage_avg_appalachia",
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [APPALACHIA_STORAGE_UNIT_LAYERS.APPALACHIA_BASIN_BEREA_BRALLIER]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `appalachia_carbon_storage_units`,
            CQL_FILTER: "zone='Berea Brallier'",
            STYLES: "co2_storage_avg_appalachia",
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [APPALACHIA_STORAGE_UNIT_LAYERS.APPALACHIA_BASIN_BLACK_RIVER]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `appalachia_carbon_storage_units`,
            CQL_FILTER: "zone='Black River'",
            STYLES: "co2_storage_avg_appalachia",
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [APPALACHIA_STORAGE_UNIT_LAYERS.APPALACHIA_BASIN_CINCINNATI_GROUP]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `appalachia_carbon_storage_units`,
            CQL_FILTER: "zone='Cincinnati Group'",
            STYLES: "co2_storage_avg_appalachia",
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [APPALACHIA_STORAGE_UNIT_LAYERS.APPALACHIA_BASIN_CLINTON_MEDINA]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `appalachia_carbon_storage_units`,
            CQL_FILTER: "zone='Clinton Medina'",
            STYLES: "co2_storage_avg_appalachia",
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [APPALACHIA_STORAGE_UNIT_LAYERS.APPALACHIA_BASIN_COOPER_RIDGE_DOLOMITE]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `appalachia_carbon_storage_units`,
            CQL_FILTER: "zone='Cooper Ridge Dolomite'",
            STYLES: "co2_storage_avg_appalachia",
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [APPALACHIA_STORAGE_UNIT_LAYERS.APPALACHIA_BASIN_HELDERBERG_LIMESTONE]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `appalachia_carbon_storage_units`,
            CQL_FILTER: "zone='Helderberg Limestone'",
            STYLES: "co2_storage_avg_appalachia",
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [APPALACHIA_STORAGE_UNIT_LAYERS.APPALACHIA_BASIN_HURON_SHALE]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `appalachia_carbon_storage_units`,
            CQL_FILTER: "zone='Huron Shale'",
            STYLES: "co2_storage_avg_appalachia",
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [APPALACHIA_STORAGE_UNIT_LAYERS.APPALACHIA_BASIN_JAVA_OLENTANGY]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `appalachia_carbon_storage_units`,
            CQL_FILTER: "zone='Java Olentangy'",
            STYLES: "co2_storage_avg_appalachia",
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [APPALACHIA_STORAGE_UNIT_LAYERS.APPALACHIA_BASIN_KNOX]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `appalachia_carbon_storage_units`,
            CQL_FILTER: "zone='Knox'",
            STYLES: "co2_storage_avg_appalachia",
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [APPALACHIA_STORAGE_UNIT_LAYERS.APPALACHIA_BASIN_LOCKPORT_DOLOMITE]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `appalachia_carbon_storage_units`,
            CQL_FILTER: "zone='Lockport Dolomite'",
            STYLES: "co2_storage_avg_appalachia",
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [APPALACHIA_STORAGE_UNIT_LAYERS.APPALACHIA_BASIN_LOWER_COOPER_RIDGE_DOLOMITE]:
      {
        layerGroupZIndex: 35,
        layers: [
          {
            url: `${GIS_URL}/geoserver/saga/wms`,
            fetchType: WMS_FETCH_TYPE,
            params: {
              LAYERS: `appalachia_carbon_storage_units`,
              CQL_FILTER: "zone='Lower Cooper Ridge Dolomite'",
              STYLES: "co2_storage_avg_appalachia",
            },
            zIndex: 37,
            minZoom: 2,
          },
        ],
      },
    [APPALACHIA_STORAGE_UNIT_LAYERS.APPALACHIA_BASIN_MARCELLUS]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `appalachia_carbon_storage_units`,
            CQL_FILTER: "zone='Marcellus'",
            STYLES: "co2_storage_avg_appalachia",
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [APPALACHIA_STORAGE_UNIT_LAYERS.APPALACHIA_BASIN_MARRYVILLE]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `appalachia_carbon_storage_units`,
            CQL_FILTER: "zone='Marryville'",
            STYLES: "co2_storage_avg_appalachia",
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [APPALACHIA_STORAGE_UNIT_LAYERS.APPALACHIA_BASIN_MOUNT_SIMON_SANDSTONE]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `appalachia_carbon_storage_units`,
            CQL_FILTER: "zone='Mount Simon Sandstone'",
            STYLES: "co2_storage_avg_appalachia",
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [APPALACHIA_STORAGE_UNIT_LAYERS.APPALACHIA_BASIN_NULICHUCKY_SHALE]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `appalachia_carbon_storage_units`,
            CQL_FILTER: "zone='Nulichucky Shale'",
            STYLES: "co2_storage_avg_appalachia",
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [APPALACHIA_STORAGE_UNIT_LAYERS.APPALACHIA_BASIN_OHIO_SHALE]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `appalachia_carbon_storage_units`,
            CQL_FILTER: "zone='Ohio Shale'",
            STYLES: "co2_storage_avg_appalachia",
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [APPALACHIA_STORAGE_UNIT_LAYERS.APPALACHIA_BASIN_ONONDAGA_ORISKANY]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `appalachia_carbon_storage_units`,
            CQL_FILTER: "zone='Onondaga Oriskany'",
            STYLES: "co2_storage_avg_appalachia",
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [APPALACHIA_STORAGE_UNIT_LAYERS.APPALACHIA_BASIN_POINT_PLEASANT]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `appalachia_carbon_storage_units`,
            CQL_FILTER: "zone='Point Pleasant'",
            STYLES: "co2_storage_avg_appalachia",
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [APPALACHIA_STORAGE_UNIT_LAYERS.APPALACHIA_BASIN_QUEENSTON_SHALE]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `appalachia_carbon_storage_units`,
            CQL_FILTER: "zone='Queenston Shale'",
            STYLES: "co2_storage_avg_appalachia",
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [APPALACHIA_STORAGE_UNIT_LAYERS.APPALACHIA_BASIN_RHINESTREET_SHALE]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `appalachia_carbon_storage_units`,
            CQL_FILTER: "zone='Rhinestreet Shale'",
            STYLES: "co2_storage_avg_appalachia",
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [APPALACHIA_STORAGE_UNIT_LAYERS.APPALACHIA_BASIN_ROCHESTER_SHALE]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `appalachia_carbon_storage_units`,
            CQL_FILTER: "zone='Rochester Shale'",
            STYLES: "co2_storage_avg_appalachia",
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [APPALACHIA_STORAGE_UNIT_LAYERS.APPALACHIA_BASIN_ROSE_RUN]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `appalachia_carbon_storage_units`,
            CQL_FILTER: "zone='Rose Run'",
            STYLES: "co2_storage_avg_appalachia",
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [APPALACHIA_STORAGE_UNIT_LAYERS.APPALACHIA_BASIN_SALINA_C]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `appalachia_carbon_storage_units`,
            CQL_FILTER: "zone='Salina C'",
            STYLES: "co2_storage_avg_appalachia",
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [APPALACHIA_STORAGE_UNIT_LAYERS.APPALACHIA_BASIN_SALINA_EVAPORITES]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `appalachia_carbon_storage_units`,
            CQL_FILTER: "zone='Salina Evaporites'",
            STYLES: "co2_storage_avg_appalachia",
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [APPALACHIA_STORAGE_UNIT_LAYERS.APPALACHIA_BASIN_SALINA_GROUP]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `appalachia_carbon_storage_units`,
            CQL_FILTER: "zone='Salina Group'",
            STYLES: "co2_storage_avg_appalachia",
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [APPALACHIA_STORAGE_UNIT_LAYERS.APPALACHIA_BASIN_TRENTON]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `appalachia_carbon_storage_units`,
            CQL_FILTER: "zone='Trenton'",
            STYLES: "co2_storage_avg_appalachia",
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [APPALACHIA_STORAGE_UNIT_LAYERS.APPALACHIA_BASIN_UTICA_SHALE]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `appalachia_carbon_storage_units`,
            CQL_FILTER: "zone='Utica Shale'",
            STYLES: "co2_storage_avg_appalachia",
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
    [APPALACHIA_STORAGE_UNIT_LAYERS.APPALACHIA_BASIN_WELLS_CREEK]: {
      layerGroupZIndex: 35,
      layers: [
        {
          url: `${GIS_URL}/geoserver/saga/wms`,
          fetchType: WMS_FETCH_TYPE,
          params: {
            LAYERS: `appalachia_carbon_storage_units`,
            CQL_FILTER: "zone='Wells Creek'",
            STYLES: "co2_storage_avg_appalachia",
          },
          zIndex: 37,
          minZoom: 2,
        },
      ],
    },
  };
};
