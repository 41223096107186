import { FC, SyntheticEvent } from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";

import {
  ReleaseVersionsProps,
  ReleaseVersionsTypes,
} from "../../../../types/information/information";

import {
  AccordionContent,
  AccordionHeadingText,
  AccordionImage,
  AccordionSubContent,
  CustomTypography,
} from "../StyledAccordions";

import AppalachiaStorageCapacityLayers from "../../../../assets/images/release-notes/AppalachiaStorageCapacityLayers.png";
import AssetEconomicsEnhancements from "../../../../assets/images/release-notes/AssetEconomicsEnhancements.png";
import CashflowChartFilter from "../../../../assets/images/release-notes/CashflowChartFilter.png";
import PermitPseudoSurvey from "../../../../assets/images/release-notes/PermitPseudoSurvey.png";
import PressureDataWFTs from "../../../../assets/images/release-notes/PressureDataWFTs.png";

const Version20250313: FC<ReleaseVersionsProps> = ({ expanded, onChange }) => {
  const handleChange =
    (panel: ReleaseVersionsTypes) =>
    (_: SyntheticEvent, isExpanded: boolean) => {
      onChange(isExpanded ? panel : "");
    };

  return (
    <Accordion
      expanded={expanded === "Version20250313"}
      onChange={handleChange("Version20250313")}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <AccordionHeadingText sx={{ width: "80%", flexShrink: 0 }}>
          Version 2025.03.13
        </AccordionHeadingText>
        <AccordionHeadingText sx={{ color: "rgba(255, 255, 255, 0.70)" }}>
          03/13/2025
        </AccordionHeadingText>
      </AccordionSummary>
      <AccordionDetails>
        <CustomTypography>
          This release includes enhancements to Asset Economics & Cashflow
          charts, the addition of Permit Pseudo Surveys and WFTs, and new
          Appalachia Carbon Storage Layers.
        </CustomTypography>
      </AccordionDetails>
      <AccordionContent>
        • Asset Economics & Cash Flow Chart Enhancements
      </AccordionContent>
      <AccordionSubContent>
        • Users can now view and filter Asset Economics and Cash Flow of a
        selected group of wells, through the <strong>Settings</strong> section
        on the top right corner of each panel
      </AccordionSubContent>
      <AccordionImage
        src={AssetEconomicsEnhancements}
        width="100%"
        draggable={false}
      />
      <AccordionImage
        src={CashflowChartFilter}
        width="100%"
        draggable={false}
      />
      <AccordionContent>• Permit Pseudo Surveys</AccordionContent>
      <AccordionSubContent>
        • Permit Pseudo Surveys are created by connecting the reported surface
        and bottom hole locations of permits. This feature allows users to
        access the Permit Path and Permit Surface & Bottom Hole Location within
        the application. Users can toggle these options on or off through{" "}
        <strong>MAP SETTINGS</strong> under the
        <strong> Well & Permit Styling</strong> section.
      </AccordionSubContent>
      <AccordionImage src={PermitPseudoSurvey} width="100%" draggable={false} />

      <AccordionContent>
        • Pressure Data - Wireline Formation Tests (WFTs)
      </AccordionContent>
      <AccordionSubContent>
        • Wireline Formation Test (WFT) values and charts are now live in Well
        Data Analytics. Users can access Drill Stem Tests (DSTs) and Wireline
        Formation Tests (WFTs) through the Pressure Data section in the well
        panel, enabling instant visualization of available information and
        Incremental Shut-in charts alongside well performance data. To access
        Pressure Data:
      </AccordionSubContent>
      <AccordionSubContent>
        <AccordionSubContent>
          ▪ Apply Filters – Use the <strong>Has DST</strong> or{" "}
          <strong>Has WFT</strong> filters to search for wells with pressure
          data. <br />
          ▪ Open the Well Panel – Hover over or right-click on a well spot to
          open the panel. <br />▪ Navigate to Pressure Data – Go to the{" "}
          <strong>WELL INFO</strong> section and scroll to{" "}
          <strong>Pressure Data</strong>. <br />
          ▪ View Available Data – The panel will display DSTs and WFTs with
          details such as: <br />
          <AccordionSubContent>
            ▪ DST/WFT Number & Reported Formation <br />
            ▪ TGS Landing Zone <br />
            ▪ Temperature <br />
            ▪ Top & Bottom Depths <br />
            ▪ 1st & 2nd Flow/Shut-in Pressure <br />
            ▪ Recovery Remarks <br />
          </AccordionSubContent>
        </AccordionSubContent>
      </AccordionSubContent>
      <AccordionSubContent>
        • If available, users can also visualize build-up curves (Incremental
        Shut-In) and Horner Plots alongside well performance data.
      </AccordionSubContent>
      <AccordionSubContent>
        • All pressure data can be exported via <strong>Export Manager</strong>{" "}
        or by exporting individual well panel information from the top right
        corner of the panel.
      </AccordionSubContent>
      <AccordionImage src={PressureDataWFTs} width="100%" draggable={false} />

      <AccordionContent>
        • CO2 Storage Assessment Additional Basins
      </AccordionContent>
      <AccordionSubContent>
        • The recently added Appalachia Basin Storage Capacity layers are now
        available in Well Data Analytics under Culture Layers in{" "}
        <strong>MAP SETTINGS</strong>.
      </AccordionSubContent>
      <AccordionImage
        src={AppalachiaStorageCapacityLayers}
        width="100%"
        draggable={false}
      />
    </Accordion>
  );
};

export default Version20250313;
