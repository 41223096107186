import { useCallback, useEffect, useMemo, useState } from "react";

import {
  DstPressureDataWellPanel,
  WftPressureDataWellPanel,
} from "../../../types/panels/wellPanel/pressureDataType";
import {
  MapLayer,
  WellSystemData,
} from "../../../types/panels/wellPanel/wellPanel";

import {
  DST_PRESSURE_DATA_TEST_TYPES,
  WFT_PRESSURE_DATA_TEST_TYPES,
} from "../../../constants/panels/wellPanel/pressureData";

import usePanelsStore from "../../../store/panels/panelsStore";

import { wellPanelSectionFlags } from "../../../utils/panel/wellPanel/sectionFlags";

import DstPanel from "./DstPanel";
import WftPanel from "./WftPanel";

interface PressureDataPanelProps {
  currentViewedWellSystem: WellSystemData;
  layer: MapLayer;
  groupedWellID: number;
  setShowPressureDataPanel: React.Dispatch<React.SetStateAction<boolean>>;
}

const PressureDataPanel = ({
  currentViewedWellSystem,
  layer,
  groupedWellID,
  setShowPressureDataPanel,
}: PressureDataPanelProps) => {
  const updateActiveWellPanelPressureData = usePanelsStore(
    (state) => state.updateActiveWellPanelPressureData
  );
  const handleClosePressureDataPanel = useCallback(() => {
    if (currentViewedWellSystem)
      updateActiveWellPanelPressureData(
        currentViewedWellSystem?.wellId,
        layer,
        groupedWellID
      );
  }, [currentViewedWellSystem]);

  const sectionFlags = useMemo(
    () => wellPanelSectionFlags(currentViewedWellSystem),
    [currentViewedWellSystem]
  );

  const pressureDataList = useMemo(
    () => [
      ...(currentViewedWellSystem?.dstPressuredata
        ? currentViewedWellSystem?.dstPressuredata
        : []),
      ...(currentViewedWellSystem?.wftPressureData
        ? currentViewedWellSystem?.wftPressureData
        : []),
    ],
    [
      currentViewedWellSystem?.dstPressuredata,
      currentViewedWellSystem?.wftPressureData,
    ]
  );
  const activePressureDataContent = useMemo(() => {
    if (sectionFlags.hasPressureData && pressureDataList.length) {
      return pressureDataList.find((data) => data.IsSelected);
    } else {
      return undefined;
    }
  }, [pressureDataList, sectionFlags]);

  useEffect(() => {
    setShowPressureDataPanel(!!activePressureDataContent);
  }, [activePressureDataContent]);

  const getPanelComponent = useCallback(() => {
    switch (activePressureDataContent?.DocType) {
      case DST_PRESSURE_DATA_TEST_TYPES.DST:
      case DST_PRESSURE_DATA_TEST_TYPES.DST_PLUS:
        return (
          <DstPanel
            panelContent={
              activePressureDataContent as DstPressureDataWellPanel | undefined
            }
            handleCloseDstPanel={handleClosePressureDataPanel}
          />
        );

      case WFT_PRESSURE_DATA_TEST_TYPES.WFT_PLUS:
        return (
          <WftPanel
            panelContent={
              activePressureDataContent as WftPressureDataWellPanel | undefined
            }
            handleCloseWftPanel={handleClosePressureDataPanel}
          />
        );

      default:
        return <></>;
    }
  }, [sectionFlags, activePressureDataContent]);

  return (
    <>
      {sectionFlags.hasPressureData && activePressureDataContent && (
        <>{getPanelComponent()}</>
      )}
    </>
  );
};

export default PressureDataPanel;
