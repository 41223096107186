import {
  AnalysisChartsMapper,
  ChartTypesMapper,
} from "../../types/charts/chartType/chartType";

import config from "../../configs/appSettings";

import { default as AssetEconomicsSelector } from "../../assets/images/AssetEconomicsSelector.svg";
import { default as CashFlowSelector } from "../../assets/images/CashFlowSelector.svg";
import { default as ProductionPlotSelector } from "../../assets/images/ProductionPlotSelector.svg";
import { default as ScatterPlotSelector } from "../../assets/images/ScatterPlotSelector.svg";
import { default as TypeCurveSelector } from "../../assets/images/TypeCurveSelector.svg";

//Plotly Tick date formatting
export const mmmYYYYTickFormat = "%b %Y";
export const YYYYTickFormat = "%Y";

//CHART TYPES
export const CHART_TYPES = {
  PRODUCTION_PLOT: "Production Plot",
  TYPE_CURVE: "Type Curve",
  BAR: "Bar",
  SCATTER_PLOT: "Scatter Plot",
  ASSET_ECONOMICS: "Asset Economics",
  CASH_FLOW: "Cash Flow",
} as const;

export const CHARTS_FIELDS_TO_CHART_TYPE_MAPPER: { [key: string]: string } = {
  [CHART_TYPES.PRODUCTION_PLOT]: "PRODUCTION_PLOT",
  [CHART_TYPES.TYPE_CURVE]: "TYPE_CURVE",
  [CHART_TYPES.SCATTER_PLOT]: "scatter",
  [CHART_TYPES.ASSET_ECONOMICS]: "asset_economics",
};

export const PRODUCTION_PLOT_ANALYSIS: ChartTypesMapper = {
  type: CHART_TYPES.PRODUCTION_PLOT,
  src: ProductionPlotSelector,
};

export const TYPE_CURVE_ANALYSIS: ChartTypesMapper = {
  type: CHART_TYPES.TYPE_CURVE,
  src: TypeCurveSelector,
};

export const SCATTER_PLOT_ANALYSIS: ChartTypesMapper = {
  type: CHART_TYPES.SCATTER_PLOT,
  src: ScatterPlotSelector,
};

export const ASSET_ECONOMICS_ANALYSIS: ChartTypesMapper = {
  type: CHART_TYPES.ASSET_ECONOMICS,
  src: AssetEconomicsSelector,
  show200MaxTooltip: true,
  hideOption: !config.isEconomicsAggregationEnabled,
};

export const CASH_FLOW_ANALYSIS: ChartTypesMapper = {
  type: CHART_TYPES.CASH_FLOW,
  src: CashFlowSelector,
  show200MaxTooltip: true,
  hideOption: !config.isEconomicsAggregationEnabled,
};

export const ANALYSIS_PANE_CHART_LIST: AnalysisChartsMapper = {
  [CHART_TYPES.PRODUCTION_PLOT]: PRODUCTION_PLOT_ANALYSIS,
  [CHART_TYPES.TYPE_CURVE]: TYPE_CURVE_ANALYSIS,
  [CHART_TYPES.SCATTER_PLOT]: SCATTER_PLOT_ANALYSIS,
  [CHART_TYPES.ASSET_ECONOMICS]: ASSET_ECONOMICS_ANALYSIS,
  [CHART_TYPES.CASH_FLOW]: CASH_FLOW_ANALYSIS,
};

export const ANALYSIS_LIST_KEY = Object.keys(ANALYSIS_PANE_CHART_LIST);

export const CHART_SCALING_TYPE = {
  LINEAR: "linear",
  LOGARITHMIC: "logarithmic",
} as const;

export const CHART_AXIS = {
  LEFT: "left",
  RIGHT: "right",
} as const;

export const CHART_ACCUMULATION_METHOD = {
  MONTHLY: "MONTHLY",
  CUMULATIVE: "CUMULATIVE",
} as const;

export const CHART_DATA_SOURCE = {
  PRODUCTION: "PRODUCTION",
  PRODUCTION_FORECAST: "PRODUCTION_FORECAST",
  PRODUCTION_UNION_FORECAST: "PRODUCTION_UNION_FORECAST",
  INJECTION: "INJECTION",
  VENTFLARE: "VENTFLARE",
} as const;

export const CHART_DATA_STATE = {
  POST_SEARCH: "post_search",
  PRE_SEARCH: "pre_search",
  INTER_SEARCH: "inter_search",
} as const;

export const CHART_STATUS = {
  EXPANDED: "expanded",
  COLLAPSED: "collapsed",
} as const;

export const CHART_GROUP_BY_TYPE = {
  INDIVIDUAL_WELLS: "NONE",
  DEFAULT: "Default",
} as const;

export const AGGREGATION_TYPE: { [key: string]: string } = {
  [CHART_TYPES.PRODUCTION_PLOT]: "sum",
  [CHART_TYPES.TYPE_CURVE]: "average",
};

export const MAX_INDIVIDUAL_WELLS_COUNT = 10;
export const INDIVIDUAL_WELLS_BATCH_LABEL = " (First 10)";

export const CHART_ALL_OTHERS_KEY = "all others";

export const PERMIT_CHARTS: string[] = [CHART_TYPES.SCATTER_PLOT];

export const MAX_WELLS_COUNT_ASSET_ECONOMICS = 200000;
export const MAX_WELLS_UWI_COUNT = 60000;

export const MONTHS_NUMERIC_VALUE: { [key: string]: string } = {
  Jan: "01",
  Feb: "02",
  Mar: "03",
  Apr: "04",
  May: "05",
  Jun: "06",
  Jul: "07",
  Aug: "08",
  Sep: "09",
  Oct: "10",
  Nov: "11",
  Dec: "12",
};

export const MONTHS_NUMERIC_TO_LABEL: { [key: string]: string } = {
  "1": "Jan",
  "2": "Feb",
  "3": "Mar",
  "4": "Apr",
  "5": "May",
  "6": "Jun",
  "7": "Jul",
  "8": "Aug",
  "9": "Sep",
  "10": "Oct",
  "11": "Nov",
  "12": "Dec",
};
