import { useState } from "react";

import axios from "axios";

import { SearchRequestPayload } from "../../types/common/api";
import { FilterUwiByAttributePayload } from "../../types/common/search";
import { GetAttribWellCountResponse } from "../../types/common/useSearchData";
import { WellCounts, WellData } from "../../types/common/wells";
import { WellDataResponse } from "../../types/grid";
import { GridFilterPayload } from "../../types/grid/filter";

import config from "../../configs/appSettings";

import { RECORD_TYPE } from "../../constants/attributes";
import {
  MAX_WELLS_COUNT_ASSET_ECONOMICS,
  MAX_WELLS_UWI_COUNT,
} from "../../constants/charts/charts";
import { FILTER_IS_ANY_OPERATOR } from "../../constants/grid/filterOperator";
import { RECORD_TYPES } from "../../constants/panels/searchPanel/queryBuilder/attributeValues";
import { IN } from "../../constants/panels/searchPanel/queryBuilder/opTypes";

import useMapSelectionStore from "../../store/map/selection/mapSelectionStore";
import useUWISearchStore from "../../store/search/uwiSearch/searchUWIStore";

import { getFirstBatch } from "../../services/grid/allWellData";

import { settledPromise } from "../../utils/api/request";
import {
  createBoundsByKey,
  createRecordTypeBounds,
} from "../../utils/common/boundsData";

import { callServiceAPI } from "../../action/callServiceAPI";
import useAttributeOptionsPostProcess from "../common/useAttributeOptionsPostProcess";
import useUnitOfMeasure from "../common/useUnitOfMeasure";
import useUwiFileUpload from "../search/useUwiFileUpload";
import useChartSearchRequest from "./useChartSearchRequest";

const useFilterByConfig = () => {
  const [wellAttributes, setWellAttributes] = useState<WellCounts[]>([]);
  const [isWellCountAttributesLoading, setIsWellCountAttributesLoading] =
    useState(false);
  const [wellCountAttributSuccess, setWellCountAttributeSuccess] =
    useState(false);

  const [filteredUwis, setFilterUwi] = useState<string[]>([]);
  const [filteredUwisLoading, setFilterUwiLoading] = useState(false);
  const [filterUwisSuccess, setFilterUwisSiccess] = useState(false);

  const { formatOptions, processOptions } = useAttributeOptionsPostProcess();
  const { isMetricOnSelection } = useUnitOfMeasure();
  const { getUWIFileIdWithRetry } = useUwiFileUpload();

  const uploadedUWIFile = useUWISearchStore((state) => state.uploadedUWIFile);

  const selectedWellUwis = useMapSelectionStore(
    (state) => state.selectedWellUwis
  );

  const { buildFilterByPostRequest } = useChartSearchRequest();

  const getWellCountByAttribute = async (attributeKey: string) => {
    const countUrl = `${config.endpoints.wellService}api/wells/count/${attributeKey}`;

    let body: SearchRequestPayload = {
      offset: 0,
      pageLimit: 1,
      isMetric: isMetricOnSelection,
      bounds: [createRecordTypeBounds(RECORD_TYPES.WELL)],
    };

    if (selectedWellUwis.length > MAX_WELLS_UWI_COUNT) {
      body = {
        ...body,
        ...buildFilterByPostRequest("Asset Economics"),
      };
    } else {
      body = {
        ...body,
        uwis: selectedWellUwis,
      };
    }

    try {
      setIsWellCountAttributesLoading(true);
      setWellCountAttributeSuccess(false);
      const initialResponse = await callServiceAPI<GetAttribWellCountResponse>(
        countUrl,
        body,
        getUWIFileIdWithRetry,
        uploadedUWIFile,
        false
      );

      if (!initialResponse || !("data" in initialResponse)) return;

      const totalCount = initialResponse?.data?.totalCount;
      const pageLimit = config.countPageLimit;
      const totalIterations = Math.ceil(totalCount / pageLimit);

      if (totalIterations > 0) {
        const responses = [...Array(totalIterations).keys()].map((_, i) => {
          return axios.post<GetAttribWellCountResponse>(
            countUrl,
            {
              ...body,
              offset: i * pageLimit,
              pageLimit,
            },
            {
              ignoreResponseInterceptor: true,
            }
          );
        });

        settledPromise(responses, (values) => {
          const allData = values.flatMap((response) => response.data.countData);
          const attributeOptions = allData.filter(
            (countDataElement) =>
              countDataElement.attributeValue !== "Blank" &&
              countDataElement.attributeValue !== ""
          );

          //post process options based on attribute
          const processedAttrOptions = processOptions(
            attributeKey,
            attributeOptions
          );

          //format options based on attribute data type
          const formattedAttrOptions = formatOptions(
            attributeKey,
            processedAttrOptions
          );

          setWellAttributes(formattedAttrOptions);

          setIsWellCountAttributesLoading(false);
          setWellCountAttributeSuccess(true);
        });
      } else {
        setIsWellCountAttributesLoading(false);
      }
    } catch {
      setIsWellCountAttributesLoading(false);
      setWellCountAttributeSuccess(false);
    }
  };

  const buildFilterSearchQuery = (attributeKey: string, value: string[]) => {
    return [
      {
        filter: attributeKey,
        operations: [
          {
            type: FILTER_IS_ANY_OPERATOR.payload,
            values: [value],
          },
        ],
      },
    ] as GridFilterPayload[];
  };

  const getUwisByAttribute = async (filter: GridFilterPayload[]) => {
    const wellsOnlyBounds = createBoundsByKey(
      RECORD_TYPE,
      [RECORD_TYPES.WELL],
      IN.key
    );

    let searchPayload = {
      bounds: [wellsOnlyBounds],
      filters: filter,
      columns: ["UWI"],
      isMetric: isMetricOnSelection,
    } as FilterUwiByAttributePayload;

    if (selectedWellUwis.length > MAX_WELLS_UWI_COUNT) {
      searchPayload = {
        ...searchPayload,
        ...buildFilterByPostRequest("Asset Economics"),
      };
    } else {
      searchPayload = {
        ...searchPayload,
        uwis: selectedWellUwis,
      };
    }

    try {
      setFilterUwiLoading(true);
      setFilterUwisSiccess(false);
      setFilterUwi([]);

      const firstBatchResponse = await getFirstBatch(
        searchPayload,
        config.searchPageLimit
      );
      const totalCount = firstBatchResponse.totalCount;
      const totalIterations = Math.ceil(totalCount / config.searchPageLimit);

      if (totalIterations > 1) {
        const responses = [...Array(totalIterations).keys()]
          .filter((num) => {
            return num !== 0;
          })
          .map((num) => {
            return axios.post(
              `${searchPayload}`,
              {
                ...searchPayload,
                offset: num * config.searchPageLimit,
                pageLimit: config.searchPageLimit,
              },
              {
                ignoreResponseInterceptor: true,
              }
            );
          });

        settledPromise(responses, (values: { data: WellDataResponse }[]) => {
          const remainingData = values.flatMap(
            (response) => response.data.wells
          );
          const allUwis = firstBatchResponse.wells.concat(remainingData);
          const mappedUwiValues = allUwis.map(
            (well: WellData) => well.UWI ?? ""
          );
          setFilterUwiLoading(false);
          setFilterUwisSiccess(true);
          setFilterUwi(mappedUwiValues);
        });
      } else {
        const mappedUwiValues = firstBatchResponse.wells.map(
          (well: WellData) => well.UWI ?? ""
        );
        setFilterUwiLoading(false);
        setFilterUwisSiccess(true);
        setFilterUwi(mappedUwiValues);
      }
    } catch {
      setFilterUwiLoading(false);
      setFilterUwisSiccess(false);
    }
  };

  const filterUwisByAttributeValue = (
    attributeKey: string,
    value: string[]
  ) => {
    if (selectedWellUwis.length > MAX_WELLS_COUNT_ASSET_ECONOMICS) return;

    const filterQuery = buildFilterSearchQuery(attributeKey, value);
    return getUwisByAttribute(filterQuery);
  };

  return {
    wellAttributes,
    isWellCountAttributesLoading,
    wellCountAttributSuccess,
    filteredUwis,
    filteredUwisLoading,
    filterUwisSuccess,
    filterUwisByAttributeValue,
    getWellCountByAttribute,
  };
};

export default useFilterByConfig;
