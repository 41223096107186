import { useCallback, useEffect, useState } from "react";

import {
  DstPressureDataResponseBody,
  WftPressureDataResponseBody,
} from "../../../../types/panels/wellPanel/dataLakeType";
import { MapLayer } from "../../../../types/panels/wellPanel/wellPanel";

import { SORT_ORDER } from "../../../../constants/dataLake";
import { dataLakeSize } from "../../../../constants/panels/wellPanel/logs";
import {
  DST_PRESSURE_DATA_FIELDS,
  DST_PRESSURE_DATA_SEARCHTYPES,
  DST_PRESSURE_DATA_TEST_TYPES,
  PRESSURE_DATA_SORT_FIELDS,
  WFT_PRESSURE_DATA_FIELDS,
  WFT_PRESSURE_DATA_SEARCHTYPES,
} from "../../../../constants/panels/wellPanel/pressureData";

import usePanelsStore from "../../../../store/panels/panelsStore";

import useDatalake from "../useDatalake";
import useDstPressureData from "../useDstPressureData";
import useWftPressureData from "../useWftPressureData";

const useExportWellPanelPressureData = (docType: string) => {
  const [loadingDataLakeData, setLoadingDataLakeData] = useState(false);
  const [panelInfoWellType, setPanelInfoWellType] = useState<
    MapLayer | undefined
  >(undefined);
  const [panelInfoGroupedWellId, setPanelInfoGroupedWellId] = useState<
    number | undefined
  >(undefined);
  const { getDataLakeData, dataLakeResponse, successRequest, errorRequest } =
    useDatalake();
  const { buildDstPressureData } = useDstPressureData();
  const { buildWftPressureData } = useWftPressureData();

  const updateWellCardDataByUWI = usePanelsStore(
    (state) => state.updateWellCardDataByUWI
  );

  const getSearchTypesByDocType = useCallback(() => {
    if (docType === DST_PRESSURE_DATA_TEST_TYPES.DST) {
      return DST_PRESSURE_DATA_SEARCHTYPES;
    } else {
      return WFT_PRESSURE_DATA_SEARCHTYPES;
    }
  }, [docType]);

  const getFieldsByDocType = useCallback(() => {
    if (docType === DST_PRESSURE_DATA_TEST_TYPES.DST) {
      return DST_PRESSURE_DATA_FIELDS;
    } else {
      return WFT_PRESSURE_DATA_FIELDS;
    }
  }, [docType]);

  const parseUWIQuery = (uwiList: string[]) => {
    let query = "";

    uwiList.forEach((uwi, index) => {
      query = query.concat(
        `Uwi:${uwi}`,
        `${index + 1 !== uwiList.length ? " OR " : ""}`
      );
    });

    return query;
  };

  const groupedData = useCallback(
    (
      response: DstPressureDataResponseBody[] | WftPressureDataResponseBody[]
    ) => {
      const groupings: {
        [key: string]: any[];
      } = {};

      response.forEach((pressureData) => {
        if (groupings[pressureData.Uwi]) {
          groupings[pressureData.Uwi].push(pressureData);
        } else {
          groupings[pressureData.Uwi] = [pressureData];
        }
      });

      return groupings;
    },
    [dataLakeResponse, successRequest]
  );

  useEffect(() => {
    if (!panelInfoWellType || !panelInfoGroupedWellId) {
      return;
    }

    if (successRequest && dataLakeResponse) {
      const data = groupedData(
        dataLakeResponse as
          | DstPressureDataResponseBody[]
          | WftPressureDataResponseBody[]
      );

      if (docType === DST_PRESSURE_DATA_TEST_TYPES.DST) {
        Object.entries(data).forEach(([key, value]) => {
          const mappedData = buildDstPressureData(
            value as DstPressureDataResponseBody[]
          );
          updateWellCardDataByUWI(
            { type: "dstPressuredata", dstPressuredata: mappedData },
            key,
            panelInfoWellType,
            panelInfoGroupedWellId
          );
        });
      } else {
        Object.entries(data).forEach(([key, value]) => {
          const mappedData = buildWftPressureData(
            value as WftPressureDataResponseBody[]
          );
          updateWellCardDataByUWI(
            { type: "wftPressuredata", wftPressuredata: mappedData },
            key,
            panelInfoWellType,
            panelInfoGroupedWellId
          );
        });
      }

      setLoadingDataLakeData(false);
      setPanelInfoGroupedWellId(undefined);
      setPanelInfoWellType(undefined);
    }
  }, [
    dataLakeResponse,
    successRequest,
    panelInfoWellType,
    panelInfoGroupedWellId,
  ]);

  const getBulkPressureData = (
    uwiList: string[],
    wellType: MapLayer,
    groupedWellID: number
  ) => {
    if (Boolean(!uwiList.length)) {
      return;
    }

    setLoadingDataLakeData(true);
    setPanelInfoGroupedWellId(groupedWellID);
    setPanelInfoWellType(wellType);

    const uwiQuery = parseUWIQuery(uwiList);
    const requestBody = {
      SearchTypes: getSearchTypesByDocType(),
      Query: uwiQuery,
      IncludeFields: getFieldsByDocType(),
      From: 0, // from count to start at (paging)
      Size: dataLakeSize,
      Sort: {
        SortOrder: SORT_ORDER.ASC,
        SortPropertyNames: PRESSURE_DATA_SORT_FIELDS,
      },
    };
    getDataLakeData(requestBody);
  };

  return { getBulkPressureData, loadingDataLakeData };
};

export default useExportWellPanelPressureData;
