import { FC, useCallback, useEffect, useState } from "react";

import {
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import { RadioGroup } from "@mui/material";

import { Button } from "component-library";

import { AssetEconomicsConfigProps } from "../../../types/charts/chartTypeSettings/chartTypeSettings";
import { EconomicsDisplayBy } from "../../../types/charts/chartTypeSettings/exonomicsTypeSettings";
import { WellCounts } from "../../../types/common/wells";

import { ECONOMICS_DISPLAY_BY } from "../../../constants/charts/cashFlowChart";
import { WELL_ECONOMICS_FILTER_BY_ATTRIBUTE } from "../../../constants/charts/economics";

import useChartConfig from "../../../customHooks/charts/useChartConfig";
import useEconomicsChartUpdate from "../../../customHooks/charts/useEconomicsChartUpdate";
import useFilterByConfig from "../../../customHooks/charts/useFilterByConfig";

import AutocompleteSelector from "./formPickers/AutocompletSelector";

const AssetEconomicsConfig: FC<AssetEconomicsConfigProps> = ({
  chartData,
  handleClose,
}) => {
  const [displayBy, setDisplayBy] = useState<EconomicsDisplayBy>();
  const [filterByAttributeSelected, setFilterByAttributeSelected] =
    useState("");
  const [selectedFilterByOptions, setSelectedFilterByOptions] = useState<
    WellCounts[]
  >([]);
  const [inputError, setInputError] = useState(false);

  const {
    title,
    isInputTitleError,
    inputTitleValidationMessage,
    handleTitleOnChange,
    handleTitleOnBlur,
  } = useChartConfig(chartData);

  const {
    filteredUwis,
    filteredUwisLoading,
    filterUwisSuccess,
    filterUwisByAttributeValue,
  } = useFilterByConfig();

  const { updateChartConfig } = useEconomicsChartUpdate(chartData);

  const handleSaveSettings = useCallback(() => {
    if (isInputTitleError) return;

    if (
      displayBy === ECONOMICS_DISPLAY_BY.attribute.key &&
      selectedFilterByOptions.length === 0
    ) {
      setInputError(true);
      return;
    }
    setInputError(false);

    if (displayBy === ECONOMICS_DISPLAY_BY.allWells.key) {
      updateChartConfig(title, displayBy);
    } else {
      const options = selectedFilterByOptions.map((op) => op.attributeValue);
      filterUwisByAttributeValue(filterByAttributeSelected, options);
    }
  }, [title, displayBy, selectedFilterByOptions]);

  const handleDisplayBy = (e: React.ChangeEvent<HTMLInputElement>) => {
    const displayBy = e.target.value as EconomicsDisplayBy;
    setDisplayBy(displayBy);

    if (displayBy === ECONOMICS_DISPLAY_BY.allWells.key) {
      setFilterByAttributeSelected("");
      setSelectedFilterByOptions([]);
    }
  };

  const handleFilterBySelect = (e: SelectChangeEvent) => {
    const attribute = e.target.value as string;
    setFilterByAttributeSelected(attribute);
    setSelectedFilterByOptions([]);
  };

  useEffect(() => {
    if (chartData.displayBy) {
      setDisplayBy(chartData.displayBy);

      if (chartData.displayBy === ECONOMICS_DISPLAY_BY.attribute.key) {
        setFilterByAttributeSelected(chartData.filterBy?.attribute ?? "");
        setSelectedFilterByOptions(chartData.filterBy?.values ?? []);
      }
    } else {
      setDisplayBy(ECONOMICS_DISPLAY_BY.allWells.key);
    }
  }, [chartData.displayBy, chartData.filterBy]);

  useEffect(() => {
    if (!filteredUwisLoading && filterUwisSuccess) {
      const displayByValue = displayBy ?? ECONOMICS_DISPLAY_BY.allWells.key;
      const filterByValue =
        displayBy === ECONOMICS_DISPLAY_BY.attribute.key
          ? {
              attribute: filterByAttributeSelected,
              values: selectedFilterByOptions,
              uwis: filteredUwis,
            }
          : undefined;

      updateChartConfig(title, displayByValue, filterByValue, true);
    }
  }, [filteredUwisLoading, filterUwisSuccess, filteredUwis]);

  return (
    <>
      <DialogContent dividers className="economics-settings-container">
        <Grid item xs={12} className="section-item">
          <TextField
            id="chart-title"
            label="Title"
            size="small"
            helperText={isInputTitleError ? inputTitleValidationMessage : ""}
            error={isInputTitleError}
            value={title}
            onChange={handleTitleOnChange}
            onBlur={handleTitleOnBlur}
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={12} className="section-item">
          <FormControl fullWidth>
            <FormLabel className="chart-settings-text">Filter Wells</FormLabel>

            <RadioGroup
              aria-labelledby="filter-wells-group-label"
              value={displayBy}
              name="display-by-buttons-group"
              onChange={handleDisplayBy}
            >
              <FormControlLabel
                value={ECONOMICS_DISPLAY_BY.allWells.key}
                control={
                  <Radio
                    size="small"
                    className="custom-primary-icon-color filter-radio-button"
                  />
                }
                label={ECONOMICS_DISPLAY_BY.allWells.label}
                checked={displayBy === ECONOMICS_DISPLAY_BY.allWells.key}
              />

              <Grid container>
                <Grid item xs={3}>
                  <FormControlLabel
                    value={ECONOMICS_DISPLAY_BY.attribute.key}
                    control={
                      <Radio
                        size="small"
                        className="custom-primary-icon-color filter-radio-button"
                      />
                    }
                    label={ECONOMICS_DISPLAY_BY.attribute.label}
                    checked={displayBy === ECONOMICS_DISPLAY_BY.attribute.key}
                  />
                </Grid>
                <Grid item xs={9}>
                  {displayBy === ECONOMICS_DISPLAY_BY.attribute.key && (
                    <Grid container spacing={1}>
                      <Grid item xs={5}>
                        <FormControl fullWidth>
                          <InputLabel
                            className="economics-display-by-attribute-label"
                            variant="outlined"
                            shrink
                          >
                            Attribute
                          </InputLabel>
                          <Select
                            className="economics-diplay-by-attribute-select"
                            value={filterByAttributeSelected}
                            label="Attribute"
                            renderValue={(selected) => {
                              if (!selected) return "--- Select ---";

                              const attribute =
                                WELL_ECONOMICS_FILTER_BY_ATTRIBUTE.find(
                                  (attr) =>
                                    attr.name === filterByAttributeSelected
                                );
                              if (attribute) return attribute.displayName;
                            }}
                            onChange={handleFilterBySelect}
                            displayEmpty
                          >
                            {WELL_ECONOMICS_FILTER_BY_ATTRIBUTE.map(
                              (attribute) => (
                                <MenuItem
                                  key={`attribute-filter-by-${attribute.name}`}
                                  value={attribute.name}
                                >
                                  {attribute.displayName}
                                </MenuItem>
                              )
                            )}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={7}>
                        <AutocompleteSelector
                          attribute={filterByAttributeSelected}
                          chartData={chartData}
                          selectedValues={selectedFilterByOptions}
                          error={inputError}
                          onSelectedValues={(values) =>
                            setSelectedFilterByOptions(values)
                          }
                        />
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </RadioGroup>
          </FormControl>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          type="secondary"
          iconLeft="chevronLeft"
          text="Cancel"
          className="chart-cancel-button"
        />
        <Button
          onClick={handleSaveSettings}
          disabled={isInputTitleError || filteredUwisLoading}
          type="primary"
          iconLeft="save"
          text="Save Settings"
        />
      </DialogActions>
    </>
  );
};

export default AssetEconomicsConfig;
