import { WellPermitPanelState } from "../../../types/panels/sidebar";
import { WellPanelInfo } from "../../../types/panels/wellPanel/wellPanel";

export const sortWellsDesc = (wellPanelList: WellPermitPanelState[]) => {
  return wellPanelList.sort((a, b) =>
    a.createdData.getTime() < b.createdData.getTime() ? 1 : -1
  );
};

export const sortPanelData = (panelObj: any[], sortProperty: string) => {
  return panelObj.sort((a, b) =>
    a[sortProperty].localeCompare(b[sortProperty], undefined, {
      numeric: true,
      sensitivity: "base",
    })
  );
};
